/** @format */

import React from "react";

const SearchIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10 18.25C5.58172 18.25 2 14.6683 2 10.25C2 5.83172 5.58172 2.25 10 2.25C14.4183 2.25 18 5.83172 18 10.25C17.995 14.6662 14.4162 18.245 10 18.25ZM10 4.25C6.68629 4.25 4 6.93629 4 10.25C4 13.5637 6.68629 16.25 10 16.25C13.3137 16.25 16 13.5637 16 10.25C15.9961 6.93789 13.3121 4.25386 10 4.25Z"
				fill="black"
				fillOpacity="0.75"
			/>
			<path
				d="M14.5 14.75L21.5 21.75"
				stroke="black"
				strokeOpacity="0.75"
				strokeWidth="2"
			/>
		</svg>
	);
};

export default SearchIcon;
