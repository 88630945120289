import React, {useEffect, useRef, useState} from "react";
import IconCaretDown from "assets/icons/IconCaretDown";
import {useSelector} from "react-redux";
import IconCaretUp from "assets/icons/IconCaretUp";
import projectApi from "api/report/project";
import {toast} from "react-toastify";
import Loader from "components/Plugins/Loader";
import useOnClickOutside from "hooks/useOnClickOutside";

const statuses = [
	"Placed",
	"Offered",
	"Interviewing",
	"Engaged",
	"Approached",
	"Identified",
	"Inactive",
];

const ProgressStatus = ({
	array = [1, 2, 3, 4],
	progressLevel,
	status,
	date = null,
	showLabel = true,
	candidateId,
	projectId,
	isPrintPage = false,
}) => {
	const progressStatuses =
		useSelector((state) => state.project?.progress_statuses) || [];
	const researchMode = useSelector((state) => state.user.researchModeEnabled);
	const [displayedProgressStatus, setDisplayedProgressStatus] = useState(status);
	const [displayedProgressLevel, setDisplayedProgressLevel] = useState(progressLevel);
	const [showOptions, setShowOptions] = useState(false);
	const [activeStatuses] = useState(researchMode ? progressStatuses : statuses);
	const [loading, setLoading] = useState(false);
	const ref = useRef();

	useEffect(() => setDisplayedProgressLevel(progressLevel), [progressLevel]);

	useEffect(() => setDisplayedProgressStatus(status), [status]);

	useOnClickOutside(ref, () => setShowOptions(false));

	const toggleDropdown = () => {
		setShowOptions((showOptions) => {
			let overflow = showOptions ? "auto" : "initial";

			if (document.getElementsByClassName("infinite-scroll-component ")?.length) {
				document.getElementsByClassName(
					"infinite-scroll-component ",
				)[0].style.overflow = overflow;
			}

			return !showOptions;
		});
	};

	const changeProgressStatusCandidate = async (progressStatus) => {
		setShowOptions(false);
		if (!window.confirm("Are you sure?")) return;

		setLoading(true);

		await projectApi
			.saveCandidateInfo(projectId, candidateId, {
				status_info: progressStatus,
			})
			.then((response) => {
				if (response.status >= 200 && response.status < 205) {
					toast.success("Saved Successfully");
					setDisplayedProgressStatus(
						response.data?.status_info?.display_text,
					);
					setDisplayedProgressLevel(response.data?.status_info?.level);
				} else {
					toast.error("Something went wrong!");
					console.log(response.data);
				}
			})
			.catch((error) => console.log(error));

		setLoading(false);
	};

	const InlineLoaderComponent = (
		<Loader height={32} width={32} type="ThreeDots" color={"#f13a3a"} />
	);

	return (
		<div style={{display: "flex", flexDirection: "column"}}>
			{showLabel && <span className="label">Candidate Stage</span>}

			{!isPrintPage && (
				<span className="dots">
					{array.map((serial, idx) => (
						<div key={`${serial}__${idx}`} className="dot-item-wrapper">
							<div // data-tip="Progress status note loads appears when user hovers over progress timeline"
								className={[
									"dot",
									displayedProgressLevel >= serial
										? "active"
										: "inactive",
								].join(" ")}
							/>
							{serial < 4 && (
								<div
									className={[
										"line",
										displayedProgressLevel >= serial
											? "active"
											: "inactive",
									].join(" ")}
								/>
							)}
						</div>
					))}
				</span>
			)}

			<span className="value">
				{loading ? (
					InlineLoaderComponent
				) : researchMode && !isPrintPage ? (
					<div style={{position: "relative"}} ref={ref}>
						<div onClick={toggleDropdown}>
							{displayedProgressStatus}
							{showOptions ? (
								<IconCaretUp className="cursor" />
							) : (
								<IconCaretDown className="cursor" />
							)}
						</div>

						{showOptions && (
							<ul
								className={[
									"options-as-list",
									showOptions ? "show" : "hide",
								].join(" ")}
								style={{left: "-115px"}}
							>
								{activeStatuses.map((item, i) => (
									<li
										key={i}
										onClick={() =>
											changeProgressStatusCandidate(item)
										}
										className={
											item.display_text ===
											displayedProgressStatus
												? "active"
												: ""
										}
									>
										{item.display_text}
									</li>
								))}
							</ul>
						)}
					</div>
				) : (
					displayedProgressStatus
				)}
			</span>

			{date && <span className="date value">{date}</span>}
		</div>
	);
};

export default ProgressStatus;
