import React, {useRef} from "react";
import IconAdd from "../../assets/icons/IconAdd";

export const UploadFile = ({handleFileUpload, ...rest}) => {
	const inputRef = useRef();

	return (
		<button
			className="btn btn-white"
			onClick={() => inputRef?.current?.click()}
			{...rest}
		>
			<input
				type="file"
				onChange={handleFileUpload}
				className="file-input"
				ref={inputRef}
				style={{display: "none"}}
			/>
			<IconAdd /> Upload
		</button>
	);
};
