import React from "react";
import LoaderSpinner from "react-loader-spinner";
import * as PropTypes from "prop-types";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Loader = ({
	type,
	color,
	height,
	width,
	timeout,
	displayAtCenterOfPage,
	customStyle = null,
	...restProps
}) => {
	const centerOfPage = {
		position: "absolute",
		top: "50%",
		right: "45%",
	};

	const inlineBlock = {
		display: "inline",
	};

	const style =
		customStyle !== null
			? customStyle
			: displayAtCenterOfPage
			? centerOfPage
			: inlineBlock;

	return (
		<LoaderSpinner
			style={{...style, ...{zIndex: "111"}}}
			type={type}
			color={color}
			height={height}
			width={width}
			timeout={timeout * 1000} //3 secs
			{...restProps}
		/>
	);
};

Loader.defaultProps = {
	type: "ThreeDots", // Audio | Ball-Triangle | Bars | Circles | Grid | Hearts | Oval | Puff | Rings | TailSpin | ThreeDots
	color: "#00BFFF",
	timeout: 0,
	width: 50,
	height: 50,
	displayAtCenterOfPage: false,
};

Loader.propTypes = {
	type: PropTypes.string,
	color: PropTypes.string,
	timeout: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
	displayAtCenterOfPage: PropTypes.bool,
};

export default Loader;
