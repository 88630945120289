/** @format */

import React from "react";
import ErrorPage from "components/Plugins/ErrorPage";
import ServerErrorIcon from "assets/icons/ServerErrorIcon";

const ServerError = () => {
	return (
		<ErrorPage
			icon={<ServerErrorIcon />}
			title="Internal Server Error"
			description="Sorry! It seems there is a problem with our server! Please try again in a few moments."
		/>
	);
};

export default ServerError;
