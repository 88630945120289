/** @format */

import React from "react";
import Select from "react-select/async";
import css from "./index.module.scss";

const customStyles = {
	control: (base) => ({
		...base,
		border: "1px solid #D7DFE9",
		height: "40px",
		borderRadius: 0,
	}),
	indicatorSeparator: (base) => ({
		...base,
		width: 0,
	}),
	dropdownIndicator: (base) => ({
		...base,
		color: "#000",
	}),
	placeholder: (base) => ({
		...base,
		fontSize: "14px",
		lineHeight: "24px",
		color: "#000",
	}),
	multiValueLabel: (base) => ({
		...base,
		// backgroundColor: 'red'
	}),
	option: (provided) => ({
		...provided,
		fontSize: "14px",
		lineHeight: "24px",
		// color: state.isDisabled ? null : state.isSelected ? '#fff' : state.isFocused ? '#fff' : null,
		":hover": {
			cursor: "pointer",
		},
	}),
};

const SelectComponent = React.forwardRef(({styles = {}, ...props}, ref) => {
	return (
		<Select
			ref={ref}
			{...props}
			className={css.select}
			styles={{...customStyles, ...styles}}
		/>
	);
});

SelectComponent.displayName = "SelectComponent";

export default SelectComponent;
