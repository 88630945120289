/** @format */

import React, {useRef, useState} from "react";
import IconCaretDown from "../../assets/icons/IconCaretDown";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import projectApi from "../../api/report/project";
import {toast} from "react-toastify";
import Loader from "../../components/Plugins/Loader";
import IconCaretUp from "../../assets/icons/IconCaretUp";

// const noticePeriods = ["TBC", "Negotiable", "Immediate", "1 Week", "2 Weeks", "3 Weeks", "4 Weeks", "6 Weeks", "1 Month", "2 Months", "3 Months", "4 Months", "5 Months", "6 Months", "7 Months", "8 Months", "9 Months", "10 Months", "11 Months", "12 Months"];

const NoticePeriodSection = ({
	noticePeriod,
	noticePeriods = [],
	candidateId,
	projectId,
}) => {
	const [displayedNoticePeriod, setDisplayedNoticePeriod] = useState(noticePeriod);
	const [showNoticePeriodOptions, setShowNoticePeriodOptions] = useState(false);
	const [loading, setLoading] = useState(false);
	const ref = useRef();

	useOnClickOutside(ref, () => setShowNoticePeriodOptions(false));

	const changeNoticePeriodForCandidate = async (selectedNoticePeriod) => {
		setShowNoticePeriodOptions(false);
		if (!window.confirm("Are you sure?")) return;

		setLoading(true);

		await projectApi
			.saveCandidateInfo(projectId, candidateId, {
				notice_period: selectedNoticePeriod,
			})
			.then((response) => {
				if (response.status >= 200 && response.status < 205) {
					toast.success("Saved Successfully");
					setDisplayedNoticePeriod(response.data.notice_period);
				} else {
					toast.error("Something went wrong!");
				}
			})
			.catch((error) => console.log(error));

		setLoading(false);
	};

	const InlineLoaderComponent = (
		<Loader height={32} width={32} type="ThreeDots" color={"#f13a3a"} />
	);

	return (
		<div style={{position: "relative"}} ref={ref}>
			{loading ? (
				InlineLoaderComponent
			) : (
				<>
					<div
						onClick={() =>
							setShowNoticePeriodOptions((showOptions) => !showOptions)
						}
					>
						{displayedNoticePeriod?.id !== null
							? displayedNoticePeriod?.display_text
							: "Select"}

						{showNoticePeriodOptions ? (
							<IconCaretUp className="cursor" />
						) : (
							<IconCaretDown className="cursor" />
						)}
					</div>

					<ul
						className={[
							"options-as-list reverse notice-periods",
							showNoticePeriodOptions ? "show" : "hide",
						].join(" ")}
					>
						{noticePeriods.map((item, i) => (
							<li
								key={i + item.id}
								onClick={() => changeNoticePeriodForCandidate(item)}
								className={[
									item.id === displayedNoticePeriod?.id
										? "active"
										: "",
									"cursor",
								].join(" ")}
							>
								{item.display_text}
							</li>
						))}
					</ul>
				</>
			)}
		</div>
	);
};

export default NoticePeriodSection;
