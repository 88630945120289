/** @format */

import HttpClient from "../api";

class ProjectApi extends HttpClient {
	async getProjects(userType = "client", query, config = {}) {
		return await this.doGet(
			`/report/${userType}/projects${query ? "q=" + query : ""}`,
			config,
		);
	}

	async getSummaryInfoByProjectId(projectId, userType = "client", config = {}) {
		return await this.doGet(`/report/${userType}/projects/${projectId}`, config);
	}

	async saveProjectInfo(projectId, formBody, config = {}) {
		return await this.doPatch(`/report/projects/${projectId}`, formBody, config);
	}

	async getGenderAggregatesDataProjectId(
		projectId,
		isResearcher = false,
		config = {},
	) {
		return await this.doGet(
			`/report/projects/${projectId}/aggregates/gender${
				isResearcher ? "?researcher=true" : ""
			}`,
			config,
		);
	}

	async getAssessmentDataByProjectId(
		projectId,
		isResearcher = false,
		query = null,
		config = {},
	) {
		return await this.doGet(
			`/report/${
				isResearcher ? "researcher" : "client"
			}/projects/${projectId}/candidates${query ? "?" + query : ""}`,
			config,
		);
	}

	async getCandidateInfoById(projectId, candidateId, isResearcher, config = {}) {
		return this.doGet(
			`/report/${
				isResearcher ? "researcher" : "client"
			}/projects/${projectId}/candidates/${candidateId}`,
			config,
		);
	}

	async getAssignments(q, config = {}) {
		return await this.doGet(`/report/assignments?q=${q || "a"}`, config);
	}

	async getBriefs(assignmentId, config = {}) {
		return this.doGet(`/report/assignments/${assignmentId}/fit_to_briefs`, config);
	}

	async getInterviewedCandidateByProjectId(projectId, query, config = {}) {
		return await this.doGet(
			`/report/projects/${projectId}/interviews${query ? query : ""}`,
			config,
		);
	}

	async saveCandidateInfo(projectId, candidateId, formBody, config = {}) {
		return await this.doPatch(
			`/report/projects/${projectId}/candidates/${candidateId}`,
			formBody,
			config,
		);
	}

	async searchCompanies(q = "a") {
		return await this.doGet(`/report/companies?q=${q}&take=100`);
	}

	async searchInstitutes(q = "a", pageIndex = 0, pageSize = 20) {
		return await this.doGet(
			`/report/educational_institutions?q=${q}&page_index=${pageIndex}&page_size=${pageSize}`,
		);
	}

	async getCurrencies() {
		return await this.doGet("/report/currencies");
	}

	async getProgressStatuses() {
		return await this.doGet("/report/candidate_statuses");
	}

	async getNoticePeriods() {
		return await this.doGet("/report/notice_periods");
	}

	async getCountries() {
		return await this.doGet("/report/countries");
	}

	async publishChanges(projectId, formBody) {
		return await this.doPost(`/report/projects/${projectId}/publish`, formBody);
	}

	async pinProject(isResearcher, projectId, formBody) {
		return await this.doPatch(
			`/report/${isResearcher ? "researcher" : "client"}/projects/${projectId}`,
			formBody,
		);
	}

	async updateCandidateOrdering(projectId, candidateId, index) {
		return await this.doPatch(
			`/researcher/projects/${projectId}/candidates/ordering/${candidateId}`,
			{
				custom_order: index,
			},
		);
	}
}

const projectApi = new ProjectApi();

export default projectApi;
