import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import CloseIcon from "assets/icons/CloseIcon";
import ProjectDetailsSettings from "./ProjectDetailsSettings";
import ProjectSettings from "./ProjectSettings";
import FitToBriefSettings from "./FitToBriefSettings";
import AdditionalCriteriaSettings from "./AdditionalCriteriaSettings";
import CaretLeftIcon from "assets/icons/IconCaretLeft";
import CaretRightIcon from "assets/icons/IconCaretRight";
import Loader from "components/Plugins/Loader";
import projectApi from "api/report/project";
import {useDispatch, useSelector} from "react-redux";
import {setFormData} from "store/projectForm/projectFormActions";
import {useMountEffect} from "hooks/common";
import projectRequestsApi from "api/report/projectRequests";
import {toast} from "react-toastify";

const ProjectWorkflowForm = (props) => {
	const initialFormData = useSelector((state) => state.projectForm);
	const dispatch = useDispatch();
	const [currentProgressLevel, setCurrentProgressLevel] = useState(0);
	const [nextRoute, setNextRoute] = useState(null);
	const [prevRoute, setPrevRoute] = useState(null);
	const [loading, setLoading] = useState(false);
	const [formData, setData] = useState({...initialFormData.form});
	const [formDataLoading, setFormDataLoading] = useState(false); // default false
	const projectFetched = !!initialFormData.form.assignment; // default false

	const {location, match} = props;
	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
	const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
	const projectDetailsRef = useRef();
	const briefRef = useRef();
	const [progressLevels] = useState([
		"Project Details",
		"Project Settings",
		"Fit to Brief Settings",
		"Additional Criteria Settings",
	]);
	const {id} = match.params;
	const projectParentRef = useRef(true);
	const urlSearchParams = new URLSearchParams(window.location.search);
	const redirectTo =
		Object.fromEntries(urlSearchParams.entries())?.redirectTo || null;

	const nextDisabled = useCallback(() => {
		if (currentProgressLevel === 0) {
			setIsNextButtonDisabled(!projectDetailsRef?.current?.validated());
		} else if (currentProgressLevel === 2) {
			setIsNextButtonDisabled(!briefRef?.current?.validated());
		} else {
			setIsNextButtonDisabled(false);
		}
	}, [projectDetailsRef, currentProgressLevel]);

	const updateSaveButtonStatus = useCallback(() => {
		let shouldBeEnabled = true;

		if (currentProgressLevel === 0) {
			shouldBeEnabled = projectDetailsRef?.current?.validated();
		} else if (currentProgressLevel === 2) {
			shouldBeEnabled = briefRef?.current?.validated();
		}

		if (shouldBeEnabled) {
			setIsSaveButtonDisabled(false);
		} else {
			setIsSaveButtonDisabled(true);
		}
	}, [projectDetailsRef, currentProgressLevel]);

	const handleInputChange = async (data) => {
		delete data.validation;
		data = {...formData, ...data};
		dispatch(setFormData(data));
		setData(data);
	};

	useEffect(() => {
		nextDisabled();
		updateSaveButtonStatus();

		return () => {
			projectParentRef.current = false;
		};
	}, [formData, nextDisabled, updateSaveButtonStatus]);

	const goBackToPrevious = () => {
		props.history.push(prevRoute);
	};

	const progressToNext = () => {
		if (currentProgressLevel === 3) {
			console.log("save");
		} else {
			props.history.push(nextRoute);
		}
	};

	const close = () => {
		props.history.push(redirectTo || "/home");
	};

	const save = async () => {
		let data = {
			fit_to_briefs: formData.fit_to_briefs.map((item) => {
				if (item.validName) {
					delete item.validName;
				}
				if (item.validDesc) {
					delete item.validDesc;
				}

				return item;
			}),
			settings: formData.settings,
		};

		if (!id) {
			data = {
				assignment_id:
					formData.assignment.value ||
					initialFormData.form.assignment.assignment_id,
				...data,
			};
		}

		// if (formData.briefUpdated) {
		//     data = {...data, fit_to_briefs: formData.fit_to_briefs}
		// }

		setLoading(true);

		if (id) {
			await projectRequestsApi.updateProject(data, id).then((response) => {
				if (response.status === 200 || response.status === 201) {
					toast.success("Project updated successfully");
					close();
				} else {
					toast.error("Something went wrong, try again!");
					setLoading(false);
				}
			});
		} else {
			await projectRequestsApi.saveProject(data).then((response) => {
				if (response.status === 200 || response.status === 201) {
					toast.success("Project created successfully");
					close();
				} else {
					toast.error("Something went wrong, try again!");
					setLoading(false);
				}
			});
		}
	};

	const getBriefs = async (assignmentId) => {
		await projectApi
			.getBriefs(assignmentId)
			.then((response) => {
				const briefs = response.data.fit_to_briefs.map((item) => {
					item.invenias_name = item.name;
					item.updated_name = item.name;
					delete item.name;

					return item;
				});

				dispatch(setFormData({fit_to_briefs: briefs, briefUpdated: false}));

				if (formData.assignment_id) {
					setData({
						...formData,
						fit_to_briefs: briefs,
						briefUpdated: false,
					});
				} else {
					setData({
						...formData,
						assignment_id: assignmentId,
						fit_to_briefs: briefs,
						briefUpdated: false,
					});
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const getProjectInformation = useCallback(async () => {
		setFormDataLoading(true);

		await projectRequestsApi
			.getProjectInformation(id)
			.then(async (response) => {
				let projectInfo = response.data;
				const {assignment_info} = projectInfo;
				const {assignment_name, assignment_id} = assignment_info;
				const briefs = response.data.fit_to_briefs.map((item) => {
					item.updated_name = item.custom_name || item.invenias_name;

					return item;
				});

				projectInfo = {
					assignment: {
						label: assignment_name,
						value: assignment_id,
					},
					fit_to_briefs: briefs,
					external_shares: projectInfo.shared_with || [],
					briefUpdated: false,
					settings: {
						...formData.settings,
						...(projectInfo.settings || {}),
					},
				};

				dispatch(setFormData(projectInfo));
				setData((data) => {
					return {...data, ...projectInfo};
				});
			})
			.catch((e) => {
				console.log(e);
			});

		setFormDataLoading(setFormDataLoading(true));
	}, [id, dispatch, formData.settings]);

	useMountEffect(() => {
		nextDisabled();
		updateSaveButtonStatus();
	}, [currentProgressLevel]);

	const currentPage = useMemo(() => {
		const routeArray = location.pathname.split("/");

		return routeArray[routeArray.length - 1];
	}, [location.pathname]);

	useEffect(() => {
		const baseUrl = id ? `/projects/${id}/edit` : "/projects/create";

		switch (currentPage) {
			case "project-settings":
				setCurrentProgressLevel(1);
				setNextRoute(`${baseUrl}/fit-to-brief-settings`);
				setPrevRoute(`${baseUrl}`);
				break;
			case "fit-to-brief-settings":
				setCurrentProgressLevel(2);
				setNextRoute(`${baseUrl}/additional-criteria-settings`);
				setPrevRoute(`${baseUrl}/project-settings`);
				break;
			case "additional-criteria-settings":
				setCurrentProgressLevel(3);
				setNextRoute(null);
				setPrevRoute(`${baseUrl}/fit-to-brief-settings`);
				break;
			default:
				setCurrentProgressLevel(0);
				setNextRoute(`${baseUrl}/project-settings`);
				setPrevRoute(null);
		}
	}, [currentPage, id]);

	useEffect(() => {
		if (id && !projectFetched) {
			getProjectInformation().then(() => setIsNextButtonDisabled(false));
		}
	}, [id, getProjectInformation, projectFetched]);

	const InlineLoaderComponent = (
		<Loader
			height={16}
			width={16}
			customStyle={{paddingLeft: "10px"}}
			type="TailSpin"
			color={"#f13a3a"}
		/>
	);

	const LoaderComponent = (
		<div className="project-details-form">
			<Loader
				displayAtCenterOfPage={true}
				type={"Rings"}
				height={80}
				width={80}
				color={"#f13a3a"}
			/>
		</div>
	);

	return (
		<div className={"main-content-area"}>
			<div className={["content-wrapper new-project-page"].join(" ")}>
				<div className="container custom-scrollbar">
					<div className="page-title-with-buttons">
						<div className="title">Project Setup</div>
						<div className="button-group">
							<button
								className={[
									"btn",
									loading || isSaveButtonDisabled
										? "btn-white btn-disabled"
										: "btn-red",
								].join(" ")}
								onClick={save}
								disabled={isSaveButtonDisabled || loading}
							>
								{loading ? (
									<>Saving {InlineLoaderComponent}</>
								) : (
									"Save & Close"
								)}
							</button>
							<button
								className="btn btn-white close-button"
								onClick={close}
							>
								<CloseIcon />
							</button>
						</div>
					</div>

					<div className="content-container">
						<div className="left-sidebar">
							<div className="section-title">
								{id ? "Edit Project" : "Create Project"}
							</div>
							<div className="progress-with-label">
								{progressLevels.map((progress, rI) => (
									<div
										key={rI}
										className={[
											"item",
											rI <= currentProgressLevel ? "active" : "",
										].join(" ")}
									>
										<div className="dot-with-line">
											<div>
												<span className="dot" />
											</div>
											<div className="line-with-label">
												{rI < [1, 2, 3, 4].length - 1 ? (
													<span
														className="line"
														style={{
															borderLeft:
																rI <
																currentProgressLevel
																	? "1px solid #F3A533"
																	: "1px solid #DEDEDE",
														}}
													/>
												) : (
													<span
														style={{
															width: "18px",
														}}
													/>
												)}
											</div>
										</div>
										<span className="label">{progress}</span>
									</div>
								))}
							</div>
						</div>

						<div className="right-sidebar">
							{formDataLoading ? (
								LoaderComponent
							) : (
								<>
									{(currentPage === "create" ||
										currentPage === "edit") && (
										<ProjectDetailsSettings
											key="assignments"
											ref={projectDetailsRef}
											isEdit={!!id}
											projectId={id}
											handleInputChange={handleInputChange}
											getBriefs={getBriefs}
											currentProgressLevel={currentProgressLevel}
										/>
									)}
									{currentPage === "project-settings" && (
										<ProjectSettings
											key="project-settings"
											handleInputChange={handleInputChange}
										/>
									)}
									{currentPage === "fit-to-brief-settings" && (
										<FitToBriefSettings
											briefs={initialFormData?.form.fit_to_briefs}
											getBriefs={() =>
												getBriefs(formData.assignment.value)
											}
											handleInputChange={handleInputChange}
											ref={briefRef}
										/>
									)}
									{currentPage === "additional-criteria-settings" && (
										<AdditionalCriteriaSettings />
									)}

									<div className="footer">
										{currentProgressLevel > 0 && (
											<button
												className="btn btn-white has-pre-icon"
												onClick={goBackToPrevious}
											>
												<CaretLeftIcon /> Back
											</button>
										)}

										{currentProgressLevel < 3 && (
											<button
												className={[
													"btn",
													loading || isNextButtonDisabled
														? "btn-white btn-disabled"
														: "btn-red",
												].join(" ")}
												disabled={isNextButtonDisabled}
												onClick={progressToNext}
											>
												{currentProgressLevel === 3
													? "Save"
													: "Next"}{" "}
												<CaretRightIcon color={"#fff"} />
											</button>
										)}
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProjectWorkflowForm;
