/** @format */

import React from "react";

const CaretLeftIcon = ({height = 12, width = 7, color = "#171F46"}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 7 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.87352 1.34331e-08L7 1.15074L2.25296 6L7 10.8493L5.87352 12L0.684789 6.69953C0.304253 6.3108 0.304254 5.68919 0.684789 5.30046L5.87352 1.34331e-08Z"
				fill={color}
			/>
		</svg>
	);
};

export default CaretLeftIcon;
