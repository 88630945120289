/** @format */

import React from "react";

const AreasToExploreSection = ({areasToExplore}) => {
	return (
		<div className="areas-to-explore">
			<div className="section-title">Areas to explore</div>

			{areasToExplore.map((info, i) => (
				<div className="list-info" key={i}>
					<span className="dot" />
					<span>{info}</span>
				</div>
			))}
		</div>
	);
};

export default AreasToExploreSection;
