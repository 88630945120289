/** @format */

import React, {useEffect, useState} from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import Select from "../../components/Inputs/Select";
import {animated, useSpring} from "@react-spring/web";
import {endOfMonth, format, isWithinInterval, setMonth, setYear} from "date-fns";
import {getShortName} from "../../helpers/string";

const WEEKDAYS_SHORT = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const MONTHS = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
].map((m, i) => {
	return {label: m, value: i + 1};
});
const YEARS = [2020, 2021, 2022, 2023, 2024, 2025].map((m) => ({
	label: m,
	value: m,
}));

const InterviewScheduleWithTimeline = ({
	selectedDates = [],
	interviews = [],
	toggle,
}) => {
	const filterData = (month, year) => {
		return interviews.filter((item) => {
			const startDate = new Date(year, month - 1);
			const endDate = endOfMonth(startDate);

			return isWithinInterval(new Date(item.date), {
				start: startDate,
				end: endDate,
			});
		});
	};

	const [activeClass, setActiveClass] = useState("hide");
	const [dates, setDates] = useState(selectedDates);
	const [modifiers, setModifiers] = useState({
		savannahInterviews: [],
		clientInterviews: [],
	});
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedMonth, setSelectedMonth] = useState(
		MONTHS.find((item) => item.value - 1 === new Date().getMonth()),
	);
	const [selectedYear, setSelectedYear] = useState(
		YEARS.find((item) => item.value === new Date().getFullYear()),
	);
	const [interviewsData, setInterviewsData] = useState(
		filterData(selectedMonth.value, selectedYear.value),
	);

	const handleChange = (type = "month", value) => {
		let month = selectedMonth.value;
		let year = selectedYear.value;

		if (type === "month") {
			const initialDate = setMonth(new Date(), value.value - 1);
			const date = setYear(initialDate, selectedYear.value);
			setSelectedMonth(value);
			setSelectedDate(date);
			month = value.value;
		} else {
			const initialDate = setYear(new Date(), value.value);
			const date = setMonth(initialDate, selectedMonth.value - 1);
			setYear(new Date(), value.value);
			setSelectedDate(date);
			setSelectedYear(value);
			year = value.value;
		}

		setInterviewsData(filterData(month, year));
	};

	const handleMonthChange = (date) => {
		const month = date.getMonth();
		const year = date.getFullYear();
		const monthObject = MONTHS[month];

		handleChange("month", monthObject);
		setSelectedDate(date);
		setSelectedMonth(monthObject);
		setSelectedYear({label: year.toString(), value: year});
	};

	useEffect(() => {
		let dates = [];
		const modifiers = {savannahInterviews: [], clientInterviews: []};

		for (let i = 0; i < interviewsData.length; i++) {
			const date = new Date(interviewsData[i].date);

			dates = [...dates, date];

			if (interviewsData[i].savannah_interview) {
				modifiers.savannahInterviews = [...modifiers.savannahInterviews, date];
			} else {
				modifiers.clientInterviews = [...modifiers.clientInterviews, date];
			}
		}

		setModifiers(modifiers);
		setDates(dates);
	}, [interviewsData]);

	useEffect(() => {
		if (toggle) {
			setActiveClass("show");
		} else {
			setTimeout(() => {
				setActiveClass("hide");
			}, 150);
		}
	}, [toggle]);

	const props = useSpring({
		from: {opacity: 0},
		to: {opacity: toggle ? 1 : 0},
		hold: !toggle,
	});

	return (
		<animated.div style={props}>
			<div className={["interview-schedule-wrapper", activeClass].join(" ")}>
				<div className="column calendar">
					<div className="section-title">Interview Schedule:</div>
					<DayPicker
						readOnly
						selectedDays={dates}
						modifiers={modifiers}
						onMonthChange={handleMonthChange}
						month={selectedDate}
						weekdaysShort={WEEKDAYS_SHORT}
						// onDayClick={handleDayClick}
					/>
				</div>
				<div className="column timeline">
					<div className="filters">
						<div className="dropdown interviewers">
							<Select
								altDropdownIndicator={true}
								isSearchable={false}
								options={[{label: "All Interviews", value: 1}]}
								defaultValue={{
									label: "All Interviews",
									value: 1,
								}}
							/>
						</div>
						<div className="dropdown candidates">
							<Select
								altDropdownIndicator={true}
								isSearchable={false}
								options={[{label: "All Candidates", value: 1}]}
								defaultValue={{
									label: "All Candidates",
									value: 1,
								}}
							/>
						</div>
						<div className="dropdown month">
							<Select
								altDropdownIndicator={true}
								isSearchable={false}
								options={MONTHS}
								defaultValue={selectedMonth}
								onChange={(value) => handleChange("month", value)}
								value={selectedMonth}
							/>
						</div>
						<div className="dropdown year">
							<Select
								altDropdownIndicator={true}
								isSearchable={false}
								options={YEARS}
								defaultValue={selectedYear}
								onChange={(value) => handleChange("year", value)}
								value={selectedYear}
							/>
						</div>
					</div>

					<div className="timeline-details custom-scrollbar">
						{interviewsData.map((interview, index) => (
							<div className="row" key={index}>
								<div
									className="date-column"
									style={{
										backgroundColor: interview.client_interview
											? "#D8C396"
											: "#F3A533",
									}}
								>
									<span className="date">
										{format(new Date(interview.date), "d")}
									</span>
									<span className="day">
										{format(new Date(interview.date), "ccc")}
									</span>
									<div className="avatar">
										{/*<img src={interview.candidate_avatar} alt=""/>*/}
									</div>
								</div>
								<div className="info-columns">
									<div className="interviewer-column">
										<div
											className={
												index % 2 === 0
													? "section-title bg-yellow-orange xs-only"
													: "section-title bg-orange xs-only"
											}
										>
											{interview.title}
										</div>

										<div className="meta-info">
											<div className="info time">
												<div className="label">Start Time</div>
												<div className="value">
													{format(
														new Date(interview.date),
														"p",
													)}
												</div>
											</div>
											<div className="info interviewer">
												<div className="label">Interviewer</div>
												<div className="value">
													{interview.interviewers.join(", ")}
												</div>
											</div>
											<div className="info location">
												<div className="label">Location</div>
												<div className="value">
													{interview.location || "-"}
												</div>
											</div>
										</div>
									</div>
									<div className="candidate-column">
										<div className="avatar">
											{/*<img src={interview.candidate_avatar} alt=""/>*/}
											<div className="name-as-avatar">
												{getShortName(interview.candidate_name)}
											</div>
										</div>

										<div className="info candidate">
											<div className="label">Candidate</div>
											<div className="value">
												{interview.candidate_name}
											</div>
										</div>
										<div className="info company">
											<div className="label">Company</div>
											<div className="value">
												{interview.candidate_company}
											</div>
										</div>
										<div className="info role">
											<div className="label">Role</div>
											<div
												className="value"
												title={interview.candidate_role}
											>
												{interview.candidate_role
													? interview.candidate_role.length >
													  50
														? interview.candidate_role.substr(
																0,
																50,
														  ) + "..."
														: interview.candidate_role
													: "-"}
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</animated.div>
	);
};

export default InterviewScheduleWithTimeline;
