/** @format */

import React from "react";

const CaretRightIcon = ({color = "#141414", height = 10, width = 7}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 7 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.14997 4.4002C6.26722 4.5173 6.33316 4.67616 6.33331 4.84187V5.15854C6.33139 5.32388 6.26571 5.4821 6.14997 5.6002L1.86664 9.8752C1.78841 9.95408 1.68191 9.99845 1.57081 9.99845C1.45971 9.99845 1.35321 9.95408 1.27497 9.8752L0.683308 9.28354C0.604921 9.20673 0.560748 9.10161 0.560748 8.99187C0.560748 8.88213 0.604921 8.77701 0.683308 8.7002L4.39164 5.0002L0.683308 1.3002C0.604428 1.22197 0.560059 1.11547 0.560059 1.00437C0.560059 0.89327 0.604428 0.786772 0.683308 0.708536L1.27497 0.125202C1.35321 0.0463222 1.45971 0.00195312 1.57081 0.00195312C1.68191 0.00195312 1.78841 0.0463222 1.86664 0.125202L6.14997 4.4002Z"
				fill={color}
			/>
		</svg>
	);
};

export default CaretRightIcon;
