import React, {useEffect, useState} from "react";
import IconEyeActive from "../../assets/images/eye-icon-active.png";
import IconEyeInactive from "../../assets/images/eye-icon-inactive.png";
import projectApi from "../../api/report/project";
import {toast} from "react-toastify";
import {setSummaryData} from "../../store/project/projectActions";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../components/Plugins/Loader";

const CandidatesByStage = ({
	stages,
	setStages,
	totalCandidates,
	researcherMode,
	projectId,
}) => {
	const {summaryData} = useSelector((state) => state.project);
	const [displayedStages, setDisplayedStages] = useState([]);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const handleStageClick = async (index) => {
		let updatedStages = displayedStages;

		updatedStages = updatedStages.map((item, s) => {
			if (index === s) {
				item.is_active = !item.is_active;
			}

			return item;
		});

		await sendRequest(updatedStages);
	};

	const sendRequest = async (data) => {
		setLoading(true);

		await projectApi
			.saveProjectInfo(projectId, {candidate_categories: data})
			.then((response) => {
				if (response.status >= 200 && response.status <= 204) {
					const updatedStages = response.data.candidate_categories;

					dispatch(
						setSummaryData({
							...summaryData,
							stages: updatedStages,
						}),
					);
					setStages(updatedStages);
					setDisplayedStages(updatedStages);
				} else {
					toast.error("Something went wrong!");
				}
			})
			.catch((error) => console.log(error))
			.finally(() => setLoading(false));
	};

	const InlineLoaderComponent = (
		<Loader
			height={15}
			width={20}
			customStyle={{display: "inline-flex"}}
			color={"#DDC89A"}
		/>
	);

	useEffect(() => {
		setDisplayedStages(stages);
	}, [stages]);

	const isAllowedInteraction = (stageLabel) => {
		return !(
			stageLabel.toLowerCase() === "client interviewing" ||
			stageLabel.toLowerCase() === "savannah interviewing"
		);
	};

	return (
		<div>
			<div className={"section-title"}>
				<span>Candidates by Stage</span>
				<span className="total-counts">{totalCandidates} Candidates</span>
			</div>

			<div className="info-with-progress">
				{displayedStages?.length
					? displayedStages.map((item, s) => (
							<div
								className={[
									"block",
									item.is_active ? "default" : "inactive",
								].join(" ")}
								key={s}
							>
								<div className="info-row">
									<span
										className={[
											"label",
											item.is_active ? "default" : "inactive",
										].join(" ")}
									>
										{item.label}
									</span>
								</div>

								<div
									className={[
										"progress-bar",
										item.is_active ? "default" : "inactive",
									].join(" ")}
								>
									<progress
										id="progressbar"
										className={
											researcherMode
												? "researcher-mode"
												: "client-mode"
										}
										value={item.value}
										max={totalCandidates}
									/>

									<span
										className={[
											"value",
											item.is_active ? "default" : "inactive",
										].join(" ")}
									>
										{item.value}
									</span>

									{researcherMode && (
										<span className="eye-icon">
											{isAllowedInteraction(item.label) &&
												(loading ? (
													InlineLoaderComponent
												) : (
													<img
														className="cursor"
														onClick={() =>
															handleStageClick(s)
														}
														src={
															item.is_active
																? IconEyeInactive
																: IconEyeActive
														}
														height={14}
														alt=""
													/>
												))}
										</span>
									)}
								</div>
							</div>
					  ))
					: "-"}
			</div>
		</div>
	);
};

export default CandidatesByStage;
