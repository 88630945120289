import React from "react";

const UploadIcon = (props) => (
	<svg
		width={16}
		height={16}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 16 16"
		{...props}
	>
		<mask
			id="UploadIcon_svg__a"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={16}
			height={16}
		>
			<path fill="#D9D9D9" d="M0 0h16v16H0z" />
		</mask>
		<g mask="url(#UploadIcon_svg__a)">
			<path
				d="M4.001 13.333c-.366 0-.68-.13-.941-.391A1.284 1.284 0 0 1 2.668 12v-2h1.333v2h8v-2h1.334v2c0 .367-.13.68-.392.942-.261.26-.575.391-.942.391h-8Zm3.334-2.667V5.233L5.6 6.967 4.668 6l3.333-3.333L11.335 6l-.934.966-1.733-1.733v5.433H7.335Z"
				fill="#141414"
			/>
		</g>
	</svg>
);

export default UploadIcon;
