import HttpClient from "../api";

class PeopleApi extends HttpClient {
	async uploadLogo(candidateId, formBody) {
		return await this.doPatch(`/candidates/${candidateId}/avatar`, formBody, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
	}

	async restoreLogo(candidateId) {
		return await this.doDelete(`/candidates/${candidateId}/avatar`);
	}
}

const peopleApi = new PeopleApi();

export default peopleApi;
