/** @format */

import {confirmAlert} from "react-confirm-alert";
import React from "react";
import "styles/Report/_confrmAlert.scss";
import classNames from "classnames";

export default ({
	handlePressYes,
	title = "Are you sure?",
	message = "You want to delete this file?",
	yesButtonLabel = "Yes",
	noButtonLabel = "No",
	afterClose = null,
	buttonClass = "btn-red",
}) => {
	return confirmAlert({
		title,
		message,
		customUI: ({onClose}) => {
			return (
				<div className="react-confirm-alert-body">
					<h1>{title}</h1>
					<p>{message}</p>

					<div className="react-confirm-alert-button-group">
						<button
							onClick={() => {
								onClose();

								if (afterClose) {
									afterClose();
								}
							}}
							className="btn btn-white"
						>
							{noButtonLabel}
						</button>
						<button
							className={classNames("btn", buttonClass)}
							onClick={async () => {
								handlePressYes();
								onClose();
							}}
						>
							{yesButtonLabel}
						</button>
					</div>
				</div>
			);
		},
	});
};
