import React, {useState} from "react";

const Tabs = ({options = [], activeIndex = 0, onActive}) => {
	const [tabIndex, setTabIndex] = useState(activeIndex);

	const setActiveTab = (index) => {
		setTabIndex(index);
		onActive(index);
	};

	return (
		<div className="tabs-wrapper">
			{options.map((tab, tI) => (
				<div
					className={["tab", tabIndex === tI ? "active" : "not-active"].join(
						" ",
					)}
					onClick={() => setActiveTab(tI)}
					key={tI}
				>
					{tab}
				</div>
			))}
		</div>
	);
};

export default Tabs;
