import AuthApi from "api/auth";
import HttpClient from "api/api";
import {
	getUserNameFailed,
	getUserNameFetch,
	getUserNameSuccess,
	loginFailed,
	loginFetch,
	loginSuccess,
	logout,
} from "./userActions";

const handleUserLoggedIn = async (result, dispatch) => {
	try {
		window.localStorage.clear();
		window.localStorage.setItem("token", "Bearer " + result.data.access_token);
		window.localStorage.setItem("refresh_token", result.data.refresh_token);
		window.localStorage.setItem(
			"expires_in",
			new Date().getTime() + 1000 * result.data.expires_in,
		);

		await HttpClient.resetConstructor();
		const responseData = await AuthApi.getUser();
		const user = responseData.data.result;

		if (user.is_active) {
			const savedUser = {
				firstName: user.first_name,
				lastName: user.last_name,
				image: user.image,
				role: user.role,
				email: user.email,
				company: user.company,
				companyId: user.company_id,
				is_invenias_user: user.is_invenias || false,
				user_id: user.user_id,
			};

			window.localStorage.setItem("user", JSON.stringify(savedUser));
			dispatch(loginSuccess(savedUser));

			return true;
		} else {
			localStorage.clear();

			const error = {
				message:
					"This account is not active yet! Contact administrator for more information!",
			};

			dispatch(loginFailed({error}));

			return {error};
		}
	} catch (e) {
		console.log(e);

		return {
			message: "Authentication failed!",
		};
	}
};

export const loginUser = (userData) => async (dispatch) => {
	dispatch(loginFetch());
	let result = {};

	try {
		result = await AuthApi.login(userData);
	} catch (error) {
		result = {error: error.data};
	}

	if (result.status === 200) {
		return handleUserLoggedIn(result, dispatch);
	} else {
		const error = result.data.detail;

		dispatch(loginFailed({error}));

		return {error};
	}
};

export const logoutUser = () => (dispatch) => {
	dispatch(logout());

	try {
		AuthApi.logout({
			refresh_token: window.localStorage.getItem("refresh_token"),
		});
	} catch (error) {
		console.log(error);
	}

	window.localStorage.clear();
	window.location.reload();

	// window.localStorage.clear();
};

export const logoutBulk = () => async () => {
	try {
		await AuthApi.logoutBulk({
			refresh_token: window.localStorage.getItem("refresh_token"),
		});

		return true;
	} catch (error) {
		console.log(error);

		return false;
	}
};

export const getUserName = (email) => async (dispatch) => {
	dispatch(getUserNameFetch());
	let result = {};

	try {
		result = await AuthApi.getUserName({email});
	} catch (error) {
		result = {error: error.data};
	}

	if (result.status === 204) {
		dispatch(getUserNameSuccess(email));

		return true;
	} else {
		const error = result.data.detail;

		dispatch(getUserNameFailed({error}));

		return {error};
	}
};

export const verifyEmailAccount = (userData) => async () => {
	// dispatch(resetPasswordFetch());
	let result = {};

	try {
		result = await AuthApi.verifyEmail(userData);
	} catch (error) {
		result = {error: error.data};
	}

	if (result.status === 200) {
		// dispatch(resetPasswordSuccess());
		return true;
	} else {
		const error = result.data.detail;
		// dispatch(resetPasswordFailed({error}));

		return {error};
	}
};

export const resetPassword = (code, formData) => async () => {
	let result = {};

	const data = {...formData};
	delete data.code;

	try {
		result = await AuthApi.resetPassword(code, formData);
	} catch (error) {
		result = {error: error.data};
	}

	if (result.status === 200) {
		return true;
	} else {
		const error = result.data.detail;

		return {error};
	}
};

export const registerUser = (code, formData) => async (dispatch) => {
	let result = {};

	try {
		result = await AuthApi.register(code, formData);
	} catch (error) {
		result = {error: error.data};
	}

	if (result.status === 201) {
		return handleUserLoggedIn(result, dispatch);
	} else {
		const error = result.data.detail;

		return {error};
	}
};
