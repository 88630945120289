/** @format */

import React, {useCallback, useEffect, useState} from "react";
import useModalHook from "../../../hooks/useModalHook";
import {
	convertBeDateToFriendlyDate,
	convertFriendlyDateToBeDate,
} from "../../../helpers/string";
import projectApi from "../../../api/report/project";
import {toast} from "react-toastify";
import CloseIconBold from "../../../assets/icons/IconCloseBold";
import SelectComponent from "../../../components/Inputs/Select";
import TextInput from "../../../components/Inputs/TextInput";
import IconTrash from "../../../assets/icons/IconTrash";
import CalendarIcon from "../../../assets/icons/CalendarIcon";
import IconAdd from "../../../assets/icons/IconAdd";
import useDatePeriodValidation from "../../../hooks/useDatePeriodValidation";

const errorTexts = {
	companyErrorText: "Please select a company",
	jobTitleErrorText: "Role can not be empty!",
};

const emptyPosition = {
	job_title: null,
	start_date: null,
	end_date: null,
	is_new: true,
};
const initialPositionValidation = {
	job_title: {valid: undefined},
	start_date: {valid: undefined, valid_date_range: undefined},
	end_date: {valid: undefined, valid_date_range: undefined},
};

const defaultLogo =
	"https://storage.googleapis.com/nebula-static/logos30x30/empty_logo.png";

const initialExperienceData = {
	company: {
		custom_name: null,
		id: null,
		invenias_name: null,
		logo: defaultLogo,
	},
	positions: [{...emptyPosition}],
};

const ExperienceModal = ({
	modalDisplay = false,
	setModalDisplay,
	experienceData = null,
	updateExperiences,
	projectId,
	candidateId,
}) => {
	const {
		modalIsOpen,
		setIfModalIsOpen,
		customStyles,
		Modal,
		loading,
		setLoading,
		InlineLoaderComponent,
	} = useModalHook({content: {width: "528px"}}, modalDisplay);
	const {datePeriodValidateByKey, dateErrorTextByKey, didDateRangeValidationFailed} =
		useDatePeriodValidation();

	const [company, setCompany] = useState("");
	const [isEditMode, setIsEditMode] = useState(false);
	const [companies, setCompanies] = useState([]);
	const [companiesLoading, setIsCompaniesLoading] = useState(false);
	const [displayAddNewRoleIcon, setDisplayAddNewRoleIcon] = useState(false);
	const [experience, setExperience] = useState({...initialExperienceData});
	const [deletedExperiences, setDeletedExperiences] = useState([]);
	const [isSaveDisabled, setIfSaveDisabled] = useState(true);
	const [companyValidation, setCompanyValidation] = useState({
		companyId: {valid: undefined},
	});
	const [positionsValidation, setPositionsValidation] = useState([
		{...initialPositionValidation},
	]);

	const handleAddNewRole = async () => {
		const positions = [...experience.positions, {...emptyPosition}];
		const updatedExp = {...experience, positions};

		await setExperience(updatedExp);
		await setDisplayAddNewRoleIcon(false);
		setIfSaveDisabled(true); // because we're adding new role with empty data;
		setPositionsValidation([
			...positionsValidation,
			{...initialPositionValidation},
		]);
	};

	const removeCurrentRole = async (positionIndex) => {
		let positions = experience.positions;

		if (positions[positionIndex] === undefined) return;

		if (isEditMode && positions[positionIndex]?.id) {
			const deletedData = {
				id: positions[positionIndex].id,
				is_deleted: true,
			};

			delete deletedData.initial_state;
			setDeletedExperiences([...deletedExperiences, deletedData]);
		}
		positions = positions.filter((_, index) => index !== positionIndex);
		const data = {company: experience.company, positions: [...positions]};

		setExperience({...data});
		validationChecker({...data});
		setAddIconStatus({...data});
	};

	const handleInputChange = (value, key, positionIndex = null) => {
		let updatedExperience = {...experience};

		if (positionIndex !== null && positionIndex > -1) {
			updatedExperience.positions[positionIndex][key] = value;
			setAddIconStatus(updatedExperience);

			const validationData = [...positionsValidation];

			// HERE VALIDATION RULE IS: JOB TITLE CAN NOT BE EMPTY,
			// START DATE CAN NOT BE EMPTY AND SHOULD BE CORRECT DATE FORMAT,
			// END DATE CAN BE EMPTY BUT IF DATA ENTERED, IT SHOULD BE CORRECT DATE FORMAT

			if (value) {
				if (key === "start_date" || key === "end_date") {
					const fromDate =
						key === "start_date"
							? value
							: updatedExperience?.positions[positionIndex]?.start_date;
					const toDate =
						key === "end_date"
							? value
							: updatedExperience?.positions[positionIndex]?.end_date;

					validationData[positionIndex][key] = datePeriodValidateByKey(
						key,
						fromDate,
						toDate,
					);
				} else if (key === "job_title") {
					validationData[positionIndex][key] = {valid: true};
				}
			} else if (key === "job_title" || key === "start_date") {
				validationData[positionIndex][key] = {
					...validationData[positionIndex][key],
					valid: false,
				};
				setIfSaveDisabled(true);
			} else if ((value === null || value === "") && key === "end_date") {
				validationData[positionIndex][key] = {valid: true};
				updatedExperience.positions[positionIndex][key] = null;
			}

			setPositionsValidation([...validationData]);
		} else if (key === "company") {
			updatedExperience[key] = value;
		} else {
			updatedExperience["company"][key] = value;
		}

		setExperience({...updatedExperience});
		validationChecker(updatedExperience);
	};

	const validationChecker = (updatedExperience) => {
		let isValid;

		isValid = updatedExperience.company?.id !== null;

		if (!isValid) {
			setIfSaveDisabled(true);

			return;
		}

		for (let item of updatedExperience.positions) {
			const {start_date, end_date} = item;
			const isFailed = didDateRangeValidationFailed({
				start_date,
				end_date,
			});

			console.log("isFailed", isFailed);
			if (item.job_title === null || item.job_title === "" || isFailed) {
				isValid = false;
				break;
			}

			isValid = true;
		}

		setIfSaveDisabled(!isValid);
	};

	const save = async () => {
		setLoading(true);

		const formData = {
			company: {
				id: experience.company.id,
				custom_name: experience.company.custom_name,
				invenias_name: experience.company.invenias_name,
				logo: null,
			},
			positions: [
				...experience.positions.map((item) => {
					if (isEditMode) {
						const compareState = item.initial_state || null;

						delete item.initial_state;
						item.has_changed =
							compareState !== null
								? JSON.stringify(item) !== compareState
								: false;
					}

					item.start_date = convertFriendlyDateToBeDate(item.start_date);
					item.end_date = convertFriendlyDateToBeDate(item.end_date) || null;

					return item;
				}),
				...deletedExperiences,
			],
		};

		await projectApi
			.saveCandidateInfo(projectId, candidateId, {experience: formData})
			.then((response) => {
				if (response.status >= 200 && response.status < 205) {
					toast.success("Saved Successfully");
					setExperience({...initialExperienceData});
					const {experiences} = response.data;

					updateExperiences(experiences);

					setTimeout(() => {
						setModalDisplay(false);
					}, 1000);
				} else {
					toast.error("Something went wrong!");
				}

				setIfModalIsOpen(false);
			})
			.catch((error) => console.log(error));

		setLoading(false);
		setIsEditMode(false);
		setCompanyValidation({companyId: {valid: undefined}});
		setPositionsValidation([{...initialPositionValidation}]);
		setDeletedExperiences([]);
	};

	const handleClose = () => {
		setExperience({...initialExperienceData});
		setIfModalIsOpen(false);
		setDeletedExperiences([]);
		setDeletedExperiences([]);

		setTimeout(() => {
			setModalDisplay(false);
		}, 1000);
	};

	const getCompanies = useCallback(async () => {
		if (company.length < 3) return;

		setIsCompaniesLoading(true);
		await projectApi
			.searchCompanies(company)
			.then((response) => {
				const data = response.data.companies.map((item) => {
					return {
						custom_name: null,
						invenias_name: item.name,
						id: item.id,
						logo: item.logo_url || null,
						label: item.name,
						value: item.company_id,
					};
				});

				setCompanies(data);
			})
			.catch((e) => {
				console.log(e);
			});

		setIsCompaniesLoading(false);
	}, [company]);

	const setAddIconStatus = (data = []) => {
		let latestRole;

		if (data.length) {
			latestRole = data[data.length - 1];
		} else {
			latestRole = data.positions[data.positions.length - 1];
		}

		if (latestRole.job_title !== null && latestRole.start_date !== null) {
			setDisplayAddNewRoleIcon(true);
		} else {
			setDisplayAddNewRoleIcon(false);
		}
	};

	const handleEditExperience = useCallback(() => {
		let positionData = [];
		let positionValidationData = [];

		for (let item of [...experienceData.positions]) {
			item.start_date = convertBeDateToFriendlyDate(item.start_date) || null;
			item.end_date = convertBeDateToFriendlyDate(item.end_date) || null;
			item.has_changed = false;
			item.initial_state = JSON.stringify(item);

			positionData = [...positionData, {...item}];
			positionValidationData = [
				...positionValidationData,
				{
					job_title: {valid: true},
					start_date: {valid: true},
					end_date: {valid: true},
				},
			];
		}

		const data = {
			company: {...experienceData.company},
			positions: [...positionData],
		};

		setExperience(data);
		setCompanyValidation({companyId: {valid: true}});
		setPositionsValidation(positionValidationData);
		setIfModalIsOpen(true);
		setAddIconStatus(data);
		setIsEditMode(true);
		setDeletedExperiences([]);
	}, [experienceData, setIfModalIsOpen]);

	useEffect(() => {
		if (experienceData) {
			handleEditExperience();
		} else {
			setExperience({
				...initialExperienceData,
				positions: [{...emptyPosition}],
			});
		}
	}, [experienceData, handleEditExperience]);

	useEffect(() => {
		getCompanies().then((r) => r);
	}, [getCompanies, company]);

	const getFromDateErrorText = (startDate) => dateErrorTextByKey(startDate);

	const getToDateErrorText = (endDate) => dateErrorTextByKey(endDate);

	return (
		<div>
			<Modal
				isOpen={modalIsOpen}
				style={customStyles}
				contentLabel="Experience Modal"
			>
				<div className="reports-modal experience-modal" style={{width: "auto"}}>
					<div className="modal-title">
						<span className="title">Experiences</span>
						<CloseIconBold
							className="close-icon cursor"
							onClick={handleClose}
						/>
					</div>

					<div className="content">
						{experience.company.id && (
							<div className="col company-logo-column">
								<img
									src={experience.company.logo || defaultLogo}
									alt=""
								/>
							</div>
						)}

						<div className="col input-wrapper company-column">
							<label htmlFor="">Company *</label>
							<SelectComponent
								altDropdownIndicator={true}
								borderColor={
									!companyValidation.companyId.valid &&
									companyValidation.companyId.valid !== undefined
										? "red"
										: "#dedede"
								}
								errorText={
									!companyValidation.companyId.valid &&
									companyValidation.companyId.valid !== undefined
										? errorTexts.companyErrorText
										: null
								}
								onChange={(company, triggerAction) => {
									if (triggerAction.action === "clear") {
										setCompanyValidation({
											companyId: {valid: false},
										});
										setExperience({
											...initialExperienceData,
										});
										setIfSaveDisabled(true);
									} else {
										handleInputChange(company, "company");
										setCompanyValidation({
											companyId: {valid: true},
										});
									}
								}}
								onInputChange={(query) => setCompany(query)}
								defaultValue={
									experience.company.invenias_name
										? {
												label: experience.company.invenias_name,
												value: experience.company.id,
										  }
										: null
								}
								options={companies}
								isSearchable={true}
								isClearable={true}
								placeholder="Type to select"
								isLoading={companiesLoading}
							/>
						</div>

						{experience.company.id && (
							<div className="col input-wrapper company-name">
								<label htmlFor="">Custom Company Name</label>

								<TextInput
									customClass="input"
									onChange={(e) =>
										handleInputChange(e.target.value, "custom_name")
									}
									placeholder="Custom Name"
									defaultValue={experience.company.custom_name}
								/>
							</div>
						)}

						{experience.positions.map((item, positionIndex) => (
							<div
								className="role-form-wrapper"
								key={`${positionIndex}.${item.id?.toString()}`}
							>
								<div className="role-row col input-wrapper">
									<label htmlFor="">Role *</label>

									<div className="text-input-with-icon">
										<TextInput
											customClass="input"
											placeholder="Role"
											errorText={
												positionsValidation[positionIndex]
													?.job_title?.valid !== undefined &&
												!positionsValidation[positionIndex]
													?.job_title?.valid
													? errorTexts.jobTitleErrorText
													: null
											}
											onChange={(e) =>
												handleInputChange(
													e.target.value,
													"job_title",
													positionIndex,
												)
											}
											defaultValue={item.job_title}
										/>

										{(positionIndex > 0 ||
											experience?.positions.length > 1) && (
											<IconTrash
												onClick={() =>
													removeCurrentRole(positionIndex)
												}
												className="trash-icon cursor"
											/>
										)}
									</div>
								</div>

								<div className="col date-row">
									<div className="date input-wrapper">
										<label htmlFor="">From *</label>

										<CalendarIcon className="calender-icon" />

										<TextInput
											customClass="input datepicker-input"
											placeholder="dd.mm.yyyy"
											errorText={getFromDateErrorText(
												positionsValidation[positionIndex]
													?.start_date,
											)}
											onChange={(e) =>
												handleInputChange(
													e.target.value,
													"start_date",
													positionIndex,
												)
											}
											defaultValue={item.start_date}
										/>
									</div>

									<div className="date input-wrapper">
										<label htmlFor="">To</label>

										<CalendarIcon className="calender-icon" />
										<TextInput
											customClass="input datepicker-input"
											placeholder="dd.mm.yyyy"
											errorText={getToDateErrorText(
												positionsValidation[positionIndex]
													?.end_date,
											)}
											onChange={(e) =>
												handleInputChange(
													e.target.value,
													"end_date",
													positionIndex,
												)
											}
											defaultValue={item.end_date}
										/>
									</div>
								</div>
							</div>
						))}

						{displayAddNewRoleIcon && (
							<div className="col add-icon-with-label">
								<span className="cursor" onClick={handleAddNewRole}>
									Add new Role <IconAdd />
								</span>
							</div>
						)}

						<div className="buttons">
							<button className="btn btn-white" onClick={handleClose}>
								Cancel
							</button>

							<button
								className={[
									"btn",
									loading || isSaveDisabled
										? "btn-white btn-disabled"
										: "btn-red",
								].join(" ")}
								disabled={loading || isSaveDisabled}
								onClick={save}
							>
								{loading ? (
									<>Saving {InlineLoaderComponent}</>
								) : (
									"Save Changes"
								)}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default ExperienceModal;
