import React, {useEffect, useState} from "react";
import IconPencil from "assets/icons/IconPencil";
import {useSelector} from "react-redux";
import moment from "moment";
import {getShortName} from "helpers/string";
import IconAdd from "assets/icons/IconAdd";
import ReactMarkdown from "react-markdown";
import LatestUpdateModal from "./Modals/LatestUpdateModal";

const LatestUpdatesSection = ({latestUpdates, projectId}) => {
	const researchMode = useSelector((state) => state.user.researchModeEnabled);
	const [displayedLatestUpdates, setDisplayedLatestUpdates] = useState(
		latestUpdates || [],
	);
	const [dataToEdit, setDataToEdit] = useState(null);
	const [modalDisplay, setModalDisplay] = useState(false);

	useEffect(() => {
		setDisplayedLatestUpdates(latestUpdates);
	}, [latestUpdates]);

	const handleUpdateEdit = (data) => {
		setDataToEdit({...data});
		setModalDisplay(true);
	};

	return (
		<>
			<div className="row">
				<div className={"section-title"}>Latest updates</div>

				{researchMode && (
					<div
						className="add-update"
						onClick={() => {
							setDataToEdit(false);
							setModalDisplay(true);
						}}
					>
						<IconAdd />
						<span className="label">Add Update</span>
					</div>
				)}
			</div>

			<div className="row client-summary-card-wrapper custom-scrollbar">
				{displayedLatestUpdates.length ? (
					displayedLatestUpdates.map((data, index) => (
						<div
							className={[
								"candidate-summary-card",
								data?.is_read ? "read" : "unread",
							].join(" ")}
							key={index}
						>
							<div className="avatar">
								{data.user.image ? (
									<img src={data.user.image} alt="" />
								) : (
									<div
										className="name-as-avatar"
										style={{
											height: "40px",
											width: "40px",
											fontSize: "13px",
										}}
									>
										{getShortName(
											data.user.first_name,
											data.user.last_name,
										)}
									</div>
								)}
							</div>
							<div className="summary-info">
								<div className="meta-info">
									<div className="intro">
										<span className="title">
											{data.user.first_name +
												" " +
												data.user.last_name}
										</span>

										{data?.user?.job_title && (
											<span className="designation">
												{data.user.job_title || "-"}
											</span>
										)}
									</div>
									<div className="extra-meta">
										<span
											className={[
												"date",
												!researchMode && data.isRead
													? "client-mode"
													: "researcher-mode",
											].join(" ")}
										>
											{moment(data.date_created).format(
												"DD/MM/yyyy hh:mm",
											)}
										</span>

										{!researchMode && !data?.is_read && (
											<span className="is-read">Unread</span>
										)}

										{researchMode && (
											<IconPencil
												className="edit-icon cursor"
												onClick={() => handleUpdateEdit(data)}
											/>
										)}
									</div>
								</div>
								<div className="short-desc">
									<ReactMarkdown>{data.message}</ReactMarkdown>
								</div>
							</div>
						</div>
					))
				) : (
					<div className="candidate-summary-card">-</div>
				)}
			</div>

			{modalDisplay && (
				<LatestUpdateModal
					projectId={projectId}
					data={dataToEdit}
					setLatestUpdates={setDisplayedLatestUpdates}
					setModalDisplay={setModalDisplay}
				/>
			)}
		</>
	);
};

export default LatestUpdatesSection;
