import {NotPinned} from "assets/icons/NotPinned";
import {Pinned} from "assets/icons/Pinned";
import {PencilIcon} from "assets/icons/PencilIcon";
import React from "react";
import {Link} from "react-router-dom";
import {DisplayCompany} from "rs-pages/Home";
import {timeAgo} from "helpers/timeDifference";
import moment from "moment";
import ProgressStatus from "rs-components/ProgressStatus";

export const ProjectRow = ({item, researchMode, onPinHandler}) => {
	return (
		<div
			className={`card content-row ${
				item.status === "Active" ? "active" : "inactive"
			} ${item.is_pinned ? "pinned" : ""} row-grid`}
		>
			<div className={"item company-wrapper"}>
				<DisplayCompany
					item={item}
					link={`/projects/${item.id}/summary`}
					uploadModalAccessible={researchMode}
				/>
			</div>
			<div className={"item job-title"}>
				{
					// item.is_clickable ? (
					<>
						<div className="category">Role</div>
						<Link to={`/projects/${item.id}/summary`} className="link">
							{item.assignment_name}
						</Link>
					</>
					// ) : (
					//     <span style={{color: 'gray'}}>{item.assignment_name}</span>
					// )
				}
			</div>
			<div className={"item start-date"}>
				<div className="category">Last Updated</div>
				{item.last_published_date ? timeAgo(item.last_published_date) : ""}

				<span className="note">
					{item.last_published_date
						? moment(item.last_published_date).format("DD MMMM yyyy")
						: "-"}
				</span>
			</div>

			<div className={"item status"}>
				<div className="category">Status</div>
				<div className="progress-block">
					<div className="progress-status-wrapper">
						{!researchMode ? (
							<ProgressStatus
								progressLevel={item?.progress_status?.level}
								status={item?.progress_status?.display_text}
								showLabel={false}
							/>
						) : (
							<div className="active-text">{item.status}</div>
						)}
					</div>
				</div>
				{/* <div>
					{item.status === "Active" ? <Active /> : <Inactive />}
					<span>{item.status === "Active" ? "Interviewing" : "Closed"}</span>
				</div> */}
			</div>

			<div className={"item candidates"}>
				<div className="category">Candidates</div>
				{item.total_candidates || "-"}
			</div>

			{researchMode && (
				<div className={"item item-actions"}>
					<Link to={`/projects/${item.id}/edit`}>
						<PencilIcon />
					</Link>

					<div className="pin-btn" onClick={onPinHandler}>
						{item.is_pinned ? <Pinned /> : <NotPinned />}
					</div>
				</div>
			)}
		</div>
	);
};
