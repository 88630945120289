import React from "react";
import "rs-components/PrintLayout/printLayout.scss";
import {SavannahLogoWhite} from "assets/icons/SavannahLogo";

const PrintLayout = ({informationBlock = null, children}, ref) => {
	return (
		// eslint-disable-next-line react/no-unknown-property
		<div className="print-container" layout="portrait" ref={ref}>
			<div className="page-header">
				<div className="logo">
					<SavannahLogoWhite className="logoWithText" />
				</div>

				{informationBlock && (
					<div className="information-block">{informationBlock}</div>
				)}
			</div>

			<div className="page-footer" />

			{children}
		</div>
	);
};

export default React.forwardRef(PrintLayout);
