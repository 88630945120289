import projectApi from "api/report/project";

import {
	updateCandidateOrderingDone,
	updateCandidateOrderingFail,
	updateCandidateOrderingInit,
} from "./projectActions";

export const updateCandidateOrdering =
	({projectId, candidateId, index}) =>
	async (dispatch) => {
		try {
			dispatch(updateCandidateOrderingInit());

			const response = await projectApi.updateCandidateOrdering(
				projectId,
				candidateId,
				index,
			);

			if (response.status === 200) {
				dispatch(updateCandidateOrderingDone());
			} else {
				throw new Error(response.data.detail);
			}
		} catch (e) {
			dispatch(updateCandidateOrderingFail(e));
		}
	};
