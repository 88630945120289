import {
	CLEAR_ASSESSMENT_DATA,
	CLEAR_SUMMARY_DATA,
	GET_INTERVIEWS,
	SET_ASSESSMENT_DATA,
	SET_CANDIDATES_DATA,
	SET_COMPANY_LOGO,
	SET_COUNTRIES,
	SET_CURRENCIES,
	SET_GENDER_DATA,
	SET_INTERVIEWS,
	SET_NOTICE_PERIODS,
	SET_PEOPLE_LOGO,
	SET_PROGRESS_STATUS,
	SET_SUMMARY_DATA,
	UPDATE_CANDIDATE_ORDERING_DONE,
	UPDATE_CANDIDATE_ORDERING_FAIL,
	UPDATE_CANDIDATE_ORDERING_INIT,
} from "../reportActionTypes";

export function setInterviews(payload) {
	return {type: SET_INTERVIEWS, payload};
}

export function getInterviewsData() {
	return {type: GET_INTERVIEWS};
}

export function setCandidatesData(payload) {
	return {type: SET_CANDIDATES_DATA, payload};
}

export function clearAssessmentData() {
	return {type: CLEAR_ASSESSMENT_DATA};
}

export function setAssessmentData(payload) {
	return {type: SET_ASSESSMENT_DATA, payload};
}

export function setSummaryData(payload) {
	return {type: SET_SUMMARY_DATA, payload};
}

export function setGenderData(payload) {
	return {type: SET_GENDER_DATA, payload};
}

export function clearSummaryData() {
	return {type: CLEAR_SUMMARY_DATA};
}

export function setCurrencies(payload) {
	return {type: SET_CURRENCIES, payload};
}

export function setProgressStatuses(payload) {
	return {type: SET_PROGRESS_STATUS, payload};
}

export function setNoticePeriods(payload) {
	return {type: SET_NOTICE_PERIODS, payload};
}

export function setCountries(payload) {
	return {type: SET_COUNTRIES, payload};
}

export function setCompanyLogo(payload) {
	return {type: SET_COMPANY_LOGO, payload};
}

export function setPeopleLogo(payload) {
	return {type: SET_PEOPLE_LOGO, payload};
}

export function updateCandidateOrderingInit() {
	return {type: UPDATE_CANDIDATE_ORDERING_INIT};
}

export function updateCandidateOrderingDone() {
	return {type: UPDATE_CANDIDATE_ORDERING_DONE};
}

export function updateCandidateOrderingFail(payload) {
	return {type: UPDATE_CANDIDATE_ORDERING_FAIL, error: payload};
}
