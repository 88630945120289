/** @format */

import React, {useState} from "react";
import IconUniversity from "../../assets/images/University.svg";
import IconPencil from "../../assets/icons/IconPencil";
import EducationModal from "./Modals/EducationModal";
import IconAdd from "../../assets/icons/IconAdd";
import {convertBeDateToFriendlyDate} from "../../helpers/string";

const EducationSection = ({educations, researcherMode, candidateId, projectId}) => {
	const [modalIsOpen, setIfModalIsOpen] = useState(false);
	const [displayedEducations, setDisplayedEducations] = useState([...educations]);
	const [activeIndex, setActiveIndex] = useState(null);
	const [educationDataToEdit, setEducationDataToEdit] = useState(null);

	const handleEditClick = (index) => {
		setActiveIndex(index);
		let data = {...displayedEducations[index]};

		data.start_date =
			data.start_date !== null
				? convertBeDateToFriendlyDate(data.start_date)
				: null;
		data.end_date =
			data.end_date !== null ? convertBeDateToFriendlyDate(data.end_date) : null;
		data.establishment = data?.establishment?.id
			? {
					value: data.establishment.id,
					label: data.establishment.name,
			  }
			: null;
		setEducationDataToEdit(data);
		setIfModalIsOpen(true);
	};

	const handleAddClick = () => {
		setActiveIndex(null);
		setEducationDataToEdit(null);
		setIfModalIsOpen(true);
	};

	return (
		<div className="education-section">
			<div className="section-title">
				Education
				{researcherMode && (
					<span
						onClick={handleAddClick}
						className="cursor"
						style={{marginRight: "5px"}}
					>
						<IconAdd />
					</span>
				)}
			</div>
			{displayedEducations?.length ? (
				displayedEducations.map((displayedEducation, eI) => (
					<div className="education" key={eI}>
						<div className="avatar">
							<img src={IconUniversity} alt="" />
						</div>
						<div className="info">
							<span className="institute-name">
								<label htmlFor="">
									{researcherMode
										? displayedEducation.establishment?.name || "-"
										: displayedEducation.establishment_name || "-"}
								</label>

								{researcherMode && (
									<IconPencil
										className="edit-icon cursor"
										color="#702C2E"
										onClick={() => handleEditClick(eI)}
									/>
								)}
							</span>
							<span>{displayedEducation.qualification}</span>
							{!!(
								displayedEducation.start_date ||
								displayedEducation.end_date
							) && (
								<span className="tenure">
									{`${
										displayedEducation.start_date
											? new Date(
													displayedEducation.start_date,
											  ).getFullYear()
											: ""
									}${
										displayedEducation.end_date
											? " - " +
											  new Date(
													displayedEducation.end_date,
											  ).getFullYear()
											: ""
									}`}
								</span>
							)}
						</div>
					</div>
				))
			) : (
				<div className="education">-</div>
			)}

			{modalIsOpen && (
				<EducationModal
					modalDisplay={modalIsOpen}
					key={"education-modal" + activeIndex}
					updateEducations={setDisplayedEducations}
					setModalDisplay={setIfModalIsOpen}
					educationData={educationDataToEdit}
					candidateId={candidateId}
					projectId={projectId}
				/>
			)}
		</div>
	);
};

export default EducationSection;
