import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import TextInput from "components/Inputs/TextInput";
import css from "./index.module.scss";
import {getUserName} from "store/user/userSelectors";
import useFormValidation from "hooks/useFormValidation";
import Footer from "rs-components/LayoutPartials/Footer";

const ForgotName = (props) => {
	const dispatch = useDispatch();
	const [error, setError] = useState(null);
	const initialUserData = {email: ""};
	const initialValidation = {
		email: {valid: false},
	};
	const [userData, validation, changeForm] = useFormValidation(
		initialUserData,
		initialValidation,
	);

	const onSubmit = async () => {
		const res = await dispatch(getUserName(userData.email));

		if (!res.error) {
			props.history.push("/login");
		} else {
			setError(res.error);
		}
	};

	const submitEnabled = validation.email.valid;

	return (
		<div className={css.container}>
			<div className={css.content}>
				<div className={css.logo} />
				<h1 className={css.title}>Forgot your user_name?</h1>
				<p className={css.subTitle}>We’ll email you your user_name</p>
				<form className={css.form}>
					<TextInput
						label="Email"
						name="email"
						type="text"
						placeholder="Enter your email"
						errorText={validation.email.error}
						onChange={(e) => changeForm("email", e.target.value)}
					/>
					{error ? <p className={css.error}>{error}</p> : null}
					<button
						type="button"
						disabled={!submitEnabled}
						onClick={onSubmit}
						className={css.submitButton}
					>
						Login
					</button>
				</form>
				<div className={css.links}>
					<Link to="/login" className={css.linkForgot}>
						Return to Login
					</Link>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default ForgotName;
