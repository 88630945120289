import {CLEAR_FORM, SET_ASSIGNMENTS, SET_FORM_DATA} from "../reportActionTypes";

export function setAssignments(payload) {
	return {type: SET_ASSIGNMENTS, payload};
}

export function setFormData(payload) {
	return {type: SET_FORM_DATA, payload};
}

export function clearFormData() {
	return {type: CLEAR_FORM};
}
