/** @format */

import React, {useEffect, useMemo, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import TextInput from "../../components/Inputs/TextInput";
import css from "./index.module.scss";
import Footer from "../../rs-components/LayoutPartials/Footer";
import {useOnAuth} from "../../hooks/useOnAuth";
import {onEnter} from "../../hooks/common";
import {newPasswordRules} from "../../helpers/validator";
import {SavannahLogo} from "../../assets/icons/SavannahLogo";
import SvgOpenEye from "assets/icons/OpenEye";
import SvgClosedEye from "assets/icons/ClosedEye";

const Register = (props) => {
	const [passwordValidations, setPasswordValidations] = useState(newPasswordRules);
	const [showPassword, setShowPassword] = useState(false);
	const search = useLocation().search;
	const invitationCode = new URLSearchParams(search).get("invitation_code");
	const projectId = new URLSearchParams(search).get("project_id");
	const email = new URLSearchParams(search).get("email");
	const userInitialData = {
		first_name: "",
		last_name: "",
		email,
		new_password: "",
		code: invitationCode,
		project_id: projectId,
	};
	const userInitialValidation = {
		first_name: {valid: false},
		last_name: {valid: false},
		email: {valid: false},
		new_password: {valid: false},
	};

	const {
		loading,
		changeForm,
		error,
		LoaderComponent,
		onSubmit,
		submitEnabled,
		validation,
	} = useOnAuth({
		history: props.history,
		type: "register",
		userInitialData,
		userInitialValidation,
		redirectUrl: projectId ? `/projects/${projectId}/summary` : "/home",
	});

	const eyeIcon = useMemo(
		() =>
			showPassword ? (
				<SvgClosedEye onClick={() => setShowPassword(false)} />
			) : (
				<SvgOpenEye onClick={() => setShowPassword(true)} />
			),
		[showPassword],
	);

	useEffect(() => {
		if (invitationCode === null) {
			props.history.push("/login");
		}
	}, [props.history, invitationCode, changeForm]);

	useEffect(() => {
		if (validation?.new_password) {
			setPasswordValidations((passwordValidations) => {
				return passwordValidations.map((item) => {
					if (validation.new_password.error !== undefined) {
						item.isValid = !validation.new_password.error.includes(
							item.rule,
						);
					}

					return item;
				});
			});
		}
	}, [validation]);

	return (
		<div className={css.container}>
			<div className={css.content}>
				{/*<div className={css.logo}/>*/}
				<div className={css.logoContainer}>
					<SavannahLogo />
				</div>

				<div
					style={{
						display: "flex",
						fontSize: "18px",
						justifyContent: "center",
					}}
				>
					Sign up for secure project access
				</div>
				<form
					className={[css.form, "register-form"].join(" ")}
					onKeyPress={(e) => onEnter(e, onSubmit)}
				>
					<TextInput
						label="First Name"
						name="first_name"
						type="text"
						placeholder="Enter First Name"
						errorText={validation.first_name.error}
						onChange={(e) => changeForm("first_name", e.target.value)}
						customClass={css.loginInput}
					/>

					<TextInput
						label="Last Name"
						name="last_name"
						type="text"
						placeholder="Enter Last Name"
						errorText={validation.last_name.error}
						onChange={(e) => changeForm("last_name", e.target.value)}
						customClass={css.loginInput}
					/>

					<TextInput
						label="Email"
						name="email"
						type="text"
						placeholder="Enter E-Mail"
						errorText={validation.email.error}
						onChange={(e) => changeForm("email", e.target.value)}
						customClass={css.loginInput}
						defaultValue={email}
					/>
					<TextInput
						label="Password"
						name="password"
						type={showPassword ? "name" : "password"}
						placeholder="Set password"
						// errorText={validation.new_password.error}
						onChange={(e) => changeForm("new_password", e.target.value)}
						passwordValidations={passwordValidations}
						customClass={css.loginInput}
						icon={eyeIcon}
					/>

					{error && <p className={css.error}>{error}</p>}

					<button
						type="button"
						disabled={!submitEnabled || loading}
						onClick={onSubmit}
						style={{marginTop: 20}}
						className={css.submitButton}
					>
						{loading ? LoaderComponent : "Register"}
					</button>
				</form>

				<div className={css.links}>
					<Link to="/login" className={css.linkForgot}>
						Has account already? return to login.
					</Link>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Register;
