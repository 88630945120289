/** @format */

import React from "react";

const CloseIcon = ({color = "none", ...rest}) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				color={color}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.61111 10L3.75 5.13889L5.13889 3.75L10 8.61111L14.8611 3.75L16.25 5.13889L11.3889 10L16.25 14.8611L14.8611 16.25L10 11.3889L5.13889 16.25L3.75 14.8611L8.61111 10Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default CloseIcon;
