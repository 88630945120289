import React from "react";
import PrintLayout from "rs-components/PrintLayout";
import AssignmentInfoAsHeader from "rs-components/AssignmentInfoAsHeader";
import classnames from "classnames";
import {candidateAvatar, companyInfo} from "rs-pages/Assesments/PrintCandidateList";
import "./printCandidateProfile.scss";
import {convertLocationConcatenatedString} from "helpers/string";
import {CompanyLogo} from "rs-components/CompanyLogo/CompanyLogo";
import IconUniversity from "assets/images/University.svg";
import {getPackages} from "rs-pages/ClientCandidate/RemunerationSection";
import ReactMarkdown from "react-markdown";
import {levels} from "rs-pages/ClientCandidate/BriefsSection";
import SkillLevelWithSquare from "rs-components/SkillLevelWithSquare";

const defaultLogo =
	"https://storage.googleapis.com/nebula-static/logos30x30/empty_logo.png";

const PrintCandidateProfile = (
	{
		isResearcher = false,
		candidate,
		summary,
		experiences,
		educations,
		remuneration,
		noticePeriod,
		briefs,
		displayProfilePics = true,
		displayFitToBriefs = true,
		displayRemuneration = true,
	},
	ref,
) => {
	const {packages} = getPackages(remuneration, isResearcher);

	if (!candidate) return;

	const getCandidateName = () => {
		return `${candidate?.first_name} ${candidate?.last_name}`.trim();
	};

	const children = (
		<table className="no-spacing print-candidate-profile">
			<thead>
				<tr>
					<td>
						<div className="page-header-space" />
					</td>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td colSpan={4}>
						<AssignmentInfoAsHeader isPrintPage={true} />
					</td>
				</tr>
				<tr>
					<td colSpan={3}>
						<div className={classnames("intro")}>
							<div className="avatar">
								{candidateAvatar(candidate, displayProfilePics)}
							</div>
							<span className="name">{getCandidateName()}</span>
						</div>
					</td>
					<td
						style={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
							minHeight: 80,
						}}
					>
						{candidate?.for_discussion && (
							<span className="highlighted">Highlighted</span>
						)}
					</td>
				</tr>
				<tr>
					<td style={{width: "32%"}}>
						<div className="info role">
							<div className="label">Role</div>
							<div className="value">{candidate.role?.trim()}</div>
						</div>
					</td>
					<td style={{width: "25%"}}>
						<div className="info company">
							<div className="details">
								<div className="label">Company</div>
								<div className="value">
									{companyInfo(candidate)?.name.trim()}
								</div>
							</div>
						</div>
					</td>
					<td style={{width: "23%"}}>
						<div className="info location">
							<div className="label">Location</div>
							<div className="value">
								{convertLocationConcatenatedString(
									candidate.location,
								)?.trim()}
							</div>
						</div>
					</td>
					<td style={{width: "20%"}}>
						<div className="info progress-status xs-only">
							<div className="label">Progress Status</div>
							<div className="value">
								{candidate.status_info
									? candidate.status_info.display_text
									: candidate.progress_info.status}
							</div>
						</div>
					</td>
				</tr>

				{!!experiences?.length &&
					experiences.map((exp, index) => (
						<tr key={`exp-${index}`}>
							<td colSpan={4}>
								{index === 0 && (
									<span className="block-title">Experience</span>
								)}
								<div className="experience">
									<div className="avatar">
										<CompanyLogo
											src={
												exp?.company?.logo
													? exp?.company?.logo
													: defaultLogo
											}
											name={exp?.company?.name}
											id={exp?.company?.id}
											alt=""
										/>
									</div>
									<div className="info experience-role">
										<div className="role">
											{exp.company
												? exp.company.custom_name ||
												  exp.company.invenias_name
												: "-"}
										</div>
										{exp.positions.map((role, rI) => (
											<div key={rI} className="item">
												<div className="title-with-dot">
													<span className="dot" />
													<span>{role.job_title}</span>
												</div>
												<div className="line-with-label tenure">
													{rI < exp.positions.length - 1 ? (
														<span className="line" />
													) : (
														<span style={{width: "18px"}} />
													)}

													{`${
														role.start_date
															? new Date(
																	role.start_date,
															  ).getFullYear()
															: ""
													}${
														role.end_date
															? " - " +
															  new Date(
																	role.end_date,
															  ).getFullYear()
															: ""
													}`}
												</div>
											</div>
										))}
									</div>
								</div>
							</td>
						</tr>
					))}

				{!!educations?.length &&
					educations.map((education, eI) => (
						<tr key={`educations-${eI}`}>
							<td colSpan={4}>
								{eI === 0 && (
									<span className="block-title">Education</span>
								)}
								<div
									className="education"
									key={eI}
									style={{display: "flex", alignItems: "center"}}
								>
									<div className="avatar">
										<img src={IconUniversity} alt="" />
									</div>
									<div className="info">
										<span className="institute-name">
											<label htmlFor="">
												{isResearcher
													? education?.establishment?.name ||
													  "-"
													: education?.establishment_name ||
													  "-"}
											</label>
										</span>
										<span>{education.qualification}</span>
										{!!(
											education.start_date || education.end_date
										) && (
											<span className="tenure">
												{`${
													education.start_date
														? new Date(
																education.start_date,
														  ).getFullYear()
														: ""
												}${
													education.end_date
														? " - " +
														  new Date(
																education.end_date,
														  ).getFullYear()
														: ""
												}`}
											</span>
										)}
									</div>
								</div>
							</td>
						</tr>
					))}

				{displayRemuneration && !!packages?.length && (
					<tr key={"remuneration"}>
						<td colSpan={4}>
							<div
								className="remuneration-section"
								style={{marginTop: 20}}
							>
								<div className="block-title">Remuneration</div>

								{packages.map((item, index) => (
									<div className="package info" key={`r-${index}`}>
										<span className="label">{item.label}</span>
										<span className="values">
											{item.values.length
												? item.values.map((value, vI) => (
														<span key={vI}>{value}</span>
												  ))
												: "-"}
										</span>
									</div>
								))}
							</div>
						</td>
					</tr>
				)}

				<tr>
					<td colSpan={4} style={{height: 50}}>
						<div className="notice-section" style={{marginTop: 20}}>
							<div className="block-title">Notice Period</div>

							<div className="info">
								{isResearcher && typeof noticePeriod === "object"
									? noticePeriod?.id !== null
										? noticePeriod?.display_text
										: "-"
									: noticePeriod && typeof noticePeriod === "string"
									? noticePeriod
									: "-"}
							</div>
						</div>
					</td>
				</tr>

				<tr>
					<td colSpan={4}>
						<div className="section-summary" style={{marginTop: 20}}>
							<div className="block-title">Summary</div>
							<div className="list-info" style={{padding: "16px 0"}}>
								<ReactMarkdown>{summary?.toString()}</ReactMarkdown>
							</div>
						</div>
					</td>
				</tr>

				{displayFitToBriefs && !!briefs.length && (
					<tr>
						<td colSpan={4}>
							<div className="briefs-wrapper" style={{marginTop: 20}}>
								<div className="block-title">Fit to brief</div>
								{briefs.map((brief, briefIndex) => {
									return (
										<div className="brief-item" key={briefIndex}>
											<div className="info-area">
												<div className="title">
													<span>{brief.title}</span>

													<SkillLevelWithSquare
														label={levels[brief.score - 1]}
														length={5}
														maxLevel={brief.score}
														labelPlace={"bottom"}
													/>
												</div>

												<div className="notes">
													{brief.comment?.length ? (
														brief.comment
															?.split("\n")
															.filter(
																(item) => item !== "",
															)
															.map(
																(
																	description,
																	noteIndex,
																) => (
																	<div
																		className="item"
																		key={noteIndex}
																	>
																		<div className="dot" />
																		<div className="note">
																			{
																				description
																			}
																		</div>
																	</div>
																),
															)
													) : (
														<div className="item">-</div>
													)}
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</td>
					</tr>
				)}
			</tbody>

			<tfoot>
				<tr>
					<td>
						<div className="page-footer-space" />
					</td>
				</tr>
			</tfoot>
		</table>
	);

	const informationBlock = <span>{getCandidateName()} | Candidate Detail</span>;

	return (
		<PrintLayout ref={ref} informationBlock={informationBlock}>
			{children}
		</PrintLayout>
	);
};

export default React.forwardRef(PrintCandidateProfile);
