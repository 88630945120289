import React from "react";

const ClosedEye = (props) => (
	<svg
		width={24}
		height={24}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		{...props}
	>
		<mask
			id="closed-eye_svg__a"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={24}
			height={24}
		>
			<path fill="#D9D9D9" d="M0 0h24v24H0z" />
		</mask>
		<g mask="url(#closed-eye_svg__a)">
			<path
				d="m16.1 13.3-1.45-1.45c.15-.783-.075-1.517-.675-2.2-.6-.683-1.375-.95-2.325-.8L10.2 7.4c.283-.133.57-.233.862-.3.292-.067.605-.1.938-.1 1.25 0 2.313.437 3.188 1.312S16.5 10.25 16.5 11.5c0 .333-.033.646-.1.938a4.247 4.247 0 0 1-.3.862Zm3.2 3.15-1.45-1.4a10.952 10.952 0 0 0 1.688-1.588A8.895 8.895 0 0 0 20.8 11.5c-.833-1.683-2.03-3.02-3.588-4.013C15.654 6.496 13.917 6 12 6c-.483 0-.958.033-1.425.1a9.625 9.625 0 0 0-1.375.3L7.65 4.85a11.107 11.107 0 0 1 2.1-.638A11.61 11.61 0 0 1 12 4c2.517 0 4.758.696 6.725 2.087C20.692 7.479 22.117 9.283 23 11.5a11.712 11.712 0 0 1-1.512 2.738A11.022 11.022 0 0 1 19.3 16.45Zm.5 6.15-4.2-4.15c-.583.183-1.17.321-1.762.413-.592.091-1.205.137-1.838.137-2.517 0-4.758-.696-6.725-2.087C3.308 15.521 1.883 13.717 1 11.5c.35-.883.792-1.704 1.325-2.463A11.522 11.522 0 0 1 4.15 7L1.4 4.2l1.4-1.4 18.4 18.4-1.4 1.4ZM5.55 8.4c-.483.433-.925.908-1.325 1.425A9.014 9.014 0 0 0 3.2 11.5c.833 1.683 2.029 3.02 3.587 4.012C8.346 16.504 10.083 17 12 17c.333 0 .658-.02.975-.062.317-.042.642-.088.975-.138l-.9-.95c-.183.05-.358.087-.525.112A3.484 3.484 0 0 1 12 16c-1.25 0-2.313-.437-3.188-1.312S7.5 12.75 7.5 11.5c0-.183.012-.358.037-.525a4.67 4.67 0 0 1 .113-.525L5.55 8.4Z"
				fill="#999"
			/>
		</g>
	</svg>
);

export default ClosedEye;
