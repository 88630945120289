import HttpClient from "../api";

class CompanyApi extends HttpClient {
	async uploadLogo(companyId, formBody) {
		return await this.doPatch(`/companies/${companyId}/logo`, formBody, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
	}

	async restoreLogo(companyId) {
		return await this.doDelete(`/companies/${companyId}/logo`);
	}
}

const companyApi = new CompanyApi();

export default companyApi;
