export const checkboxFilters = [
	{
		column: "Fit to Brief",
		values: [
			"All",
			"Real Business Leadership",
			"Commercial Acumen",
			"Operational Transformation and Change",
			"Strategy",
		],
	},
	{
		column: "Progress Status",
		values: ["Any status", "Identified", "Approached", "Engaged", "Interviewing"],
	},
	{
		column: "Location",
		values: [
			"All locations",
			{
				head: "EMEA (28)",
				values: [
					"United Kingdom (22)",
					"France (3)",
					"Netherlands (2)",
					"Germany (1)",
				],
			},
			"NA (2)",
			"LATAM (1)",
			"APAC (4)",
		],
	},
	{
		column: "Additional Criteria",
		values: ["All Criteria", "MB", "MB -1", "MB -2", "NED Experience"],
	},
];
