import {CLEAR_FORM, SET_ASSIGNMENTS, SET_FORM_DATA} from "../reportActionTypes";

const initialState = {
	assignments: [],
	form: {
		assignment: null,
		external_shares: [],
		internal_shares: [],
		fit_to_briefs: [],
		settings: {
			show_candidate_avatars: true,
			show_candidate_fit_to_briefs: true,
			show_gender_stats: true,
			show_linkedin_urls: true,
			show_only_for_discussion: true,
			show_remuneration: true,
		},
		briefUpdated: false,
	},
};

const projectFormReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ASSIGNMENTS:
			return {...state, assignments: action.payload};
		case SET_FORM_DATA:
			return {...state, form: {...state.form, ...action.payload}};
		case CLEAR_FORM:
			return {assignments: state.assignments, ...initialState};
		default:
			return state;
	}
};

export default projectFormReducer;
