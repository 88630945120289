import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import projectApi from "api/report/project";
import Loader from "components/Plugins/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import {clearAssessmentData, clearSummaryData} from "store/project/projectActions";
import {clearFormData} from "store/projectForm/projectFormActions";
import {CompanyLogo} from "rs-components/CompanyLogo/CompanyLogo";
import SearchBox from "rs-components/Filters/SearchBox";
import {ProjectRow} from "rs-components/ProjectRow/ProjectRow";
import useWindowSize from "hooks/useWindowSize";

const ReportHome = () => {
	const dispatch = useDispatch();
	const researchMode = useSelector((state) => state.user.researchModeEnabled);
	const [assignments, setAssignments] = useState([]);
	const [page, setPage] = useState(2);
	const [loading, setLoading] = useState(false);
	const [searchInputValue, setSearchInputValue] = useState("");

	const onSearchInputChange = (ev) => {
		setSearchInputValue(ev.target.value);
	};

	const hasMore = false;

	const sortData = useCallback(
		(data) => {
			let sortedData = [];
			let inactiveProjects = [];
			for (const project of data) {
				if (project.is_pinned && researchMode) {
					sortedData = [project, ...sortedData];
				} else if (project.status !== "Active") {
					inactiveProjects.push(project);
				} else {
					sortedData = [...sortedData, project];
				}
			}

			return sortedData.concat(inactiveProjects);
		},
		[researchMode],
	);

	const setProjects = useCallback(
		async (query = null) => {
			setLoading(true);

			await projectApi
				.getProjects(researchMode ? "researcher" : "client", query)
				.then((response) => {
					if (response.status === 200) {
						const arr = sortData(response.data.results);

						setAssignments(arr);
					}
				})
				.catch((e) => {
					console.log(e);
				});

			setLoading(false);
		},
		[researchMode, sortData],
	);

	const onGetMoreData = async () => {
		if (!hasMore && !loading) return;
		setPage(page + 1);
		setLoading(true);
		const query = `page=${page}`;

		await setProjects(query);
	};

	useEffect(() => {
		dispatch(clearSummaryData());
		dispatch(clearAssessmentData());
		dispatch(clearFormData());

		try {
			setProjects().then((r) => r);
		} catch (e) {
			console.log(e);
		}
	}, [setProjects, dispatch, researchMode]);

	const {width} = useWindowSize();

	const shouldBreak = width < 550;

	const LoaderComponent = (
		<Loader
			displayAtCenterOfPage={true}
			type={"Rings"}
			height={80}
			width={80}
			color={"#f13a3a"}
		/>
	);

	const InlineLoaderComponent = <Loader height={40} width={40} color={"#f13a3a"} />;

	const onPinHandler = (id, is_pinned, assignments) => {
		projectApi.pinProject(researchMode, id, {
			is_pinned: !is_pinned,
		});

		const data = assignments.map((val) => {
			if (id === val.id) {
				return {
					...val,
					is_pinned: !val.is_pinned,
				};
			}

			return val;
		});

		setAssignments(data);

		setTimeout(() => setAssignments(sortData(data)), 500);
	};

	return (
		<div className="main-content-area assignment-page">
			<div className={"content-wrapper"}>
				<div className="container">
					<div className="page-title">
						<span>Projects</span>

						<div className="report-flex">
							<SearchBox
								placeholder={"Search"}
								onChange={onSearchInputChange}
								value={searchInputValue}
								searchBtn
								// style={{width: shouldBreak ? "100%" : "auto"}}
							/>
							{researchMode && (
								<Link to="/projects/create">
									<button
										className={"btn btn-red"}
										style={{
											marginRight: 0,
											width: shouldBreak ? "100%" : "auto",
											padding: shouldBreak ? "0 15px" : "auto",
										}}
									>
										{shouldBreak ? "+" : "New Project"}
									</button>
								</Link>
							)}
						</div>
					</div>

					{loading && LoaderComponent}

					<div className={["content assignments", "researcher"].join(" ")}>
						<div className="card-wrapper">
							<div className="card header-row header-row-grid">
								<div className={"item company"}>Company </div>
								<div className={"item job-title"}>Role</div>
								<div className={"item start-date"}>
									Last {researchMode ? "Published" : "Updated"}{" "}
								</div>
								<div className={"item status"}>Status </div>
								<div className={["item", "progress-status"].join(" ")}>
									Candidates
								</div>
							</div>

							<div
								className="content-block custom-scrollbar"
								id="results-block"
							>
								<InfiniteScroll
									dataLength={assignments.length}
									initialLoad={false}
									next={onGetMoreData}
									hasMore={hasMore}
									loader={
										loading &&
										page > 2 && (
											<div className="loader" key={0}>
												{InlineLoaderComponent}
											</div>
										)
									}
								>
									{assignments
										.filter((item) =>
											item.company.name
												.toLowerCase()
												.includes(
													searchInputValue.toLowerCase(),
												),
										)
										.map((item, i) => (
											<ProjectRow
												key={i}
												onPinHandler={() =>
													onPinHandler(
														item.id,
														item.is_pinned,
														assignments,
													)
												}
												item={item}
												researchMode={researchMode}
											/>
										))}
								</InfiniteScroll>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReportHome;

export const DisplayCompany = ({item, link, uploadModalAccessible = false}) => {
	return uploadModalAccessible ? (
		<div className="company">
			<CompanyLogo
				src={item.company.logo}
				name={item.company.name}
				id={item.company.id}
				showUploadOnHover={uploadModalAccessible}
				alt=""
			/>
			<Link to={link} className="clickable">
				<span className={"name"}>{item.company.name}</span>
			</Link>
		</div>
	) : (
		<Link to={link} className="clickable">
			<div className="company">
				<CompanyLogo
					src={item.company.logo}
					showUploadOnHover={uploadModalAccessible}
					alt=""
				/>
				<span className={"name"}>{item.company.name}</span>
			</div>
		</Link>
	);
};
