/** @format */

import React from "react";

const TrashIcon = ({height = 20, width = 20, color = "black", ...rest}) => {
	return (
		<svg
			width={height}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill="none"
			{...rest}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.6667 4.58366V3.75033C16.6667 3.52021 16.4801 3.33366 16.25 3.33366H12.5V2.50033C12.5 2.04009 12.1269 1.66699 11.6667 1.66699H8.33334C7.8731 1.66699 7.5 2.04009 7.5 2.50033V3.33366H3.75C3.51988 3.33366 3.33334 3.52021 3.33334 3.75033V4.58366C3.33334 4.81378 3.51988 5.00033 3.75 5.00033H16.25C16.4801 5.00033 16.6667 4.81378 16.6667 4.58366ZM6.55834 18.3337C5.68156 18.3358 4.95304 17.6583 4.89167 16.7837L4.16667 6.66699H15.8333L15.125 16.7837C15.0636 17.6583 14.3351 18.3358 13.4583 18.3337H6.55834Z"
				fill={color}
			/>
		</svg>
	);
};

export default TrashIcon;
