import {combineReducers} from "redux";

import UserReducers from "./user/userReducers";
import RSProjectReducer from "./project/projectReducers";
import RSProjectFormReducer from "./projectForm/projectFormReducers";
import modalReducer from "./modal/modalReducers";

export default combineReducers({
	user: UserReducers,
	project: RSProjectReducer,
	projectForm: RSProjectFormReducer,
	modal: modalReducer,
});
