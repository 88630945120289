import React from "react";

const SearchBox = ({placeholder = null, onSearch, searchBtn = true, ...rest}) => {
	return (
		<div className="search-filter filter-item">
			<input placeholder={placeholder} className="filter-input" {...rest} />
			{searchBtn && (
				<svg
					className="cursor"
					width="40"
					height="40"
					viewBox="0 0 40 40"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					onClick={onSearch}
				>
					<rect x="0.00012207" width="40" height="40" fill="#702C2E" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M24.0626 22.8795L27.396 26.2169C27.5448 26.3824 27.5375 26.6359 27.3793 26.7926L26.796 27.3766C26.7177 27.4556 26.6112 27.5 26.5001 27.5C26.389 27.5 26.2825 27.4556 26.2043 27.3766L22.871 24.0393C22.7788 23.9469 22.6951 23.8463 22.621 23.7389L21.996 22.9046C20.9618 23.7313 19.6777 24.1815 18.3543 24.1811C15.6273 24.1906 13.2576 22.3072 12.6482 19.6459C12.0388 16.9847 13.3523 14.2558 15.811 13.0749C18.2698 11.894 21.2176 12.5763 22.9093 14.7177C24.601 16.8592 24.5851 19.8883 22.871 22.0118L23.7043 22.5875C23.8344 22.6709 23.9546 22.7689 24.0626 22.8795ZM14.1876 18.3407C14.1876 20.6447 16.0531 22.5124 18.3543 22.5124C19.4593 22.5124 20.5192 22.0729 21.3006 21.2905C22.082 20.5082 22.5209 19.4471 22.5209 18.3407C22.5209 16.0367 20.6555 14.169 18.3543 14.169C16.0531 14.169 14.1876 16.0367 14.1876 18.3407Z"
						fill="white"
					/>
				</svg>
			)}
		</div>
	);
};

export default SearchBox;
