import React, {useState} from "react";
import IconPencil from "../../assets/icons/IconPencil";
import "react-day-picker/lib/style.css";
import IconAdd from "../../assets/icons/IconAdd";
import ExperienceModal from "./Modals/ExperienceModal";
import {CompanyLogo} from "rs-components/CompanyLogo/CompanyLogo";

const defaultLogo =
	"https://storage.googleapis.com/nebula-static/logos30x30/empty_logo.png";

const ExperienceSection = ({
	experienceData,
	projectId,
	candidateId,
	researchMode,
	updateRole,
}) => {
	const [displayedExperiences, setDisplayedExperiences] = useState([
		...experienceData,
	]);
	const [modalIsOpen, setIfModalIsOpen] = useState(false);
	const [experienceDataToEdit, setExperienceDataToEdit] = useState(null);

	// Candidate Summary Card Role & Company information sets from here

	const updateExperiences = (data) => {
		setDisplayedExperiences(data);
		updateRole(data);
	};

	return (
		<div className="experiences-section">
			<div className="section-title">
				Experience
				{researchMode && (
					<span
						onClick={() => {
							setExperienceDataToEdit(null);
							setIfModalIsOpen(true);
						}}
						className="cursor"
						style={{marginRight: "5px"}}
					>
						<IconAdd />
					</span>
				)}
			</div>

			{displayedExperiences.map((exp, eI) => (
				<div className="experience" key={eI}>
					<div className="avatar">
						<CompanyLogo
							src={exp?.company?.logo ? exp?.company?.logo : defaultLogo}
							name={exp?.company?.name}
							id={exp?.company?.id}
							alt=""
							showUploadOnHover={researchMode}
						/>
					</div>
					<div className="info">
						<div className="role">
							{exp.company
								? exp.company.custom_name || exp.company.invenias_name
								: "-"}

							{researchMode && (
								<IconPencil
									className="edit-icon cursor"
									color="#702C2E"
									onClick={() => {
										setExperienceDataToEdit(exp);
										setIfModalIsOpen(true);
									}}
								/>
							)}
						</div>
						{exp.positions.map((role, rI) => (
							<div key={rI} className="item">
								<div className="title-with-dot">
									<span className="dot" />
									<span>{role.job_title}</span>
								</div>
								<div className="line-with-label tenure">
									{rI < exp.positions.length - 1 ? (
										<span className="line" />
									) : (
										<span style={{width: "18px"}} />
									)}

									{`${
										role.start_date
											? new Date(role.start_date).getFullYear()
											: ""
									}${
										role.end_date
											? " - " +
											  new Date(role.end_date).getFullYear()
											: ""
									}`}
								</div>
							</div>
						))}
					</div>
				</div>
			))}

			{modalIsOpen && (
				<ExperienceModal
					modalDisplay={modalIsOpen}
					key={"experience-modal"}
					updateExperiences={(data) => updateExperiences(data)}
					setModalDisplay={setIfModalIsOpen}
					experienceData={experienceDataToEdit}
					candidateId={candidateId}
					projectId={projectId}
				/>
			)}
		</div>
	);
};

export default ExperienceSection;
