/** @format */

import React, {useCallback, useState} from "react";
import useModalHook from "../hooks/useModalHook";
import {useDispatch} from "react-redux";
import customConfirmAlert from "../helpers/customConfirmAlert";
import projectApi from "../api/report/project";
import {updateSaveTime} from "../store/user/userActions";
import CloseIconBold from "../assets/icons/IconCloseBold";
import CheckboxWithLabel from "../components/Inputs/CheckboxWithLabel";

export default function PublishChangesModal({
	projectId,
	modalDisplay = true,
	setModalDisplay,
}) {
	const {
		modalIsOpen,
		setIfModalIsOpen,
		customStyles,
		Modal,
		loading,
		setLoading,
		deleting,
		InlineLoaderComponent,
		LoaderComponent,
	} = useModalHook({content: {width: "516px"}}, modalDisplay);
	const [modalTitle, setModalTitle] = useState(
		"Are you sure you want to publish all changes?",
	);
	const [actionDone, setActionDone] = useState(false);
	const [notify, setNotify] = useState(false);
	const [actionMessage, setActionMessage] = useState(
		"The latest update message has already been sent. If you notify the client about new changes they will get the same update message again. Add a new update message if you want to say something new.",
	);

	const dispatch = useDispatch();

	const handleClose = () => {
		setIfModalIsOpen(false);
		setActionDone(false);
		setModalTitle("Are you sure you want to publish all changes?");
		setActionMessage(null);

		setTimeout(() => {
			setModalDisplay(false);
		}, 1000);
	};

	const publishChanges = async () => {
		if (notify) {
			customConfirmAlert({
				yesButtonLabel: "Yes",
				noButtonLabel: "No - go back",
				title: "Please Confirm",
				message:
					"Notifying the client will send them the most recent update from the summary page. Have you" +
					" checked" +
					" the latest update is new?",
				handlePressYes: async () => await sendRequest(),
				afterClose: () => handleClose(),
			});
		} else {
			await sendRequest();
		}
	};

	const sendRequest = async () => {
		setLoading(true);

		await projectApi
			.publishChanges(projectId, {notify_client: notify})
			.then((response) => {
				if (response.status === 200 || response.status === 204) {
					// 'The latest update message has already been sent. If you notify the client about new changes they will get the same update message again. Add a new update message if you want to say something new.'
					setModalTitle("Success!");
					setActionMessage("Published changes are live for clients!");
				} else {
					setModalTitle("Something went wrong!");
					setActionMessage(
						"We are investigating the issue, please try again later!",
					);
				}

				setActionDone(true);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setLoading(false);
				updateTime();
			});
	};

	const updateTime = useCallback(
		() => dispatch(updateSaveTime(Date.now())),
		[dispatch],
	);

	return (
		<div>
			<Modal
				isOpen={modalIsOpen}
				style={customStyles}
				contentLabel="Publish Changes Modal"
			>
				{deleting && LoaderComponent}

				<div className="reports-modal" style={{width: "auto"}}>
					<div className="modal-title" style={{marginBottom: 24}}>
						<span className="title">{modalTitle}</span>
						<CloseIconBold
							className="close-icon cursor"
							onClick={handleClose}
						/>
					</div>

					<div className="content input-wrapper">
						{actionDone ? (
							<span className="action-message">{actionMessage}</span>
						) : (
							<CheckboxWithLabel
								label="Notify Client"
								checked={notify}
								onChange={() => setNotify(!notify)}
							/>
						)}

						<div className="buttons">
							{actionDone ? (
								<button
									onClick={handleClose}
									className="btn btn-red"
									style={{
										paddingLeft: 30,
										paddingRight: 30,
									}}
								>
									OK
								</button>
							) : (
								<>
									<button
										className="btn btn-white"
										onClick={handleClose}
										disabled={loading}
									>
										{"No I'm not done yet"}
									</button>

									<button
										className={[
											"btn",
											loading
												? "btn-white btn-disabled"
												: "btn-red",
										].join(" ")}
										disabled={loading}
										onClick={publishChanges}
									>
										{loading ? (
											<>Publishing {InlineLoaderComponent}</>
										) : (
											"Yes publish"
										)}
									</button>
								</>
							)}
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}
