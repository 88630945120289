import React from "react";

const IconPlay = (props) => (
	<svg
		width={16}
		height={16}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 16 16"
		{...props}
	>
		<mask
			id="play_svg__a"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={16}
			height={16}
		>
			<path fill="#D9D9D9" d="M0 0h16v16H0z" />
		</mask>
		<g mask="url(#play_svg__a)">
			<path
				d="M5.333 12.667V3.333L12.668 8l-7.333 4.667Zm1.334-2.434L10.167 8l-3.5-2.233v4.466Z"
				fill="#999"
			/>
		</g>
	</svg>
);

export default IconPlay;
