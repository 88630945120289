import React from "react";
import classNames from "classnames";
import css from "../TextInput/index.module.scss";
import IconError from "../../../assets/icons/IconError16";
import {TextFieldMessage} from "components";

const TextAreaInput = ({label, errorText, customStyle = {}, ...restProps}) => {
	return (
		<div
			className={classNames(
				"textarea-wrapper",
				{[css.invalid]: errorText},
				errorText !== "" && errorText !== undefined
					? "invalid-input"
					: "valid-input",
			)}
		>
			<label className={css.label}>{label}</label>
			<div className={css.inputWrapper}>
				<textarea
					{...restProps}
					className={[css.input, "text-area-custom"].join(" ")}
					style={{...{resize: "none"}, ...customStyle}}
				/>
				<span className={css.iconError}>
					<IconError />
				</span>
			</div>

			<TextFieldMessage errorText={errorText} />
		</div>
	);
};

export default TextAreaInput;
