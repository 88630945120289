/** @format */

import React, {useEffect, useState} from "react";
import SearchBox from "../../rs-components/Filters/SearchBox";
import {Link} from "react-router-dom";
import IconCaretDown from "../../assets/icons/IconCaretDown";
import IconPencil from "../../assets/icons/IconPencil";
import IconTrash from "../../assets/icons/IconTrash";
import userManagement from "../../api/report/userManagement";
import Loader from "../../components/Plugins/Loader";
import {setEditUserInfo} from "store/user/userActions";
import {useDispatch} from "react-redux";

const UserManagement = (props) => {
	const dispatch = useDispatch();
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);

	const getUserData = async () => {
		setLoading(true);

		await userManagement
			.getUsers()
			.then((response) => {
				if (response.data) {
					setUsers(response.data.results);
				}
			})
			.catch((e) => console.log(e));

		setLoading(false);
	};

	const editUser = async (user) => {
		await dispatch(setEditUserInfo(user));
		props.history.push(`/users/${user.user_id}/edit`);
	};

	const deleteUser = async (user_id) => {
		if (window.confirm("Are you sure?")) {
			setLoading(true);

			await userManagement.deleteUser({user_id}).then(() => {
				setUsers((users) => users.filter((user) => user.user_id !== user_id));
			});

			setLoading(false);
		}
	};

	useEffect(() => {
		getUserData().then(() => dispatch(setEditUserInfo(null)));
	}, [dispatch]);

	const LoaderComponent = (
		<Loader
			displayAtCenterOfPage={true}
			type={"Rings"}
			height={80}
			width={80}
			color={"#f13a3a"}
		/>
	);

	return (
		<div className={["main-content-area", "user-management-page"].join(" ")}>
			<div className={"content-wrapper"}>
				<div className="container">
					<div className="header-section">
						<div className="page-title">User Management</div>

						<SearchBox placeholder="Search" />

						<Link to="/users/create" className="add-new">
							<button className={"btn btn-red add-new-user-btn"}>
								Create New User
							</button>
						</Link>
					</div>

					<div
						className={["user-management-table custom-scrollbar"].join(" ")}
					>
						{loading && LoaderComponent}

						<div className="card-wrapper">
							<div className="card row header-row">
								<div className={"col first-name"}>
									First Name{" "}
									<span>
										<IconCaretDown />
									</span>
								</div>
								<div className={"col last-name"}>
									Last Name
									<span>
										<IconCaretDown />
									</span>
								</div>
								<div className={"col company"}>
									Company{" "}
									<span>
										<IconCaretDown />
									</span>
								</div>
								<div className={"col role"}>
									Role{" "}
									<span>
										<IconCaretDown />
									</span>
								</div>
							</div>

							<div className="content-block custom-scrollbar">
								{users.map((item, i) => (
									<div className="card row content-row" key={i}>
										<div className={"col first-name"}>
											{item.first_name}
										</div>
										<div className={"col last-name"}>
											{item.last_name}
										</div>
										<div className={"col company"}>
											{item.company}
										</div>
										<div className={"col role"}>
											<span>{item.role}</span>

											<span className="icon-group">
												<IconPencil
													height={16}
													width={16}
													onClick={() => editUser(item)}
												/>

												<IconTrash
													height={20}
													width={20}
													onClick={() =>
														deleteUser(item.user_id)
													}
												/>
											</span>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserManagement;
