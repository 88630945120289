/** @format */

import React from "react";

const CaretDownIcon = ({width = 20, height = 20, color = "#141414", ...rest}) => {
	return (
		<svg
			width={width}
			height={height}
			{...rest}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.55001 8.88338C6.47113 8.80514 6.42676 8.69864 6.42676 8.58754C6.42676 8.47644 6.47113 8.36995 6.55001 8.29171L6.71667 8.12504C6.79507 8.04366 6.90369 7.9984 7.01667 8.00004H12.9833C13.0963 7.9984 13.2049 8.04366 13.2833 8.12504L13.45 8.29171C13.5289 8.36995 13.5733 8.47644 13.5733 8.58754C13.5733 8.69864 13.5289 8.80514 13.45 8.88338L10.2917 12.0417C10.2161 12.1223 10.1105 12.168 10 12.168C9.88952 12.168 9.78395 12.1223 9.70834 12.0417L6.55001 8.88338Z"
				fill={color}
			/>
		</svg>
	);
};

export default CaretDownIcon;
