import React from "react";
import css from "./index.module.scss";
import {Link, useHistory} from "react-router-dom";
import ArrowLeft from "assets/icons/ArrowLeft";

function ErrorPage({icon, title, description, link, linkText}) {
	const history = useHistory();

	return (
		<section className={css.section}>
			<div className={css.left}>{icon}</div>
			<div className={css.right}>
				<h1 className={css.title}>{title}</h1>
				<p className={css.description}>{description}</p>
				{linkText && (
					<>
						{link ? (
							<Link to={link} className={css.link} replace>
								<ArrowLeft />
								{linkText}
							</Link>
						) : (
							<Link
								onClick={() => history.goBack()}
								className={css.link}
								replace
							>
								<ArrowLeft />
								{linkText}
							</Link>
						)}
					</>
				)}
			</div>
		</section>
	);
}

export default ErrorPage;
