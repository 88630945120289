/** @format */

import axios from "axios";
import config from "config";

let accessToken = null;

class HttpClient {
	constructor() {
		this.alreadyRefreshOnProcess = false;
		this.client = axios.create({
			baseURL: config.apiUrl,
			// withCredentials: true,
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		this.client.interceptors.request.use((config) => {
			if (accessToken) {
				config.headers.common["Authorization"] = accessToken;
			}

			return config;
		});

		const savedToken = window.localStorage.getItem("token");

		this.client.interceptors.request.use((config) => {
			config.headers.common["Authorization"] = savedToken;

			return config;
		});

		// Handling automatic logout
		this.client.interceptors.response.use(
			(response) => response,
			async (error) => {
				switch (error.response?.status) {
					case 401:
						await this.refreshToken().then((r) => r);
						break;
					case 500:
						// TODO: send notification to slack.
						window.location = "/500";
						break;
					case 503:
						// TODO: send notification to slack.
						window.location = "/503";
						break;
					default:
				}

				return error.response;
			},
		);
	}

	static resetConstructor() {
		accessToken = window.localStorage.getItem("token");

		return new HttpClient();
	}

	redirectToLogin(redirectToPrev = false) {
		let redirect = "/login";

		if (redirectToPrev) {
			const history = window.location.pathname;
			const redirectTo = `?redirectTo=${history}`;
			redirect += `${redirectTo || ""}`;
		}

		window.localStorage.clear();
		window.location = redirect;
	}

	async refreshToken() {
		if (this.alreadyRefreshOnProcess) return;

		if (window.localStorage.getItem("token")) {
			const expiredTime = localStorage.getItem("expires_in")
				? parseInt(localStorage.getItem("expires_in"), 10)
				: null;

			if (expiredTime && expiredTime <= new Date().getTime()) {
				this.alreadyRefreshOnProcess = true;

				await this.doPost("/auth/refresh", {
					refresh_token: window.localStorage.getItem("refresh_token"),
				}).then(async (response) => {
					if (response.status === 200) {
						window.localStorage.setItem(
							"token",
							"Bearer " + response.data.access_token,
						);
						window.localStorage.setItem(
							"expires_in",
							new Date().getTime() + 1000 * response.data.expires_in,
						);
						await HttpClient.resetConstructor();
					} else {
						this.redirectToLogin(true);
					}
				});
			}
		} else {
			this.alreadyRefreshOnProcess = false;
		}
	}

	async doGet(url, config) {
		return await this.client
			.get(url, config)
			.then((res) => res)
			.catch((err) => err.response);
	}

	async doPost(url, body, config, hasMultimedia = false) {
		let formData;

		if (hasMultimedia) {
			config = {
				...config,
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
			formData = new FormData();

			for (const [key, value] of Object.entries(body)) {
				formData.append(key, value);
			}
		} else {
			formData = body;
		}

		return await this.client
			.post(url, formData, config)
			.then((res) => res)
			.catch((err) => err.response);
	}

	async doPatch(url, body, config) {
		return await this.client
			.patch(url, body, config)
			.then((res) => res)
			.catch((err) => err);
	}

	async doPut(url, body, config) {
		return await this.client
			.put(url, body, config)
			.then((res) => res)
			.catch((err) => err.response);
	}

	async doDelete(url, body = {}) {
		return await this.client
			.delete(url, {data: body})
			.then((res) => res.data)
			.catch((err) => err.response);
	}
}

export default HttpClient;
