import React from "react";
import CalendarIcon from "assets/icons/CalendarIcon";

const InterviewSchedule = ({classNames, ...props}) => {
	return (
		<div className={["interview-schedule filter-item", ...classNames].join(" ")}>
			<button className="btn btn-white" {...props}>
				<CalendarIcon /> Interview Schedule
			</button>
		</div>
	);
};

export default InterviewSchedule;
