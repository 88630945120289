import React, {useEffect, useState} from "react";
import css from "./index.module.scss";

const Switch = ({name, value, onChange, customClass = null, ...rest}) => {
	const [selected, setSelected] = useState(value);

	useEffect(() => {
		setSelected(value);
	}, [value]);
	const toggleSelected = () => {
		setSelected(!selected);
		onChange(!selected);
	};

	return (
		<label
			className={[css.switch, customClass ? customClass : ""].join(" ")}
			{...rest}
		>
			<input
				type="checkbox"
				id={name}
				className={css.input}
				name={name}
				checked={selected}
				onChange={toggleSelected}
			/>
			<span className={[css.switchBox, "switchBox"].join(" ")} />
		</label>
	);
};

export default Switch;
