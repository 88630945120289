/** @format */

import HttpClient from "../api";

class UserManagementApi extends HttpClient {
	async saveUser(formData, config = {}) {
		return (await formData.user_id)
			? this.doPatch("/admin/user", formData, config)
			: this.doPost("/admin/user", formData, config);
	}

	async getUsers(config = {}) {
		return await this.doGet("/admin/user", config);
	}

	async deleteUser(formData, config = {}) {
		return await this.doDelete("/admin/user", formData, config);
	}

	async updateProfile(formData, config = {}) {
		return await this.doPatch("/user/profile", formData, config);
	}

	async deletePicture() {
		return await this.doDelete("/user/avatar");
	}
}

const userManagementApi = new UserManagementApi();

export default userManagementApi;
