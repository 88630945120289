/** @format */

import React, {useCallback, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {format} from "date-fns";
import CloseIconBold from "../../../assets/icons/IconCloseBold";
import IconTrash from "../../../assets/icons/IconTrash";
import TextAreaInput from "../../../components/Inputs/TextAreaInput";
import {autoGrowTextArea} from "../../../helpers/general";
import projectApi from "../../../api/report/project";
import {setSummaryData} from "../../../store/project/projectActions";
import useModalHook from "../../../hooks/useModalHook";

const initialUpdateData = {
	id: null,
	date_created: format(new Date(), "yyyy-MM-dd"),
	message: null,
	user: null,
};

const LatestUpdateModal = ({
	data,
	projectId,
	setLatestUpdates,
	modalDisplay = true,
	setModalDisplay,
}) => {
	const summaryData = useSelector((state) => state.project.summaryData);

	const {
		modalIsOpen,
		setIfModalIsOpen,
		customStyles,
		Modal,
		loading,
		setLoading,
		deleting,
		setDeleting,
		InlineLoaderComponent,
		LoaderComponent,
	} = useModalHook({content: {width: "528px"}}, modalDisplay);
	const [update, setUpdate] = useState({...initialUpdateData});
	const [message, setMessage] = useState(null);

	const dispatch = useDispatch();

	const handleClose = () => {
		setUpdate({...initialUpdateData});
		setIfModalIsOpen(false);

		setTimeout(() => {
			setModalDisplay(false);
		}, 1000);
	};

	const handleEdit = useCallback(() => {
		setUpdate({...data});
		setMessage(data.message);
	}, [data]);

	useEffect(() => {
		if (data) {
			handleEdit();
		}
	}, [data, handleEdit]);

	const sendRequest = async (data, isDelete = false) => {
		await projectApi
			.saveProjectInfo(projectId, {latest_update: data})
			.then((response) => {
				if (response.status >= 200 && response.status <= 204) {
					setIfModalIsOpen(false);
					toast.success(`${isDelete ? "Deleted" : "Saved"} Successfully`);
					setLatestUpdates(response.data.latest_updates);
					dispatch(
						setSummaryData({
							...summaryData,
							latestUpdates: response.data.latest_updates,
						}),
					);

					setTimeout(() => {
						setModalDisplay(false);
					}, 1000);
				} else {
					toast.error("Something went wrong!");
				}
			})
			.catch((error) => console.log(error));
	};

	const save = async () => {
		setLoading(true);
		await sendRequest({...update, message});
		setLoading(false);
	};

	const handleDelete = async (update) => {
		if (window.confirm("Are you sure?")) {
			setDeleting(true);
			await sendRequest({...update, is_deleted: true}, true);
			setDeleting(true);
		}
	};

	return (
		<div>
			<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Modal">
				{deleting && LoaderComponent}

				<div className="reports-modal" style={{width: "auto"}}>
					<div className="modal-title">
						<span className="title">Update</span>
						<CloseIconBold
							className="close-icon cursor"
							onClick={handleClose}
						/>
					</div>

					<div className="content input-wrapper">
						<label
							htmlFor=""
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							Text*
							{update.id !== null && (
								<IconTrash
									className="cursor"
									onClick={() => handleDelete(update)}
								/>
							)}
						</label>

						<TextAreaInput
							customStyle={{maxHeight: "350px", height: "auto"}}
							rows={4}
							errorText={
								message !== null && message.length === 0
									? "Please Enter Message"
									: ""
							}
							onChange={(e) => setMessage(e.target.value)}
							onKeyDown={autoGrowTextArea}
							onKeyUp={autoGrowTextArea}
							defaultValue={message}
						/>

						<div className="buttons">
							<button className="btn btn-white" onClick={handleClose}>
								Cancel
							</button>

							<button
								className={[
									"btn",
									loading || message?.length < 1
										? "btn-white btn-disabled"
										: "btn-red",
								].join(" ")}
								disabled={loading || message?.length < 1}
								onClick={save}
							>
								{loading ? <>Saving {InlineLoaderComponent}</> : "Save"}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default LatestUpdateModal;
