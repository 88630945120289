import React, {useState} from "react";
import SkillLevelWithSquare from "rs-components/SkillLevelWithSquare";
import IconPencil from "assets/icons/IconPencil";
import FitToBriefModal from "./Modals/FitToBriefModal";
import {useSelector} from "react-redux";

export const levels = ["Low", "Average", "Modest", "Strong", "Outstanding"];

const BriefsSection = ({briefs, researchMode = false, projectId, candidateId}) => {
	const [modalIsOpen, setIfModalIsOpen] = useState(false);
	const [displayedBriefs, setDisplayedBriefs] = useState(briefs);

	const fitToBriefs = useSelector((state) => state.project.summaryData?.fitToBriefs);

	return (
		<div className="fit-to-brief-section">
			<div className="section-title">
				Fit to brief
				{researchMode && (
					<IconPencil
						className="edit-icon cursor"
						style={{marginRight: 0}}
						color="#702C2E"
						onClick={() => setIfModalIsOpen(true)}
					/>
				)}
			</div>

			<div className="briefs-wrapper">
				{briefs?.length
					? displayedBriefs.map((brief, briefIndex) => {
							return (
								<div className="brief-item" key={briefIndex}>
									<div className="info-area">
										<div className="title">
											<span>{brief.title}</span>

											<SkillLevelWithSquare
												label={levels[brief.score - 1]}
												tooltipText={
													fitToBriefs?.length
														? fitToBriefs[briefIndex]?.value
														: ""
												}
												length={5}
												maxLevel={brief.score}
												labelPlace={"bottom"}
											/>
										</div>

										<div className="notes">
											{brief.comment?.length ? (
												brief.comment
													?.split("\n")
													.filter((item) => item !== "")
													.map((description, noteIndex) => (
														<div
															className="item"
															key={noteIndex}
														>
															<div className="dot" />
															<div className="note">
																{description}
															</div>
														</div>
													))
											) : (
												<div className="item">-</div>
											)}
										</div>
									</div>
								</div>
							);
					  })
					: "-"}
			</div>

			{modalIsOpen && (
				<FitToBriefModal
					modalDisplay={modalIsOpen}
					updateBriefs={setDisplayedBriefs}
					setModalDisplay={setIfModalIsOpen}
					briefsData={briefs}
					candidateId={candidateId}
					projectId={projectId}
				/>
			)}
		</div>
	);
};

export default BriefsSection;
