import React, {useMemo, useState} from "react";
import classNames from "classnames";
import IconError from "assets/icons/IconError16";
import css from "./index.module.scss";
import green from "assets/images/green_circle.svg";
import check_circle from "assets/images/check_circle.png";

const TextInput = ({
	label,
	errorText,
	customClass,
	passwordValidations,
	icon,
	...restProps
}) => {
	const [hasFocus, setHasFocus] = useState(null);
	const hasError = useMemo(
		() => passwordValidations?.some((rule) => !rule.isValid),
		[passwordValidations],
	);

	return (
		<>
			<div
				className={classNames(
					css.inputContainer,
					{[css.invalid]: errorText, [customClass]: customClass},
					"valid-input",
				)}
			>
				{label && <label className={css.label}>{label}</label>}

				<div className={css.inputWrapper}>
					<input
						{...restProps}
						className={classNames(css.input, {
							[css.invalidInput]: hasError && hasFocus === false,
						})}
						onFocus={() => setHasFocus(true)}
						onBlur={() => setHasFocus(false)}
					/>
					<span className={css.icon}>{icon}</span>
					<span className={[css.iconError, "error-icon"].join(" ")}>
						<IconError />
					</span>
				</div>
				{!errorText ? null : (
					<span
						className={css.errorTextSpan}
						style={{opacity: errorText ? 1 : 0}}
					>
						{errorText}&nbsp;
					</span>
				)}
				{passwordValidations && hasFocus ? (
					<div className="password-hints">
						<span>Password Requirements</span>
						{passwordValidations.map((item, i) => (
							<div
								className={[
									"hint",
									item.isValid ? "valid" : "invalid",
								].join(" ")}
								key={i}
							>
								<img src={item.isValid ? green : check_circle} />{" "}
								{item.label}
							</div>
						))}
					</div>
				) : null}
			</div>
		</>
	);
};

export default TextInput;
