/** @format */

import React, {useCallback, useEffect, useMemo, useState} from "react";
import useModalHook from "../../../hooks/useModalHook";
import projectApi from "../../../api/report/project";
import {toast} from "react-toastify";
import CloseIconBold from "../../../assets/icons/IconCloseBold";
import SelectComponent from "../../../components/Inputs/Select";
import TextInput from "../../../components/Inputs/TextInput";
import CalendarIcon from "../../../assets/icons/CalendarIcon";
import {convertFriendlyDateToBeDate} from "../../../helpers/string";
import IconTrash from "../../../assets/icons/IconTrash";
import useDatePeriodValidation from "../../../hooks/useDatePeriodValidation";
import useSearchDebounce from "../../../hooks/useSearchDebounce";

const errorTexts = {
	instituteErrorText: "Please select a establishment",
};

const initialValidation = {
	establishment: {valid: undefined},
	start_date: {valid: undefined, valid_date_range: undefined},
	end_date: {valid: undefined, valid_date_range: undefined},
};

const initialEducationData = {
	establishment: {
		name: null,
		id: null,
	},
	qualification: null,
	start_date: null,
	end_date: null,
	invenias_id: null,
};

const EducationModal = ({
	modalDisplay = false,
	setModalDisplay,
	educationData = null,
	updateEducations,
	projectId,
	candidateId,
}) => {
	const {
		modalIsOpen,
		setIfModalIsOpen,
		customStyles,
		Modal,
		loading,
		setLoading,
		InlineLoaderComponent,
	} = useModalHook({content: {width: "528px"}}, modalDisplay);
	const {datePeriodValidateByKey, dateErrorTextByKey, didDateRangeValidationFailed} =
		useDatePeriodValidation();

	const [institutes, setInstitutes] = useState([]);
	const [institute, setInstitute] = useSearchDebounce(600);
	const [instituteLoading, setIsInstituteLoading] = useState(false);
	const [isSaveDisabled, setIfSaveDisabled] = useState(true);
	const [formDataValidation, setFormDataValidation] = useState({
		...initialValidation,
	});
	const [formData, setFormData] = useState({...initialEducationData});

	useEffect(() => {
		if (educationData) {
			setFormData({...educationData});
		}
	}, [educationData]);

	const handleInputChange = (value, key) => {
		let updatedData = {...formData};
		let validationData = {...formDataValidation};

		if (value === null || value === "") {
			value = null;

			if (key === "end_date") {
				validationData[key] = {...initialValidation[key]};
			}
		} else if (value) {
			if (key === "start_date" || key === "end_date") {
				const fromDate = key === "start_date" ? value : updatedData.start_date;
				const toDate = key === "end_date" ? value : updatedData.end_date;

				validationData[key] = datePeriodValidateByKey(key, fromDate, toDate);
			}
		}

		updatedData[key] = value;
		setFormDataValidation({...validationData});
		setFormData({...updatedData});
		validationChecker(updatedData);
	};

	const validationChecker = (updatedData) => {
		let isValid = updatedData.establishment?.id !== null;

		// from date can not be null, to date can be null but if entered, needs to valid and after from date
		const {start_date, end_date} = updatedData;
		const isFailed = didDateRangeValidationFailed({start_date, end_date});

		if (isFailed) {
			isValid = false;
		}

		setIfSaveDisabled(!isValid);
	};

	const handleClose = () => {
		setModalDisplay(false);
		setIfModalIsOpen(false);
		setFormData({...educationData});
	};

	const save = async () => {
		setLoading(true);

		const formBody = {
			education: {
				...formData,
				establishment: {
					id: formData.establishment.value,
					name: formData.establishment.label,
				},
				start_date: convertFriendlyDateToBeDate(formData.start_date) || null,
				end_date: convertFriendlyDateToBeDate(formData.end_date) || null,
			},
		};

		await sendRequest(formBody);
	};

	const sendRequest = async (formBody, isDelete = false) => {
		await projectApi
			.saveCandidateInfo(projectId, candidateId, formBody)
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					setIfModalIsOpen(false);
					if (isDelete) {
						toast.success("Deleted Successfully");
					} else {
						toast.success("Saved Successfully");
					}

					if (response?.data?.educations) {
						updateEducations(response.data.educations);
					}

					setTimeout(() => {
						setModalDisplay(false);
					}, 1000);
				}
			})
			.catch((error) => console.log(error))
			.finally(() => setLoading(false));
	};

	const handleDelete = async () => {
		if (window.confirm("Are you sure?")) {
			await sendRequest({
				education: {
					invenias_id: formData.invenias_id,
					is_deleted: true,
				},
			});
		}
	};

	const getInstitutes = useCallback(async () => {
		if (institute.length < 3) return;

		setIsInstituteLoading(true);
		await projectApi
			.searchInstitutes(institute)
			.then((response) => {
				const data = response.data.map((item) => {
					return {
						label: item.display_text,
						value: item.id,
					};
				});

				setInstitutes(data);
			})
			.catch((e) => {
				console.log(e);
			});

		setIsInstituteLoading(false);
	}, [institute]);

	useEffect(() => {
		getInstitutes().then((r) => r);
	}, [getInstitutes, institute]);

	const getFromDateErrorText = useMemo(
		() => dateErrorTextByKey(formDataValidation.start_date),
		[formDataValidation.start_date, dateErrorTextByKey],
	);

	const getToDateErrorText = useMemo(
		() => dateErrorTextByKey(formDataValidation.end_date),
		[formDataValidation.end_date, dateErrorTextByKey],
	);

	return (
		<Modal isOpen={modalIsOpen} style={customStyles} contentLabel="Education Modal">
			<div
				className="reports-modal education-modal"
				key={formData?.establishment?.id || "default"}
				style={{width: "auto", height: "auto"}}
			>
				<div className="edit-candidate-form content">
					<div className="modal-title">
						<span className="title">Education</span>
						<CloseIconBold
							className="close-icon cursor"
							onClick={handleClose}
						/>
					</div>

					<div className="col input-wrapper company-column">
						<label htmlFor="" className="establishment-label">
							Establishment Name*
							{formData.invenias_id !== null && (
								<IconTrash className="cursor" onClick={handleDelete} />
							)}
						</label>
						<SelectComponent
							altDropdownIndicator={true}
							borderColor={
								!formDataValidation.establishment.valid &&
								formDataValidation.establishment.valid !== undefined
									? "red"
									: "#dedede"
							}
							errorText={
								!formDataValidation.establishment.valid &&
								formDataValidation.establishment.valid !== undefined
									? errorTexts.instituteErrorText
									: null
							}
							onChange={(establishment, triggerAction) => {
								if (triggerAction.action === "clear") {
									setFormDataValidation({
										...formDataValidation,
										establishment: {valid: false},
									});
									handleInputChange(
										{...initialEducationData.establishment},
										"establishment",
									);
									setIfSaveDisabled(true);
								} else {
									handleInputChange(establishment, "establishment");
									setFormDataValidation({
										...formDataValidation,
										establishment: {valid: true},
									});
								}
							}}
							onInputChange={(query) => setInstitute(query)}
							defaultValue={
								formData?.establishment?.value
									? formData.establishment
									: null
							}
							options={institutes}
							isSearchable={true}
							isClearable={true}
							placeholder="Type to select"
							isLoading={instituteLoading}
						/>
					</div>

					<div className="col input-wrapper company-name">
						<label htmlFor="">Academic Degree</label>

						<TextInput
							customClass="input"
							onChange={(e) =>
								handleInputChange(e.target.value, "qualification")
							}
							placeholder="Academic Degree"
							defaultValue={formData.qualification}
						/>
					</div>

					<div className="col date-row">
						<div className="date input-wrapper">
							<label htmlFor="">From *</label>

							<CalendarIcon className="calender-icon" />

							<TextInput
								customClass="input datepicker-input"
								placeholder="dd.mm.yyyy"
								errorText={getFromDateErrorText}
								onChange={(e) =>
									handleInputChange(e.target.value, "start_date")
								}
								defaultValue={formData.start_date}
							/>
						</div>

						<div className="date input-wrapper">
							<label htmlFor="">To</label>

							<CalendarIcon className="calender-icon" />
							<TextInput
								customClass="input datepicker-input"
								placeholder="dd.mm.yyyy"
								errorText={getToDateErrorText}
								onChange={(e) =>
									handleInputChange(e.target.value, "end_date")
								}
								defaultValue={formData.end_date}
							/>
						</div>
					</div>

					<div className="buttons" style={{marginLeft: "300px"}}>
						<button className="btn btn-white" onClick={handleClose}>
							Cancel
						</button>

						<button
							className={[
								"btn",
								loading || isSaveDisabled
									? "btn-white btn-disabled"
									: "btn-red",
							].join(" ")}
							disabled={loading || isSaveDisabled}
							onClick={save}
						>
							{loading ? (
								<>Saving {InlineLoaderComponent}</>
							) : (
								"Save Changes"
							)}
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default EducationModal;
