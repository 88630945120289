import React, {useState} from "react";
import ReactTooltip from "react-tooltip";

const SkillLevelWithSquare = ({
	length,
	minLevel = 1,
	maxLevel,
	labelPlace = "bottom",
	label,
	className,
	tooltipText = null,
	editMode = false,
	onClick,
	id = null,
	...rest
}) => {
	const [array] = useState(new Array(length).fill(0).map((_, i) => i + 1));

	const handleClick = (item) => {
		if (editMode) {
			onClick(id, item);
		}

		return null;
	};

	return (
		<div
			{...rest}
			className={["level", className !== undefined ? className : ""].join(" ")}
		>
			{labelPlace === "top" && <span>{label}</span>}

			{tooltipText && (
				<ReactTooltip
					place="top"
					type="light"
					effect="solid"
					className="custom-tooltip"
				/>
			)}

			<div className="square-wrapper">
				{array.map((item) => (
					<div
						key={item}
						data-tip={tooltipText}
						onClick={() => handleClick(item)}
						className={[
							"square",
							maxLevel >= item && minLevel <= item ? "fill" : "",
							editMode ? "cursor" : "",
						].join(" ")}
					/>
				))}
			</div>

			{labelPlace === "bottom" && <span>{label}</span>}
		</div>
	);
};

export default SkillLevelWithSquare;
