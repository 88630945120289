import {format, formatDistanceToNow, isBefore, parse} from "date-fns";
import isObject from "lodash/isObject";

export const getShortName = (name, lastName) => {
	if (!name) return "";

	if (lastName) {
		let first = name.charAt(0);
		let second = lastName.charAt(0);

		return `${first}${second}`;
	}
	const arName = name.split(" ");
	let first = arName[0].charAt(0);
	let second = arName[1] ? arName[1].charAt(0) : "";

	return `${first}${second}`;
};

export const labelCapitalized = (str) => {
	return str.replace(
		/(^\w|\s\w)(\S*)/g,
		(_, m1, m2) => m1.toUpperCase() + m2.toLowerCase(),
	);
};

export const isImage = (string) => {
	return string.match(/\.(jpeg|jpg|gif|png|svg)$/) != null;
};

export const truncate = (string, stringLength = 20) => {
	if (!string) return "";

	return string.length > stringLength
		? string.substr(0, stringLength) + "..."
		: string;
};

export const convertLocationConcatenatedString = (location) => {
	if (!location) return "-";

	return `${location.city ? location.city : ""}${
		location.country
			? location.city
				? ", " + location.country
				: location.country
			: ""
	}`;
};

export const moneyFormat = (number, currency = "GBP") => {
	return (
		parseInt(number).toLocaleString("en-GB", {minimumFractionDigits: 0}) +
		" " +
		currency
	);
};

export const isDate = (str) => {
	if (str === null || str === undefined || typeof str !== "string") return false;
	const params = str.split(/[.]/); // str.split(/[.\-/]/)
	const yyyy = parseInt(params[2], 10);

	if (yyyy.toString().length !== 4) return false;

	const mm = parseInt(params[1], 10);
	const dd = parseInt(params[0], 10);
	const date = new Date(yyyy, mm - 1, dd, 0, 0, 0, 0);

	return (
		mm === date.getMonth() + 1 &&
		dd === date.getDate() &&
		yyyy === date.getFullYear()
	);
};

export const convertFriendlyDateToBeDate = (date) => {
	if (!isDate(date)) return false;
	date = parse(date, "dd.MM.yyyy", new Date());

	return format(date, "yyyy-MM-dd");
};

export const convertBeDateToFriendlyDate = (date) => {
	if (date === null) return false;
	if (isDate(date)) return date;

	date = parse(date, "yyyy-MM-dd", new Date());

	return format(date, "dd.MM.yyyy");
};

export const isValidDatePeriod = (date, dateToCompare) => {
	if (!isDate(date)) return "invalid_from_date";
	if (!isDate(dateToCompare)) return "invalid_to_date";

	const fromDate = parse(date, "dd.MM.yyyy", new Date());
	const toDate = parse(dateToCompare, "dd.MM.yyyy", new Date());

	return isBefore(fromDate, toDate) ? "valid" : "invalid";
};

export const isMobile = () => {
	try {
		const pattern =
			/Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i;

		return pattern.test(navigator.userAgent);
	} catch (e) {
		console.log("Error in isMobile");

		return false;
	}
};

export const mergeClasses = (...args) => {
	return [...args].join(" ");
};

export const capitalize = (input) => {
	let words = input.split(" ");
	let capitalizedWords = [];
	words.forEach((element) => {
		capitalizedWords.push(
			element[0].toUpperCase() + element.slice(1, element.length),
		);
	});

	return capitalizedWords.join(" ");
};

export const convertTimeStampToDate = (timestamp) => {
	const date = new Date(timestamp);
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	return `${day}/${month}/${year}`;
};

export const convertUnixTimeStampToHumanReadableTime = (
	timestamp,
	addSuffix = false,
) => {
	return formatDistanceToNow(new Date(timestamp), {addSuffix});
};

export const formatRevenueNumber = (num) => {
	num = Math.abs(Number(num));
	const billions = num / 1.0e9;
	const millions = num / 1.0e6;
	const thousands = num / 1.0e3;
	const formattedNumber =
		num >= 1.0e9 && billions >= 100
			? Math.round(billions) + " B"
			: num >= 1.0e9 && billions >= 10
			? billions.toFixed(1) + " B"
			: num >= 1.0e9
			? billions.toFixed(2) + " B"
			: num >= 1.0e6 && millions >= 100
			? Math.round(millions) + " M"
			: num >= 1.0e6 && millions >= 10
			? millions.toFixed(1) + " M"
			: num >= 1.0e6
			? millions.toFixed(2) + " M"
			: num >= 1.0e3 && thousands >= 100
			? Math.round(thousands) + " K"
			: num >= 1.0e3 && thousands >= 10
			? thousands.toFixed(1) + " K"
			: num >= 1.0e3
			? thousands.toFixed(2) + " K"
			: num.toFixed();

	return `${formattedNumber}`;
};

export const formatSizeBand = (size) => {
	if (size >= 10000) {
		return "10,000+";
	} else if (size >= 5000 && size < 10000) {
		return "5,000+";
	} else if (size >= 1000 && size < 5000) {
		return "1,000+";
	} else if (size >= 100 && size < 1000) {
		return "100+";
	} else if (size >= 10 && size < 100) {
		return "10+";
	} else {
		return "0 - 10";
	}
};

export const displayInfoIfAvailableOnObject = (object, attribute, postfix = "") => {
	return isObject(object) &&
		object[attribute] !== undefined &&
		object[attribute] !== null
		? `${object?.[attribute]}${postfix}`
		: " - ";
};

export const getCurrentDate = (dateFormat = "dd.MM.yyyy") => {
	return format(new Date(), dateFormat);
};

/**
 * Truncates "text" from the end of the string. Algorithm looks
 * for a dot and a space (". ") after the "from"th and before
 * the "to"th characters to determine end of the sentence. If
 * dot does not exist between the range then sentence will be
 * truncated after the comma and if comma is not exist as well as
 * then it will be truncated from a space (" ") and dot will
 * be added to the end.
 *
 * @param {string} text 		Text that is going to be truncated
 * @param {number} [from=150] 	Starts looking for a dot "from"th char
 * @param {number} [to=200] 	Starts looking for a dot until "to"th char
 * @param {number} [rate=10] 	Increase "to" and decrease "from" by "rate" after each loop
 */
export const truncateSentenceEnd = (text, from = 150, to = 200, rate = 10) => {
	if (text && text.length > from) {
		let truncIndex = -1;

		let i = 0;
		let isComma = false;
		while (truncIndex === -1) {
			if (from === 50 && to === text.length) {
				return text;
			}

			from = from - rate * i > 50 ? from - rate * i : 50;
			to = to + rate * i < text.length ? to + rate * i : text.length;

			const range = text.slice(from, to);

			// Search for a dot at the end.
			truncIndex = range.indexOf(". ");

			// Start checking for a comma after 3rd iteration.
			if (truncIndex === -1 && i >= 2) {
				truncIndex = range.indexOf(", ");

				if (truncIndex !== -1) {
					isComma = true;
				}
			}

			i++;
		}

		const suffix = isComma ? "..." : ".";

		return `${text.slice(0, from + truncIndex)}${suffix}`;
	}

	return text;
};
