/** @format */

import React from "react";

const LeftIcon = ({color = "#373937", ...rest}) => {
	return (
		<div {...rest}>
			<svg
				width="7"
				height="12"
				viewBox="0 0 7 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1.90323 10.83C1.7937 10.9404 1.6446 11.0026 1.48906 11.0026C1.33353 11.0026 1.18443 10.9404 1.0749 10.83L0.841564 10.5967C0.727629 10.4869 0.66427 10.3349 0.666564 10.1767L0.666564 1.82335C0.66427 1.66517 0.727629 1.5131 0.841564 1.40335L1.0749 1.17002C1.18443 1.05958 1.33353 0.997467 1.48906 0.997467C1.6446 0.997467 1.7937 1.05958 1.90323 1.17002L6.3249 5.59168C6.43769 5.69754 6.50167 5.84533 6.50167 6.00002C6.50167 6.1547 6.43769 6.30249 6.3249 6.40835L1.90323 10.83Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};

export default LeftIcon;
