/** @format */

import React from "react";

const CloseRoundIcon = ({height = 28, width = 28, color = "#702C2E", ...rest}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			{...rest}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.666504 13.9993C0.666504 6.63555 6.63604 0.666016 13.9998 0.666016C17.5361 0.666016 20.9274 2.07077 23.4279 4.57126C25.9284 7.07174 27.3332 10.4631 27.3332 13.9993C27.3332 21.3631 21.3636 27.3327 13.9998 27.3327C6.63604 27.3327 0.666504 21.3631 0.666504 13.9993ZM19 17.9972C19 17.8861 18.9556 17.7796 18.8767 17.7013L15.1754 14L18.8767 10.2987C18.9556 10.2204 19 10.1139 19 10.0028C19 9.89163 18.9556 9.78509 18.8767 9.70683L18.2932 9.12329C18.2149 9.04438 18.1084 9 17.9972 9C17.8861 9 17.7796 9.04438 17.7013 9.12329L14 12.8246L10.2987 9.12329C10.2204 9.04438 10.1139 9 10.0028 9C9.89163 9 9.78509 9.04438 9.70683 9.12329L9.12329 9.70683C9.04438 9.78509 9 9.89163 9 10.0028C9 10.1139 9.04438 10.2204 9.12329 10.2987L12.8246 14L9.12329 17.7013C9.04438 17.7796 9 17.8861 9 17.9972C9 18.1084 9.04438 18.2149 9.12329 18.2932L9.70683 18.8767C9.78509 18.9556 9.89163 19 10.0028 19C10.1139 19 10.2204 18.9556 10.2987 18.8767L14 15.1754L17.7013 18.8767C17.7796 18.9556 17.8861 19 17.9972 19C18.1084 19 18.2149 18.9556 18.2932 18.8767L18.8767 18.2932C18.9556 18.2149 19 18.1084 19 17.9972Z"
				fill={color}
			/>
		</svg>
	);
};

export default CloseRoundIcon;
