/** @format */

import React from "react";
import ReactMarkdown from "react-markdown";

const FitToBriefSection = ({fitToBriefs}) => {
	return (
		<div className="fit-to-brief">
			<div className="section-title">Fit to Brief</div>

			{fitToBriefs?.length
				? fitToBriefs.map((fitToBrief, bI) => (
						<div className="brief-card" key={bI}>
							<div className="title">{fitToBrief.custom_name}</div>
							<div className="description">
								<ReactMarkdown>{fitToBrief.value}</ReactMarkdown>
							</div>
						</div>
				  ))
				: "-"}
		</div>
	);
};

export default FitToBriefSection;
