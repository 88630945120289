import projectApi from "api/report/project";
import {
	setCountries,
	setCurrencies,
	setGenderData,
	setInterviews,
	setNoticePeriods,
	setProgressStatuses,
	setSummaryData,
} from "./projectActions";
import ReportDemoSVG from "assets/images/demos/report.svg";

export const getInterviewsByProjectId = (projectId) => async (dispatch) => {
	let response;

	try {
		response = await projectApi.getInterviewedCandidateByProjectId(projectId);
	} catch (error) {
		response = {error: error.data};
	}

	if (response.status === 200) {
		const results = response.data.interviews;

		dispatch(setInterviews(results));

		return results;
	} else {
		dispatch(setInterviews([]));

		return [];
	}
};

export const getGenderChartDataByProjectId =
	(projectId, researchMode) => async (dispatch) => {
		let response;

		try {
			response = await projectApi.getGenderAggregatesDataProjectId(
				projectId,
				researchMode,
			);
		} catch (error) {
			response = {error: error.data};
		}

		if (response.status === 200) {
			const items = response.data.items;
			let labels = [];
			let values = [];
			const colors = ["#676767", "#373937"];

			for (const item of items) {
				labels.push(item.label);
				values.push(
					Math.round((item.value / response.data.total_candidates) * 100),
				);
			}

			const result = {
				labels,
				values,
				colors,
				fetched: true,
			};

			dispatch(setGenderData(result));

			return result;
		} else {
			dispatch(
				setGenderData({labels: [], values: [], colors: [], fetched: false}),
			);

			return [];
		}
	};

export const getProjectSummaryByProjectId =
	(projectId, researchMode) => async (dispatch) => {
		const response = await projectApi.getSummaryInfoByProjectId(
			projectId,
			researchMode ? "researcher" : "client",
		);

		if (response.status === 200) {
			const data = response.data;
			const summaryData = {
				latestUpdates: data.latest_updates,
				stages: researchMode
					? data.candidate_categories
					: data.candidate_categories?.stages || [],
				totalCandidates: researchMode
					? data.total_candidates
					: data.candidate_categories?.total_candidates || 0,
				fitToBriefs: data.fit_to_briefs,
				marketInsights: data.market_insights,
				insightReport: {
					cover: ReportDemoSVG,
					summary: data.insights_report.summary,
				},
				totalForDiscussedCandidates: data.for_discussion_count,
				assignmentInfo: data.assignment_info,
				settings: data.settings,
				last_published_date: data.last_published_date,
				hasData: true,
				status: true,
				projectId,
			};

			dispatch(setSummaryData(summaryData));
		} else if (response.status === 404) {
			dispatch(setSummaryData({hasData: false, status: false, projectId}));
		}
	};

export const getCurrenciesData = () => async (dispatch) => {
	let response;

	try {
		response = await projectApi.getCurrencies();
	} catch (error) {
		response = {error: error.data};
	}

	if (response.status === 200) {
		const results = response.data;

		dispatch(setCurrencies(results));

		return results;
	} else {
		dispatch(setCurrencies([]));

		return [];
	}
};

export const getProgressStatusesData = () => async (dispatch) => {
	let response;

	try {
		response = await projectApi.getProgressStatuses();
	} catch (error) {
		response = {error: error.data};
	}

	if (response.status === 200) {
		const results = response.data;

		dispatch(setProgressStatuses(results));

		return results;
	} else {
		dispatch(setProgressStatuses([]));

		return [];
	}
};

export const getNoticePeriodsData = () => async (dispatch) => {
	let response;

	try {
		response = await projectApi.getNoticePeriods();
	} catch (error) {
		response = {error: error.data};
	}

	if (response.status === 200) {
		const results = response.data;

		dispatch(setNoticePeriods(results));

		return results;
	} else {
		dispatch(setNoticePeriods([]));

		return [];
	}
};

export const getCountriesData = () => async (dispatch) => {
	let response;

	try {
		response = await projectApi.getCountries();
	} catch (error) {
		response = {error: error.data};
	}

	if (response.status === 200) {
		const results = response.data;

		dispatch(setCountries(results));

		return results;
	} else {
		dispatch(setCountries([]));

		return [];
	}
};
