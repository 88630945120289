/** @format */

import React, {useState} from "react";
import TextAreaInput from "../../../components/Inputs/TextAreaInput";
import SkillLevelWithSquare from "../../../rs-components/SkillLevelWithSquare";
import useModalHook from "../../../hooks/useModalHook";
import CloseIconBold from "../../../assets/icons/IconCloseBold";
import {autoGrowTextArea} from "../../../helpers/general";
import projectApi from "../../../api/report/project";
import {toast} from "react-toastify";
import classNames from "classnames";

const levels = ["Very Little", "Little", "Average", "Strong", "Outstanding"];

const FitToBriefModal = ({
	modalDisplay = false,
	setModalDisplay,
	briefsData = [],
	updateBriefs,
	projectId,
	candidateId,
}) => {
	const {
		modalIsOpen,
		setIfModalIsOpen,
		customStyles,
		Modal,
		loading,
		setLoading,
		InlineLoaderComponent,
	} = useModalHook({content: {width: "995px"}}, modalDisplay);

	const [briefs, setBriefs] = useState(briefsData);
	const [submitDisabled, setIfSubmitDisabled] = useState(true);

	const handleInputChange = (dataIndex, key, value) => {
		let updatedData = [...briefs];

		if (value === null || value === "") {
			value = null;
		}
		updatedData[dataIndex][key] = value;

		let enableSubmit = true;

		for (let i = 0; i < updatedData.length; i++) {
			const {comment, score} = updatedData[i];

			if (comment?.length > 0 && score === null) {
				enableSubmit = false;
				break;
			}
		}

		setIfSubmitDisabled(!enableSubmit);
		setBriefs([...updatedData]);
	};

	const handleClose = () => {
		setModalDisplay(false);
		setIfModalIsOpen(false);
		setBriefs([...briefsData]);
	};

	const save = async () => {
		setLoading(true);

		await projectApi
			.saveCandidateInfo(projectId, candidateId, {
				fit_to_briefs: briefs,
			})
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					setIfModalIsOpen(false);
					toast.success("Saved Successfully");
					setBriefs(response.data.fit_to_briefs);
					updateBriefs(response.data.fit_to_briefs);

					setTimeout(() => {
						setModalDisplay(false);
					}, 1000);
				}
			})
			.catch((error) => console.log(error));

		setLoading(false);
	};

	return (
		<Modal
			isOpen={modalIsOpen}
			style={customStyles}
			contentLabel="Fit to Brief Modal"
		>
			<div
				className="reports-modal fit-to-brief-modal"
				style={{width: "auto", height: "auto"}}
			>
				<div className="edit-candidate-form">
					<div className="modal-title">
						<span className="title">Fit to Brief</span>
						<CloseIconBold
							className="close-icon cursor"
							onClick={handleClose}
						/>
					</div>

					{briefs.map((brief, index) => (
						<div className="row grid" key={index}>
							<div className="col input-wrapper">
								<label htmlFor="">{brief.title}</label>

								<TextAreaInput
									rows={
										brief.comment?.length
											? brief.comment?.split("\n").length
											: 4
									}
									customStyle={{
										maxHeight: "350px",
										height: "auto",
									}}
									onChange={(e) =>
										handleInputChange(
											index,
											"comment",
											e.target.value,
										)
									}
									onKeyDown={autoGrowTextArea}
									onKeyUp={autoGrowTextArea}
									defaultValue={brief.comment}
								/>
							</div>
							<div className="col">
								<SkillLevelWithSquare
									editMode={true}
									key={brief.title + brief.score}
									id={brief.title}
									onClick={(_, value) =>
										handleInputChange(index, "score", value)
									}
									length={5}
									maxLevel={brief.score}
									label={levels[brief.score - 1]}
								/>

								{brief.comment?.length > 0 && brief.score === null && (
									<span
										className={classNames("error-text")}
										style={{
											position: "relative",
											bottom: 0,
										}}
									>
										Please enter score
									</span>
								)}
							</div>
						</div>
					))}

					<div className="buttons" style={{marginLeft: "300px"}}>
						<button className="btn btn-white" onClick={handleClose}>
							Cancel
						</button>

						<button
							className={[
								"btn",
								loading || submitDisabled
									? "btn-white btn-disabled"
									: "btn-red",
							].join(" ")}
							disabled={loading || submitDisabled}
							onClick={save}
						>
							{loading ? (
								<>Saving {InlineLoaderComponent}</>
							) : (
								"Save Changes"
							)}
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default FitToBriefModal;
