import React from "react";
import ForgotName from "containers/Auth/ForgotName";
import ForgotPassword from "./rs-pages/Auth/ForgotPassword";
import NotFound from "containers/NotFound";
import ReportLogin from "./rs-pages/Auth/Login";
import ReportHome from "./rs-pages/Home";
import Summary from "./rs-pages/Summary";
import Assessments from "./rs-pages/Assesments";
import ClientCandidate from "./rs-pages/ClientCandidate";
import ProjectWorkflowForm from "./rs-pages/Project";
import UserManagement from "./rs-pages/UserManagement";
import UserForm from "./rs-pages/UserManagement/Form";
import ResetPassword from "./rs-pages/Auth/ResetPassword";
import ReportRegister from "./rs-pages/Auth/Register";
import PrintCandidateList from "rs-pages/Assesments/PrintCandidateList";
import PrintCandidateProfile from "rs-pages/ClientCandidate/PrintCandidateProfile";
import ServerError from "containers/ServerError";
import ServiceUnavailable from "containers/ServiceUnavailable";
import {Redirect} from "react-router-dom";

const routes = [
	{
		path: "/",
		element: <Redirect to={"/home"} />,
		exact: true,
		private: false,
	},

	{
		path: "/login",
		pathname: "report-login",
		component: ReportLogin,
		exact: true,
	},

	// redundant url
	{
		path: "/report/signup",
		pathname: "report-signup",
		component: ReportRegister,
		exact: true,
	},
	// end

	{
		path: "/signup",
		pathname: "report-signup",
		component: ReportRegister,
		exact: true,
	},

	{
		path: "/forgot-username",
		component: ForgotName,
		exact: true,
	},
	{
		path: "/forgot-password",
		component: ForgotPassword,
		exact: true,
	},
	{
		path: "/reset-password",
		component: ResetPassword,
		exact: true,
	},

	// PRIVATE ROUTES

	{
		path: "/home",
		component: ReportHome,
		exact: true,
		private: true,
	},

	{
		path: "/projects/:projectId/summary",
		component: Summary,
		exact: true,
		private: true,
	},

	// redundant url

	{
		path: "/report/:projectId/summary",
		component: Summary,
		exact: true,
		private: true,
	},
	// end

	{
		path: "/projects/:projectId/assessments",
		component: Assessments,
		exact: true,
		private: true,
	},

	{
		path: "/projects/:projectId/assessments/print",
		component: PrintCandidateList,
		exact: true,
		private: true,
	},

	{
		path: "/projects/:projectId/candidate-interviews",
		component: Assessments,
		exact: true,
		private: true,
	},

	{
		path: "/projects/:projectId/candidate-profile/:candidateId",
		component: ClientCandidate,
		exact: true,
		private: true,
	},

	{
		path: "/projects/:projectId/candidate-profile/:candidateId/print",
		component: PrintCandidateProfile,
		exact: true,
		private: true,
	},

	{
		path: "/projects/create",
		component: ProjectWorkflowForm,
		exact: true,
		private: true,
	},

	{
		path: "/projects/create/project-settings",
		component: ProjectWorkflowForm,
		exact: true,
		private: true,
	},

	{
		path: "/projects/create/fit-to-brief-settings",
		component: ProjectWorkflowForm,
		exact: true,
		private: true,
	},

	{
		path: "/projects/create/additional-criteria-settings",
		component: ProjectWorkflowForm,
		exact: true,
		private: true,
	},

	{
		path: "/projects/:id/edit",
		component: ProjectWorkflowForm,
		exact: true,
		private: true,
	},

	{
		path: "/projects/:id/edit/project-settings",
		component: ProjectWorkflowForm,
		exact: true,
		private: true,
	},

	{
		path: "/projects/:id/edit/fit-to-brief-settings",
		component: ProjectWorkflowForm,
		exact: true,
		private: true,
	},

	{
		path: "/projects/:id/edit/additional-criteria-settings",
		component: ProjectWorkflowForm,
		exact: true,
		private: true,
	},

	{
		path: "/users",
		component: UserManagement,
		exact: true,
		private: true,
	},

	{
		path: "/users/create",
		component: UserForm,
		exact: true,
		private: true,
	},
	{
		path: "/account",
		component: UserForm,
		exact: true,
		private: true,
	},
	{
		path: "/users/:user_id/edit",
		component: UserForm,
		exact: true,
		private: true,
	},
	{
		path: "/500",
		component: ServerError,
	},
	{
		path: "/503",
		component: ServiceUnavailable,
	},
	{
		path: "*",
		component: NotFound,
	},
];

export default routes;
