import React, {useCallback, useEffect, useRef, useState} from "react";
import Breadcrumb from "rs-components/Breadcrumb";
import SummaryCard from "rs-components/Cards/Candidate/SummaryCard";
import {useDispatch, useSelector} from "react-redux";
import projectApi from "api/report/project";
import Loader from "components/Plugins/Loader";
import FilterSection from "./FilterSection";
import BrowseCandidateSection from "./BrowseCandidateSection";
import ExperienceSection from "./ExperienceSection";
import EducationSection from "./EducationSection";
import SummarySection from "./SummarySection";
import BriefsSection from "./BriefsSection";
import AreasToExploreSection from "./AreasToExploreSection";
import RemunerationSection from "./RemunerationSection";
import NoticePeriodSection from "./NoticePeriodSection";
import {
	getCountriesData,
	getCurrenciesData,
	getNoticePeriodsData,
	getProgressStatusesData,
	getProjectSummaryByProjectId,
} from "store/project/projectSelectors";
import PrintCandidateProfile from "rs-pages/ClientCandidate/PrintCandidateProfile";
import {useReactToPrint} from "react-to-print";
import {getCurrentDate} from "helpers/string";
import useWindowSize from "hooks/useWindowSize";
import queryString from "qs";
import {setAssessmentData, setCandidatesData} from "store/project/projectActions";
import ErrorPage from "components/Plugins/ErrorPage";
import NotFoundIcon from "assets/icons/NotFoundIcon";

const ClientCandidate = ({match}) => {
	const search = new URL(window.location).search;
	const filterType = new URLSearchParams(search).get("filter-type");
	const forDiscussion = new URLSearchParams(search).get("for-discussion");
	let position = new URLSearchParams(search).get("position");

	const assessmentData = useSelector((state) => state.project.assessment);
	const candidates = useSelector((state) => state.project.candidates);
	const currencies =
		useSelector((state) =>
			state.project?.currencies?.map((item) => {
				return {label: item.display_text, value: item.id};
			}),
		) || [];
	const progressStatuses =
		useSelector((state) => state.project?.progress_statuses) || [];
	const noticePeriods = useSelector((state) => state.project?.notice_periods) || [];
	const countries = useSelector((state) => state.project?.countries) || [];
	const [breadcrumbOptions, setBreadCrumbOptions] = useState([]);
	const [browseCandidate, setBrowseCandidate] = useState({
		prev: null,
		next: null,
		title: null,
		position: position,
		totalCandidate: 0,
	});
	const {projectId} = match.params;
	const candidateId = match.params.candidateId.split("?")[0];
	const researchMode = useSelector((state) => state.user.researchModeEnabled);
	const [breadcrumbActiveIndex, setBreadcrumbActiveIndex] = useState(1);
	const [candidate, setCandidate] = useState(null);
	const [summary, setSummary] = useState(null);
	const [experiences, setExperiences] = useState([]);
	const [educations, setEducations] = useState([]);
	const [remuneration, setRemuneration] = useState([]);
	const [noticePeriod, setNoticePeriod] = useState(null);
	const [areasToExplore] = useState([]);
	const [briefs, setBriefs] = useState([]);
	const [loading, setLoading] = useState(false);
	const [browseDataLoading, setBrowseDataLoading] = useState(false);
	const dispatch = useDispatch();

	// HIDDEN SECTIONS
	const {summaryData} = useSelector((state) => state.project);
	const [displayLinkedInUrls, setDisplayLinkedInUrls] = useState(true);
	const [displayProfilePics, setDisplayProfilePics] = useState(true);
	const [displayFitToBriefs, setDisplayFitToBriefs] = useState(true);
	const [displayRemuneration, setDisplayRemuneration] = useState(true);

	const getAllCandidates = useCallback(async () => {
		if (candidates.length < assessmentData?.totalCounts?.all_candidates) {
			setBrowseDataLoading(true);
			const params = {
				for_discussion: forDiscussion,
				skip: candidates.length,
				take:
					(assessmentData?.totalCounts?.all_candidates || 1000) -
					candidates.length,
			};

			const queryParam = queryString.stringify(params);

			await projectApi
				.getAssessmentDataByProjectId(projectId, researchMode, queryParam)
				.then((response) => {
					if (response !== undefined && response.status === 200) {
						let data = response.data.results;
						const totalCounts = response.data.totals;

						dispatch(
							setAssessmentData({
								totalCounts,
								queryParam,
								filterType,
							}),
						);

						dispatch(
							setCandidatesData({candidates: data, shouldAddMore: true}),
						);
					}
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => setBrowseDataLoading(false));
		}
	}, [
		assessmentData?.totalCounts?.all_candidates,
		candidates.length,
		dispatch,
		filterType,
		forDiscussion,
		projectId,
		researchMode,
	]);

	useEffect(() => {
		getAllCandidates();
	}, [getAllCandidates]);

	useEffect(() => {
		if (candidate !== null && candidate.first_name !== "" && position) {
			let options = [
				{
					to:
						"/projects/" +
						projectId +
						"/assessments?filter-type=all_candidates",
					name: "All Candidates",
				},
			];
			let candidatesData = [...candidates];

			if (filterType === "savannah_interview") {
				options = [
					...options,
					{
						to:
							"/projects/" +
							projectId +
							"/assessments?filter-type=savannah_interview",
						name: "Savannah Interviewing",
					},
				];
				candidatesData = candidatesData.filter(
					(data) => data.interviewed_by_savannah,
				);
				setBreadcrumbActiveIndex(1); // savannah assessing
			} else if (filterType === "interviewed_by_client") {
				options = [
					...options,
					{
						to:
							"/projects/" +
							projectId +
							"/assessments?filter-type=interviewed_by_client",
						name: "Client Interview",
					},
				];
				candidatesData = candidatesData.filter(
					(data) => data.interviewed_by_client,
				);
				setBreadcrumbActiveIndex(2); // client interview
			}

			if (forDiscussion === "true") {
				options = [
					...options,
					{
						to:
							"/projects/" +
							projectId +
							"/assessments?filter-type=" +
							filterType +
							"&for-discussion=true",
						name: "Highlighted Profiles",
					},
				];
				candidatesData = candidatesData.filter((data) => data.for_discussion);
			}

			options = [
				...options,
				{
					to: "#",
					name: candidate.first_name + " " + candidate.last_name,
				},
			];
			setBreadCrumbOptions(options);

			const browsableCandidates = researchMode
				? [...candidatesData]
				: [...candidatesData].filter(
						(item) => item?.is_clickable || item?.for_discussion,
				  );
			const browseTitle = options.length
				? options[options.length - 1].name
				: null;

			let prev = null;
			let next = null;
			let index = parseInt(position) - 1;
			const candidateProfileStringParameter =
				"?filter-type=" + filterType + "&for-discussion=" + forDiscussion;

			if (index > 0 && browsableCandidates[index - 1] !== undefined) {
				prev = browsableCandidates[index - 1].id;
			}

			if (
				index < browsableCandidates.length - 1 &&
				browsableCandidates[index + 1] !== undefined
			) {
				next = browsableCandidates[index + 1].id;
			}

			const browseData = {
				prev: prev
					? `/projects/${projectId}/candidate-profile/${prev}${candidateProfileStringParameter}&position=${
							parseInt(position) - 1
					  }`
					: null,
				next: next
					? `/projects/${projectId}/candidate-profile/${next}${candidateProfileStringParameter}&position=${
							parseInt(position) + 1
					  }`
					: null,
				title: browseTitle,
				position: position,
				totalCandidate: browsableCandidates.length,
			};

			setBrowseCandidate(browseData);
		}
	}, [
		candidate,
		candidates,
		filterType,
		forDiscussion,
		position,
		projectId,
		researchMode,
	]);

	const getCandidateInfo = useCallback(async () => {
		setLoading(true);

		await projectApi
			.getCandidateInfoById(projectId, candidateId, researchMode)
			.then((response) => {
				if (response.status === 200) {
					let candidateData = response.data;

					candidateData.id = candidateId;
					candidateData.avatar = candidateData.avatar_url;
					candidateData.linked_in = candidateData.linked_in_url;

					let {experiences} = {...candidateData};

					experiences = experiences.filter((exp) => exp.company);

					candidateData = {
						...candidateData,
						...getCandidateDefaultPosition(experiences),
					};

					setExperiences(experiences);

					setCandidate(candidateData);
					setEducations(response.data.educations);
					setRemuneration(response.data.remuneration_packages);
					setNoticePeriod(response.data.notice_period);
					setSummary(response.data.summary);

					setBriefs(
						researchMode
							? response.data.fit_to_briefs
							: response.data.fit_to_briefs.filter(
									(item) =>
										!(item.comment === null && item.score === null),
							  ),
					);
				} else {
					setCandidate(null);
				}
			});

		setLoading(false);
	}, [projectId, candidateId, researchMode]);

	const setDropdownDataOnState = useCallback(async () => {
		if (!currencies.length) {
			await dispatch(getCurrenciesData());
		}

		if (!progressStatuses.length) {
			await dispatch(getProgressStatusesData());
		}

		if (!noticePeriods.length) {
			await dispatch(getNoticePeriodsData());
		}

		if (!countries.length) {
			await dispatch(getCountriesData());
		}
	}, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		async function get() {
			if (!summaryData?.hasData) {
				setLoading(true);
				await dispatch(getProjectSummaryByProjectId(projectId, researchMode));
				setLoading(false);
			}

			await getCandidateInfo()
				.then((r) => r)
				.finally(() => setDropdownDataOnState());
		}

		get().then((r) => r);
	}, [
		dispatch,
		getCandidateInfo,
		projectId,
		setDropdownDataOnState,
		summaryData,
		researchMode,
	]);

	useEffect(() => {
		if (summaryData?.settings) {
			const {
				show_candidate_fit_to_briefs,
				show_candidate_avatars,
				show_linkedin_urls,
				show_remuneration,
			} = summaryData.settings;

			if (!researchMode) {
				// only client can't see fit to brief if hidden
				setDisplayFitToBriefs(show_candidate_fit_to_briefs);
			} else {
				setDisplayFitToBriefs(true);
			}
			setDisplayProfilePics(show_candidate_avatars);
			setDisplayLinkedInUrls(show_linkedin_urls);
			setDisplayRemuneration(show_remuneration);
		}
	}, [summaryData, researchMode]);

	const LoaderComponent = (
		<Loader
			displayAtCenterOfPage={true}
			type={"Rings"}
			height={80}
			width={80}
			color={"#f13a3a"}
		/>
	);

	/**
	 * PRINTING CANDIDATE PROFILE LOGIC
	 */
	const componentRef = useRef();
	const onBeforeGetContentResolve = React.useRef(null);

	const [printPageLoading, setPrintPageLoading] = React.useState(false);
	const [printLabelText, setPrintLabelText] = useState("Print Profile");

	const handleOnBeforeGetContent = React.useCallback(async () => {
		setPrintPageLoading(true);
		setPrintLabelText("Loading print preview ...");

		return new Promise((resolve) => {
			onBeforeGetContentResolve.current = resolve;

			setTimeout(() => {
				setPrintPageLoading(false);
				setPrintLabelText(`Print Profile`);
				resolve();
			}, 2000);
		});
	}, []);

	const handlePrintClick = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: `${candidate?.first_name + " " + candidate?.last_name} - ${
			summaryData?.assignmentInfo?.company?.name
		} - ${summaryData?.assignmentInfo?.assignment_name} - ${getCurrentDate()}`,
		onBeforeGetContent: handleOnBeforeGetContent,
		removeAfterPrint: true,
	});

	const {width} = useWindowSize();

	const isMobile = width <= 425;

	if (!loading && candidate === null) {
		return (
			<ErrorPage
				icon={<NotFoundIcon />}
				title="Candidate Unavailable"
				description={
					"Either candidate doesn't exist or is not being\n" +
					"\t\t\t\t\t\t\t\tpublished, Try later!"
				}
				linkText="Go back"
			/>
		);
	}

	const isDifferentLayoutForClient = () => {
		return (
			!researchMode &&
			(summary === null || summary === "") &&
			(!displayFitToBriefs || briefs?.length === 0)
		);
	};

	return (
		<div className={["main-content-area"].join(" ")}>
			{!isMobile && !loading && !!candidate && (
				<div style={{display: "none"}}>
					<PrintCandidateProfile
						ref={componentRef}
						isResearcher={researchMode}
						candidate={candidate}
						summary={summary}
						experiences={experiences}
						educations={educations}
						remuneration={remuneration}
						noticePeriod={noticePeriod}
						briefs={briefs}
						displayProfilePics={displayProfilePics}
						displayFitToBriefs={displayFitToBriefs}
						displayRemuneration={displayRemuneration}
					/>
				</div>
			)}

			<div
				className={[
					"content-wrapper candidate-profile-page",
					researchMode ? "research-mode" : "",
				].join(" ")}
			>
				<div className="container custom-scrollbar">
					<Breadcrumb
						options={[
							{
								name: "Summary",
								to: `/projects/${projectId}/summary`,
							},
							{
								name: "Savannah Assessing",
								to: `/projects/${projectId}/assessments?filter-type=all_candidates`,
							},
							{
								name: "Interviewing",
								to: `/projects/${projectId}/assessments?filter-type=interviewed_by_client`,
							},
						]}
						activeIndex={breadcrumbActiveIndex}
					/>

					<FilterSection
						breadcrumbOptions={breadcrumbOptions}
						hasCandidate={!!candidate}
						handlePrintClick={handlePrintClick}
						printPageLoading={printPageLoading || loading}
						printLabelText={printLabelText}
						shouldPrint={!isMobile}
					/>

					{!!browseCandidate.totalCandidate && (
						<BrowseCandidateSection
							loading={browseDataLoading}
							browseCandidate={browseCandidate}
						/>
					)}

					{loading && LoaderComponent}

					{!loading && candidate !== null && (
						<div className="candidates-card-wrapper">
							{!!candidate && (
								<SummaryCard
									candidate={candidate}
									projectId={projectId}
									updateCandidateName={(name) =>
										setBrowseCandidate({
											...browseCandidate,
											title: name,
										})
									}
									displayProfilePic={displayProfilePics}
									displayLinkedInUrl={displayLinkedInUrls}
									isDetailsProfile={true}
								/>
							)}

							<div className="details-information">
								<div className="left-column">
									{!isDifferentLayoutForClient() &&
										experiences?.length && (
											<ExperienceSection
												experienceData={experiences}
												key={candidateId}
												researchMode={researchMode}
												projectId={projectId}
												candidateId={candidateId}
												updateRole={(updatedExperiences) => {
													setCandidate({
														...candidate,
														...getCandidateDefaultPosition(
															updatedExperiences,
														),
													});
												}}
											/>
										)}

									<EducationSection
										educations={educations}
										projectId={projectId}
										candidateId={candidateId}
										researcherMode={researchMode}
									/>

									{/* Remuneration Section */}

									<RemunerationSection
										remuneration={remuneration}
										candidateId={candidateId}
										projectId={projectId}
										currencies={currencies}
										isResearcher={researchMode}
									/>

									<div className="notice-section">
										<div className="section-title">
											Notice Period
										</div>

										<div className="info">
											{researchMode &&
											typeof noticePeriod === "object" ? (
												<NoticePeriodSection
													noticePeriod={noticePeriod}
													noticePeriods={noticePeriods}
													candidateId={candidateId}
													projectId={projectId}
												/>
											) : noticePeriod &&
											  typeof noticePeriod === "string" ? (
												noticePeriod
											) : (
												"-"
											)}
										</div>
									</div>
								</div>

								{/*RIGHT COLUMN INFORMATION*/}

								<div className="right-column">
									{isDifferentLayoutForClient() &&
										experiences?.length > 0 && (
											<ExperienceSection
												experienceData={experiences}
												key={candidateId}
												researchMode={researchMode}
												projectId={projectId}
												candidateId={candidateId}
												updateRole={(updatedExperiences) => {
													setCandidate({
														...candidate,
														...getCandidateDefaultPosition(
															updatedExperiences,
														),
													});
												}}
											/>
										)}

									{(researchMode ||
										(summary !== null && summary !== "")) && (
										<SummarySection
											summary={summary}
											researchMode={researchMode}
											projectId={projectId}
											setSummary={setSummary}
											candidateId={candidateId}
										/>
									)}

									{(researchMode ||
										(displayFitToBriefs && !!briefs.length)) && (
										<BriefsSection
											briefs={briefs}
											researchMode={researchMode}
											projectId={projectId}
											candidateId={candidateId}
										/>
									)}

									{/*<PsychometricAssessmentSection />*/}

									{!!areasToExplore.length && (
										<AreasToExploreSection
											areasToExplore={areasToExplore}
										/>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default ClientCandidate;

export const getCandidateDefaultPosition = (experiences) => {
	let defaultPosition = {
		role: "-",
		company: {},
	};
	let defaultFound = false;

	// Finding default positions from experiences data
	for (const experience of experiences) {
		for (let positionI = 0; positionI < experience.positions.length; positionI++) {
			const positions = experience.positions[positionI];

			if (positions.is_default) {
				defaultPosition.role = positions.job_title;
				defaultPosition.company = experience.company;
				defaultFound = true;
				break;
			}
		}

		if (defaultFound) {
			break;
		}
	}

	return defaultPosition;
};
