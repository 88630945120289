import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {updateWebAppTitle} from "rs-components/LayoutPartials/Navbar/reportNavbar";
import AssignmentInfoAsHeader from "rs-components/AssignmentInfoAsHeader";
import classnames from "classnames";
import {convertLocationConcatenatedString, getShortName} from "helpers/string";
import "rs-pages/Assesments/PrintCandidateList/printCandidateList.scss";
import PrintLayout from "rs-components/PrintLayout";

const PrintCandidateList = ({candidatesData}, ref) => {
	// const candidatesData = useSelector((state) => state.project.candidates);
	const summaryData = useSelector((state) => state.project.summaryData);
	const [displayProfilePics, setDisplayProfilePics] = useState(false);

	useEffect(() => {
		if (summaryData?.settings) {
			const settings = summaryData.settings;

			if (settings?.show_candidate_avatars !== undefined) {
				setDisplayProfilePics(settings.show_candidate_avatars);
			} else {
				setDisplayProfilePics(true);
			}
		}
	}, [summaryData?.settings]);

	const updatePageTitle = updateWebAppTitle();

	useEffect(() => {
		updatePageTitle();
	}, [updatePageTitle]);

	const children = (
		<table className="no-spacing">
			<thead>
				<tr>
					<td>
						<div className="page-header-space" />
					</td>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td colSpan={6}>
						<AssignmentInfoAsHeader isPrintPage={true} />
					</td>
				</tr>

				{candidatesData?.map((displayedCandidateData, index) => (
					<tr key={index} className={"print-candidate-card"}>
						<td style={{minWidth: 80, maxWidth: 80}}>
							<div
								className={classnames("avatar", {
									["is-highlighted"]:
										displayedCandidateData?.for_discussion,
								})}
							>
								{displayedCandidateData?.for_discussion && (
									<span className="discussion">Highlighted</span>
								)}
								<div className="avatar-details">
									{candidateAvatar(
										displayedCandidateData,
										displayProfilePics,
									)}
								</div>
							</div>
						</td>
						<td style={{minWidth: 100, maxWidth: 100}}>
							<div className="info name">
								<div className="label">Name</div>
								<div className="value">
									{`${displayedCandidateData.first_name} ${displayedCandidateData.last_name}`.trim()}
								</div>
							</div>
						</td>
						<td style={{minWidth: 120, maxWidth: 120}}>
							<div className="info role">
								<div className="label">Role</div>
								<div className="value">
									{displayedCandidateData.role?.trim()}
								</div>
							</div>
						</td>
						<td style={{minWidth: 120, maxWidth: 120}}>
							<div className="info company">
								<div className="details">
									<div className="label">Company</div>
									<div className="value">
										{companyInfo(
											displayedCandidateData,
										)?.name.trim()}
									</div>
								</div>
							</div>
						</td>
						<td style={{minWidth: 110, maxWidth: 110}}>
							<div className="info location">
								<div className="label">Location</div>
								<div className="value">
									{convertLocationConcatenatedString(
										displayedCandidateData.location,
									)?.trim()}
								</div>
							</div>
						</td>
						<td>
							<div className="info progress-status xs-only">
								<div className="label">Progress Status</div>
								<div className="value">
									{displayedCandidateData.status_info
										? displayedCandidateData.status_info
												.display_text
										: displayedCandidateData.progress_info.status}
								</div>
							</div>
						</td>
					</tr>
				))}
			</tbody>

			<tfoot>
				<tr>
					<td>
						<div className="page-footer-space" />
					</td>
				</tr>
			</tfoot>
		</table>
	);

	const informationBlock = <span>Total Candidates: {candidatesData.length}</span>;

	return (
		<PrintLayout ref={ref} informationBlock={informationBlock}>
			{children}
		</PrintLayout>
	);
};

export default React.forwardRef(PrintCandidateList);

export const candidateAvatar = (displayedCandidateData, displayProfilePics = true) => (
	<>
		{displayedCandidateData.avatar !== null && displayProfilePics && (
			<img
				src={displayedCandidateData.avatar}
				alt={displayedCandidateData.first_name}
			/>
		)}

		{(displayedCandidateData.avatar === null || !displayProfilePics) && (
			<div className="name-as-avatar">
				{getShortName(
					displayedCandidateData.first_name,
					displayedCandidateData.last_name,
				)}
			</div>
		)}
	</>
);

export const companyInfo = (candidate) => {
	let company = candidate.company;

	if (company === null) return null;

	if (company.custom_name || company.invenias_name) {
		company.name = company?.custom_name || company?.invenias_name;
	} else if (company.name === undefined) {
		company.name = "-";
	}

	return company;
};
