/** @format */

import React, {useEffect, useState} from "react";
import CheckboxWithLabel from "../../components/Inputs/CheckboxWithLabel";
import {animated, useSpring} from "@react-spring/web";

const FilterDetails = ({filterOptions, toggle}) => {
	const [activeClass, setActiveClass] = useState("hide");
	const props = useSpring({
		from: {opacity: 0},
		to: {opacity: toggle ? 1 : 0},
		hold: !toggle,
	});

	useEffect(() => {
		if (toggle) {
			setActiveClass("show");
		} else {
			setTimeout(() => {
				setActiveClass("hide");
			}, 150);
		}
	}, [toggle]);

	return (
		<animated.div style={props}>
			<div className={["filters-details-wrapper", activeClass].join(" ")}>
				{filterOptions.map((filterColumn, index) => (
					<div className="column" key={index}>
						<div className="header-label">{filterColumn.column}</div>

						{filterColumn.values.map((item, iI) =>
							typeof item === "string" ? (
								<div className="checkbox-wrapper" key={iI}>
									<CheckboxWithLabel
										label={item}
										classNames={["header-item"]}
									/>
								</div>
							) : (
								<div className="checkbox-wrapper" key={iI}>
									<CheckboxWithLabel
										label={item.head}
										classNames={["header-item"]}
									/>
									{item.values.map((subItem, sI) => (
										<CheckboxWithLabel
											key={sI}
											label={subItem}
											classNames={["sub-item"]}
										/>
									))}
								</div>
							),
						)}
					</div>
				))}
			</div>
		</animated.div>
	);
};

export default FilterDetails;
