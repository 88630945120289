/** @format */

import React, {useState} from "react";
import TextInput from "../../components/Inputs/TextInput";
import CloseIcon16 from "../../assets/icons/CloseIcon16";

const AdditionalCriteriaSettings = () => {
	const [criterias] = useState(["Board experience", "CPG experience"]);

	return (
		<div className="additional-criteria-form">
			<div className="title">Add Criteria</div>

			<TextInput customClass="custom-input" />

			<div className="tags">
				{criterias.map((item, i) => (
					<button key={i} className="btn btn-tag">
						{item}
						<CloseIcon16 color={"#141414"} />
					</button>
				))}
			</div>
		</div>
	);
};

export default AdditionalCriteriaSettings;
