import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Breadcrumb from "rs-components/Breadcrumb";
import Tabs from "rs-components/Plugins/Tabs";
import Select from "components/Inputs/Select";
import SearchBox from "rs-components/Filters/SearchBox";
import InterviewSchedule from "rs-components/Filters/InterviewSchedule";
import FilterDetails from "rs-components/Filters/FilterDetails";
import InterviewScheduleWithTimeline from "rs-components/Filters/InterviewScheduleWithTimeline";
import InterviewScheduleWithTimelineMobile from "rs-components/Filters/InterviewScheduleWithTimelineMobile";
import {useHistory, useLocation} from "react-router-dom";
import SummaryCard from "rs-components/Cards/Candidate/SummaryCard";
import {useDispatch, useSelector} from "react-redux";
import projectApi from "api/report/project";
import Loader from "components/Plugins/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import {
	getInterviewsByProjectId,
	getProgressStatusesData,
	getProjectSummaryByProjectId,
} from "store/project/projectSelectors";
import queryString from "qs";
import axios from "axios";
import {
	clearAssessmentData,
	setAssessmentData,
	setCandidatesData,
} from "store/project/projectActions";
import AssignmentInfoAsHeader from "rs-components/AssignmentInfoAsHeader";
import {checkboxFilters} from "./checkboxFilters";
import classNames from "classnames";
import useWindowSize from "hooks/useWindowSize";
import IconPrint from "assets/icons/IconPrint";
import PrintCandidateList from "rs-pages/Assesments/PrintCandidateList";
import {useReactToPrint} from "react-to-print";
import {getCurrentDate} from "helpers/string";
import {
	SortableContext,
	arrayMove,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
	DndContext,
	KeyboardSensor,
	PointerSensor,
	closestCenter,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {updateCandidateOrdering} from "store/project/projectAsyncActions";

let cancelToken;
let paramData = {skip: 0, take: 50};
let initialCandidatesLength = 0;
let initialForDiscussionCandidatesLength = 0;
let initialFilterType = "all_candidates";
let searchQuery = null;
const dropdownOptions = [
	{label: "Newest candidates", value: "date_added", sort: "desc"},
	{label: "Company A-Z", value: "company", sort: "asc"},
	{label: "Name A-Z", value: "name", sort: "asc"},
	{
		label: "Progress status",
		value: "progress_status",
		sort: "desc",
	},
];
const highlightedDropdownOptions = [
	{label: "Newest candidates", value: "date_added", sort: "desc"},
	{label: "Company A-Z", value: "company", sort: "asc"},
	{label: "Name A-Z", value: "name", sort: "asc"},
	{
		label: "Progress status",
		value: "progress_status",
		sort: "desc",
	},
	{
		label: "Preferred",
		value: "custom",
		sort: "desc",
	},
];
let initialForDiscussionValue = false;

const Assessments = (props) => {
	/*
	 * BREADCRUMB AND TAB SELECTION FROM PARAMS
	 */
	const history = useHistory();
	const search = useLocation().search;
	const fromDiscussionFromParam = new URLSearchParams(search)?.get("for-discussion");
	const currentFilterOnParam = new URLSearchParams(search).get("filter-type");

	const assessmentData = useSelector((state) => state.project.assessment);
	const candidatesData = useSelector((state) => state.project.candidates);

	const {width} = useWindowSize();

	const isMobile = width <= 425;

	const dispatch = useDispatch();
	const researchMode = useSelector((state) => state.user.researchModeEnabled);
	const summaryData = useSelector((state) => state.project.summaryData);
	const interviews = useSelector((state) => state.project.interviews);
	const [isAssessingPage, setIfAssessingPage] = useState(true);
	const [filterType, setFilterType] = useState(initialFilterType);
	const [query, setQuery] = useState(searchQuery);
	const [dropdown, setDropdown] = useState({
		label: "Progress status",
		value: "progress_status",
		sort: "desc",
	});
	const [highlightedDropdown, setHighlightedDropdown] = useState({
		label: "Preferred",
		value: "custom",
		sort: "desc",
	});

	if (candidatesData.length) {
		paramData = queryString.parse(assessmentData.query);
		paramData.skip = 0; // resetting as new request is being sent on init
		paramData.take = 50; // resetting as new request is being sent on init
		paramData.sort_by =
			currentFilterOnParam === "for_discussion"
				? highlightedDropdown.value
				: dropdown.value;
		paramData.order_by =
			currentFilterOnParam === "for_discussion"
				? highlightedDropdown.sort
				: dropdown.sort;
		initialCandidatesLength = parseInt(assessmentData.totalCounts.all_candidates);
		initialForDiscussionCandidatesLength = parseInt(
			assessmentData.totalCounts.for_discussion,
		);
		searchQuery = paramData.q;
		initialFilterType = currentFilterOnParam || initialFilterType;

		if (
			(paramData?.for_discussion === "true" ||
				fromDiscussionFromParam === "true") &&
			initialFilterType === "for_discussion"
		) {
			initialForDiscussionValue = true;
			paramData.for_discussion = true;
		} else {
			initialForDiscussionValue = false;
			paramData.for_discussion = false;
		}
	} else {
		initialCandidatesLength = 0;
		// initialSavannahInterviewingCandidatesLength = 0;
		initialForDiscussionCandidatesLength = 0;
		paramData.skip = 0;
		paramData.take = 50;
		paramData.sort_by =
			currentFilterOnParam === "for_discussion"
				? highlightedDropdown.value
				: dropdown.value;
		paramData.order_by =
			currentFilterOnParam === "for_discussion"
				? highlightedDropdown.sort
				: dropdown.sort;

		if (
			fromDiscussionFromParam === "true" &&
			(initialFilterType === "for_discussion" ||
				currentFilterOnParam === "for_discussion")
		) {
			initialForDiscussionValue = true;
			paramData.for_discussion = true;
		} else {
			initialForDiscussionValue = false;
			paramData.for_discussion = false;
		}
	}

	const [forDiscussion, setForDiscussion] = useState(initialForDiscussionValue);
	const [candidates, setCandidates] = useState(candidatesData);
	const [activeCandidates, setActiveCandidates] = useState([]);

	const [totalCandidates, setTotalCandidates] = useState(initialCandidatesLength);
	const [totalPinnedCandidates, setTotalPinnedCandidates] = useState(
		initialForDiscussionCandidatesLength,
	);
	// const [totalSavannahInterviewedCandidate, setTotalSavannahInterviewedCandidate] = useState(
	// 	initialSavannahInterviewingCandidatesLength,
	// );

	const [filterOptions, setFilterOptions] = useState([]);
	const [displayDetailsFilter, setDisplayDetailsFilter] = useState(false);
	const [displayInterviewFilter, setDisplayInterviewFilter] = useState(false);

	const {projectId} = props.match.params;
	const [loading, setLoading] = useState(false);
	const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);
	const [inlineLoading, setInlineLoading] = useState(false);

	const [skip, setSkip] = useState(paramData.skip);
	const [hasMore, setHasMore] = useState(true);
	const [param, setParam] = useState(paramData);
	const progressStatuses =
		useSelector((state) => state.project?.progress_statuses) || [];

	// HIDDEN SECTIONS
	const [displayLinkedInUrls, setDisplayLinkedInUrls] = useState(true);
	const [displayProfilePics, setDisplayProfilePics] = useState(true);
	const ref = useRef(true);

	useEffect(() => {
		if (fromDiscussionFromParam) {
			setFilterType("for_discussion");
		} else {
			setFilterType(currentFilterOnParam);
		}
	}, [currentFilterOnParam, fromDiscussionFromParam]);

	/*
	 * API REQUEST TO FETCH DATA
	 */

	const sendRequest = useCallback(
		async (queryParam, filterTypeParam) => {
			//Check if there are any previous pending requests
			if (typeof cancelToken !== typeof undefined) {
				cancelToken.cancel("Operation canceled due to new request.");
			}

			//Save the cancel token for the current request
			cancelToken = axios.CancelToken.source();
			const config = {cancelToken: cancelToken.token};

			await projectApi
				.getAssessmentDataByProjectId(
					projectId,
					researchMode,
					queryParam,
					config,
				)
				.then((response) => {
					if (response !== undefined && response.status === 200) {
						let data = response.data.results;
						const totalCounts = response.data.totals;

						dispatch(
							setAssessmentData({
								totalCounts,
								queryParam,
								filterTypeParam,
							}),
						);

						setTotalCandidates(totalCounts.all_candidates);
						// setTotalSavannahInterviewedCandidate(totalCounts.savannah_interviewing);
						setTotalPinnedCandidates(totalCounts.for_discussion);

						const queryObject = queryString.parse(queryParam);
						let shouldAddMore = false;

						// checking if it's 1st page
						if (queryObject.skip === "0") {
							setCandidates([...data]);
						} else {
							shouldAddMore = true;
							setCandidates((prevCandidates) => [
								...prevCandidates,
								...data,
							]);
						}

						dispatch(
							setCandidatesData({
								candidates: data,
								shouldAddMore: shouldAddMore,
							}),
						);

						if (!data.length || data.length < paramData.take) {
							setHasMore(false);
						} else {
							setHasMore(true);
						}
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		[dispatch, projectId, researchMode],
	);

	const setData = useCallback(
		async (filterTypeParam, updatedParam = paramData) => {
			if (updatedParam?.take === undefined) {
				updatedParam.take = 50;
			}

			let q = queryString.stringify(updatedParam);

			await sendRequest(q, filterTypeParam);
		},
		[sendRequest],
	);

	const setDropdownDataOnState = useCallback(async () => {
		if (!progressStatuses.length) {
			await dispatch(getProgressStatusesData());
		}
	}, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

	/*
	 * FILTER SELECTION HANDLE TO FOR NEW API REQUEST
	 */
	const handleFilter = async (type, value) => {
		dispatch(clearAssessmentData());
		await setCandidates([]);
		let updateParam = {skip: 0, take: 50, for_discussion: forDiscussion};

		setSkip(0);

		if (type === "search") {
			updateParam = {...updateParam, q: value};
			setQuery(value);
		}

		// if (dropdown) {
		// 	if (value?.value !== "custom") {
		// 		updateParam = {
		// 			...updateParam,
		// 			sort_by: dropdown.value,
		// 			order_by: dropdown.sort,
		// 		};
		// 	} else {
		// 		// eslint-disable-next-line no-unused-vars
		// 		const {sort_by, order_by, ...rest} = updateParam;
		// 		updateParam = {...rest};
		// 	}
		// 	setDropdown(value);
		// }

		if (type === "dropdown") {
			updateParam = {
				...updateParam,
				sort_by: value.value,
				order_by:
					value.value === param?.sort_by
						? param.order_by === "asc"
							? "desc"
							: "asc"
						: "asc",
			};
			value.sort = updateParam.order_by;
			if (filterType === "for_discussion") {
				setHighlightedDropdown(value);
			} else {
				setDropdown(value);
			}
		}

		if (type === "for_discussion") {
			if (value) {
				setForDiscussion(true);
			} else {
				setForDiscussion(false);
				value = "";
				history.replace({});
			}

			updateParam = {
				...updateParam,
				sort_by: value ? highlightedDropdown.value : dropdown.value,
				order_by: value ? highlightedDropdown.sort : dropdown.sort,
			};

			updateParam = {...updateParam, for_discussion: value};
		}

		Object.keys(updateParam).forEach((key) => {
			if (updateParam[key] === "") {
				delete updateParam[key];
			}
		});

		setParam(updateParam);
		setLoading(true);
		await sendRequest(queryString.stringify(updateParam), filterType).finally(() =>
			setLoading(false),
		);
	};

	/*
	 * HANDLES INFINITE SCROLL BEHAVIOR
	 */
	const onGetTargetData = async () => {
		if (
			!hasMore &&
			!loading &&
			!inlineLoading &&
			activeCandidates.length === assessmentData?.totalCounts?.all_candidates
		)
			return;

		const updatedSkipVal = parseInt(skip) + 50;

		setSkip(updatedSkipVal);
		setInlineLoading(true);
		const updatedParam = {...param, skip: updatedSkipVal, take: 50};

		setParam(updatedParam);
		await setData(filterType, updatedParam);
		setInlineLoading(false);
	};

	/*
	 * HANDLES TAB CLICK BEHAVIOR
	 */
	const handleActiveTab = (index) => {
		let url;

		if (index === 1) {
			setFilterType("for_discussion");
			url = `/projects/${projectId}/assessments?filter-type=for_discussion&for-discussion=true`;
		} else {
			setFilterType("all_candidates");
			url = `/projects/${projectId}/assessments?filter-type=all_candidates`;
		}

		history.push(url);

		handleFilter("for_discussion", index === 1);
	};

	/*
	 * HANDLES BREADCRUMB CLICK BEHAVIOR
	 */
	const handleBreadcrumbClick = (breadcrumbIndex) => {
		setIfAssessingPage(breadcrumbIndex === 1);

		if (breadcrumbIndex === 1) {
			setFilterType("all_candidates");

			setLoading(true);
			const params = {
				skip: 0,
				take: 50,
				for_discussion: forDiscussion,
			};
			setData("all_candidates", params)
				.then(() => setLoading(false))
				.then(() => setInitialFetchCompleted(true));
		} else {
			setFilterType("interviewed_by_client");
		}
	};

	const handleDragEnd = (event) => {
		const {active, over} = event;
		if (active.id !== over.id) {
			const oldIndex = activeCandidates.findIndex(
				(item) => item.id === active.id,
			);
			const candidate = activeCandidates[oldIndex];
			let newIndex = activeCandidates.findIndex((item) => item.id === over.id);

			let customOrder = 0;
			if (filterType === "for_discussion") {
				customOrder = activeCandidates[newIndex].custom_order;
			}

			setActiveCandidates((items) => arrayMove(items, oldIndex, newIndex));

			dispatch(
				updateCandidateOrdering({
					projectId,
					candidateId: candidate.id,
					index: filterType === "for_discussion" ? customOrder : newIndex + 1,
				}),
			);
		}
	};

	/*
	 * INITIAL RENDER WHERE IT FETCHES DATA BY API
	 */
	useEffect(() => {
		if (summaryData?.hasData) {
			setLoading(true);
			setData("all_candidates")
				.then(() => setLoading(false))
				.then(() => setInitialFetchCompleted(true))
				.then(() => setFilterOptions(checkboxFilters))
				.finally(() => setDropdownDataOnState());
		}
	}, [dispatch, setData, setDropdownDataOnState, summaryData?.hasData]);

	useEffect(() => {
		async function get() {
			await dispatch(getProjectSummaryByProjectId(projectId, researchMode));
		}

		if (!summaryData?.hasData) {
			setLoading(true);
			get().then(() => setLoading(false));
		}
	}, [dispatch, projectId, researchMode, summaryData?.hasData]);

	useEffect(() => {
		if (!initialFetchCompleted) {
			dispatch(getInterviewsByProjectId(projectId));
		}

		return () => {
			// when initial fetch done, and user is toggle on/off research mode we clear the state
			if (initialFetchCompleted) {
				setActiveCandidates([]);
				setCandidates([]);
				setSkip(0);
				setParam(null);
				// dispatch(clearAssessmentData());
			}

			ref.current = false;
		};
	}, [researchMode, dispatch, initialFetchCompleted, projectId]);

	useEffect(() => {
		if (summaryData?.settings) {
			const settings = summaryData.settings;

			if (settings?.show_candidate_avatars !== undefined) {
				setDisplayProfilePics(settings.show_candidate_avatars);
			} else {
				setDisplayProfilePics(true);
			}

			if (settings?.show_linkedin_urls !== undefined) {
				setDisplayLinkedInUrls(settings.show_linkedin_urls);
			} else {
				setDisplayLinkedInUrls(true);
			}

			if (settings?.show_only_for_discussion !== undefined) {
				setForDiscussion(settings.show_only_for_discussion);
			} else {
				setForDiscussion(false);
			}
		}
	}, [summaryData?.settings]);

	/*
	 * RE-RENDERS THE DATA BY FILTER SELECTION
	 */

	useEffect(() => {
		let data = [...candidates];

		if (filterType === "interviewed_by_client") {
			data = data.filter(
				(item) => item?.status_info?.interviewing?.toLowerCase() === "client",
			);
			setForDiscussion(false);
			setIfAssessingPage(false);
		} else if (filterType === "for_discussion") {
			setForDiscussion(true);
			setIfAssessingPage(true);
		} else {
			setForDiscussion(false);
			setIfAssessingPage(true);
		}

		let position = 1;
		data = data.map((item) => {
			if (
				researchMode ||
				(!researchMode && (item.is_clickable || item.for_discussion))
			) {
				item.position = position;
				position++;
			} else {
				item.position = null;
			}

			return item;
		});

		setActiveCandidates([...data]);
	}, [candidates, filterType, projectId, researchMode]);

	const LoaderComponent = (
		<Loader
			displayAtCenterOfPage={true}
			type={"Rings"}
			height={80}
			width={80}
			color={"#f13a3a"}
		/>
	);

	const InlineLoaderComponent = <Loader height={40} width={40} color={"#f13a3a"} />;

	const candidateProfileStringParameter = useMemo(
		() =>
			"?filter-type=" +
			(filterType || "all_candidates") +
			"&for-discussion=" +
			forDiscussion,
		[filterType, forDiscussion],
	);

	const tabOptions = useMemo(() => {
		let options = [`All Candidates (${totalCandidates})`];

		if (summaryData?.settings) {
			const settings = summaryData.settings;

			if (settings.show_only_for_discussion) {
				options.splice(0, 1);
			}
		}

		options = [...options, `Highlighted Profiles (${totalPinnedCandidates})`];

		return options;
	}, [summaryData?.settings, totalCandidates, totalPinnedCandidates]);

	/**
	 * PRINTING CANDIDATES LOGIC
	 */
	const componentRef = useRef();
	const onBeforeGetContentResolve = React.useRef(null);

	const [printPageLoading, setPrintPageLoading] = React.useState(false);
	const [printLabelText, setPrintLabelText] = useState("Print All Candidates");

	const handleOnBeforeGetContent = React.useCallback(async () => {
		setPrintPageLoading(true);
		setPrintLabelText("Loading print preview ...");

		if (activeCandidates.length < assessmentData?.totalCounts?.all_candidates) {
			let params = queryString.parse(paramData);
			params = {
				...params,
				skip: activeCandidates.length,
				take:
					(assessmentData?.totalCounts?.all_candidates || 1000) -
					activeCandidates.length,
			};
			setSkip(assessmentData?.totalCounts?.all_candidates);
			setParam(params);
			setInlineLoading(true);

			await sendRequest(queryString.stringify(params), filterType).finally(() =>
				setInlineLoading(false),
			);
			setHasMore(false); // because we already fetched everything;
		}

		return new Promise((resolve) => {
			onBeforeGetContentResolve.current = resolve;

			const printType = filterType === "for_discussion" ? "Highlighted" : "All";

			setTimeout(() => {
				setPrintPageLoading(false);
				setPrintLabelText(`Print ${printType} Candidates`);
				resolve();
			}, 2000);
		});
	}, [activeCandidates.length, assessmentData, filterType, sendRequest]);

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: `${summaryData?.assignmentInfo?.company?.name} - ${
			summaryData?.assignmentInfo?.assignment_name
		} - ${getCurrentDate()}`,
		onBeforeGetContent: handleOnBeforeGetContent,
		removeAfterPrint: true,
	});

	useEffect(() => {
		const printType = filterType === "for_discussion" ? "Highlighted" : "All";
		setPrintLabelText(`Print ${printType} Candidates`);
	}, [filterType]);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	return (
		<div className={"main-content-area"}>
			{!isMobile && (
				<div style={{display: "none"}}>
					<PrintCandidateList
						candidatesData={activeCandidates}
						ref={componentRef}
					/>
				</div>
			)}

			<div
				className={classNames("content-wrapper assessment-page", {
					"research-mode": researchMode,
				})}
				id="content-wrapper"
			>
				<div className="container custom-scrollbar">
					<Breadcrumb
						options={[
							{
								name: "Summary",
								to: `/projects/${projectId}/summary`,
							},
							{
								name: "Savannah Assessing",
								to: `/projects/${projectId}/assessments?filter-type=${
									!forDiscussion ? "all_candidates" : "for_discussion"
								}${forDiscussion ? "&for-discussion=true" : ""}`,
							},
							{
								name: "Interviewing",
								to: `/projects/${projectId}/assessments?filter-type=interviewed_by_client`,
							},
						]}
						activeIndex={isAssessingPage ? 1 : 2}
						clickHandle={handleBreadcrumbClick}
					/>

					<AssignmentInfoAsHeader
						backCallback={
							isMobile && displayInterviewFilter
								? () => setDisplayInterviewFilter(false)
								: null
						}
						headerInfo={
							isMobile && displayInterviewFilter
								? "Upcoming Interviews"
								: null
						}
					/>

					{!(displayInterviewFilter && isMobile) && (
						<div className="tabs-with-info">
							{isAssessingPage && (
								<Tabs
									key={filterType}
									onActive={handleActiveTab}
									activeIndex={
										filterType === "for_discussion" ? 1 : 0
									}
									options={tabOptions}
								/>
							)}

							{/*{researchMode && (*/}
							{/*	<div className="info" style={{paddingBottom: "14px"}}>*/}
							{/*		Last synced from Invenias: 3 hours ago <IconSync />*/}
							{/*	</div>*/}
							{/*)}*/}

							<div className="buttons">
								<InterviewSchedule
									classNames={
										displayInterviewFilter
											? ["active filter-item-group"]
											: ["filter-item-group"]
									}
									onClick={() => {
										setDisplayDetailsFilter(false);
										setDisplayInterviewFilter(
											(prevDisplayInterviewFilter) =>
												!prevDisplayInterviewFilter,
										);
									}}
								/>

								{!!activeCandidates?.length && !isMobile && (
									<div className="print-candidates">
										<button
											className="btn btn-white"
											onClick={handlePrint}
											disabled={printPageLoading}
										>
											<IconPrint /> {printLabelText}
										</button>

										{printPageLoading && (
											<span className="warning">
												* It may take while to load the
												candidate list
											</span>
										)}
									</div>
								)}
							</div>
						</div>
					)}

					{displayInterviewFilter && isMobile ? null : (
						<div className="filter-options">
							<div className="left-block-filters">
								<div className="filter-item-group">
									<SearchBox
										placeholder="Search for candidates"
										defaultValue={query}
										onChange={(e) =>
											handleFilter("search", e.target.value)
										}
									/>

									{/*<FilterButton*/}
									{/*    classNames={displayDetailsFilter ? ['active'] : []}*/}
									{/*    onClick={() => {*/}
									{/*        setDisplayInterviewFilter(false)*/}
									{/*        setDisplayDetailsFilter((displayDetailsFilter) => !displayDetailsFilter);*/}
									{/*    }}/>*/}
								</div>
							</div>

							<div className="right-block-filters">
								{isAssessingPage && (
									<div className="dropdown-block">
										<span className="dropdown-label">Sort:</span>
										<div className="dropdown">
											<Select
												altDropdownIndicator={true}
												isSearchable={false}
												options={
													filterType === "for_discussion"
														? highlightedDropdownOptions
														: dropdownOptions
												}
												value={
													filterType === "for_discussion"
														? highlightedDropdown
														: dropdown
												}
												placeholder="Select"
												onChange={(e) =>
													handleFilter("dropdown", e)
												}
												onRemove={() => {}}
												customClass={"dropdown-custom"}
											/>
										</div>
									</div>
								)}

								{/*{!!isAssessingPage && (*/}
								{/*	<div className="pin-item">*/}
								{/*		<button*/}
								{/*			className={[*/}
								{/*				"btn discussion-btn",*/}
								{/*				"btn-red",*/}
								{/*				forDiscussion ? "active" : "default",*/}
								{/*			].join(" ")}*/}
								{/*			onClick={() =>*/}
								{/*				handleFilter("for_discussion", !forDiscussion)*/}
								{/*			}*/}
								{/*		>*/}
								{/*			<IconPin*/}
								{/*				className={"pin-icon"}*/}
								{/*				color={["#fff"].join(" ")}*/}
								{/*			/>*/}
								{/*			For discussion ({totalPinnedCandidates})*/}
								{/*		</button>*/}
								{/*	</div>*/}
								{/*)}*/}
							</div>
						</div>
					)}

					<FilterDetails
						filterOptions={filterOptions}
						toggle={displayDetailsFilter}
					/>

					{isMobile ? (
						<InterviewScheduleWithTimelineMobile
							interviews={interviews}
							toggle={displayInterviewFilter}
						/>
					) : (
						<InterviewScheduleWithTimeline
							interviews={interviews}
							toggle={displayInterviewFilter}
						/>
					)}

					{loading && LoaderComponent}

					{!loading &&
						!(displayInterviewFilter && isMobile) &&
						(activeCandidates?.length ? (
							<div className="candidates-card-wrapper">
								<DndContext
									sensors={sensors}
									collisionDetection={closestCenter}
									onDragEnd={handleDragEnd}
								>
									<SortableContext
										items={activeCandidates}
										strategy={verticalListSortingStrategy}
									>
										<InfiniteScroll
											key={`totalCandidate${activeCandidates.length}`}
											dataLength={activeCandidates.length}
											initialLoad={false}
											next={onGetTargetData}
											hasMore={hasMore}
											loader={
												inlineLoading &&
												skip > 0 && (
													<div
														className="loader"
														key={0}
														style={{
															display: "flex",
															alignItems: "center",
															justifyContent: "center",
														}}
													>
														{InlineLoaderComponent}
													</div>
												)
											}
											scrollableTarget="content-wrapper"
										>
											{activeCandidates.map(
												(candidate, index) => (
													<SummaryCard
														candidate={candidate}
														candidateProfileStringParameter={`${candidateProfileStringParameter}${
															candidate.position &&
															"&position=" +
																candidate.position
														}`}
														key={`${index.toString()}${
															candidate.id
														}`}
														projectId={projectId}
														displayProfilePic={
															displayProfilePics
														}
														displayLinkedInUrl={
															displayLinkedInUrls
														}
														sortable={
															filterType ===
																"for_discussion" &&
															researchMode
														}
														disableDragHandle={
															highlightedDropdown?.value !==
															"custom"
														}
													/>
												),
											)}
										</InfiniteScroll>
									</SortableContext>
								</DndContext>
							</div>
						) : (
							<span
								className="candidates-card-wrapper"
								style={{padding: "40px"}}
							>
								{filterType === "interviewed_by_client" &&
									"There currently aren't any candidates at client interview stage yet."}

								{filterType === "for_discussion" &&
									"There currently aren't any candidates selected as highlighted yet."}

								{(filterType === "all_candidates" ||
									filterType === null) &&
									"There currently aren't any candidates added to this project yet."}
							</span>
						))}
				</div>
			</div>
		</div>
	);
};

export default Assessments;
