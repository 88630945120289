/** @format */

import React from "react";

const CaretUpIcon = ({width = 20, height = 20, color = "#141414"}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.55025 11.1166C6.47137 11.1949 6.427 11.3014 6.427 11.4125C6.427 11.5236 6.47137 11.6301 6.55025 11.7083L6.71692 11.875C6.79531 11.9563 6.90393 12.0016 7.01692 12H12.9836C13.0966 12.0016 13.2052 11.9563 13.2836 11.875L13.4503 11.7083C13.5291 11.6301 13.5735 11.5236 13.5735 11.4125C13.5735 11.3014 13.5291 11.1949 13.4503 11.1166L10.2919 7.95829C10.2163 7.87773 10.1107 7.83202 10.0003 7.83202C9.88976 7.83202 9.7842 7.87773 9.70858 7.95829L6.55025 11.1166Z"
				fill={color}
			/>
		</svg>
	);
};

export default CaretUpIcon;
