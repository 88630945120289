import React, {useCallback, useEffect, useState} from "react";
import TextInput from "../../components/Inputs/TextInput";
import SelectComponent from "../../components/Inputs/Select";
import IconCloseBold from "../../assets/icons/IconCloseBold";
import Avatar from "assets/images/avatar.svg";
import search from "../../api/search";
import Loader from "../../components/Plugins/Loader";
import useFormValidation from "../../hooks/useFormValidation";
import userManagement from "../../api/report/userManagement";
import {useDispatch, useSelector} from "react-redux";
import {setEditUserInfo, updateAccountInfo} from "store/user/userActions";
import CloseIcon from "../../assets/icons/CloseIcon";
import LogoutButton from "../../components/Buttons/LogoutButton";
import {toast} from "react-toastify";
import {UploadFile} from "rs-components/UploadFile/UploadFile";

const roles = [
	{label: "Admin", value: "Admin"},
	{label: "RT User", value: "RT_User"},
	{label: "MapX User", value: "MapX_User"},
	{label: "RT_MapX User", value: "RT_MapX_User"},
	{label: "Client", value: "Client"},
];

const initialUserData = {
	first_name: "",
	last_name: "",
	image: null,
	email: "",
	role: "",
	password: "",
};

const Form = (props) => {
	const userAccount = useSelector((state) => state.user.user);
	const dispatch = useDispatch();
	const editUser = useSelector((state) => state.user.editUserInfo);
	const [loading, setLoading] = useState(false);
	const [errorText, setErrorText] = useState(null);
	const [pageType, setPageType] = useState("create");
	const [userInfo, setUserInfo] = useState(editUser || initialUserData);
	const [avatarUrl, setAvatarUrl] = useState(
		editUser && editUser.image ? `data:${editUser.image}` : Avatar,
	);
	const [company, setCompany] = useState(
		editUser
			? {
					label: editUser.company,
					value: editUser.company_id,
			  }
			: {},
	);

	const removeImage = () => {
		setAvatarUrl("");
		setUserInfo((prevState) => ({
			...prevState,
			image: null,
		}));
	};

	const initialValidation = useState({
		first_name: {valid: false},
		last_name: {valid: false},
		email: {valid: false},
		role: {valid: false},
		password: {valid: false},
	});
	const [userData, validation, changeForm, setFormData, updateValidation] =
		useFormValidation({...userInfo} || {...initialUserData}, {
			...initialValidation,
		});
	const {location} = props;

	const handleFileUpload = (e) => {
		e.preventDefault();

		let reader = new FileReader();
		const file = e.target.files[0];

		reader.onloadend = () => {
			setAvatarUrl(reader.result);
		};

		reader.readAsDataURL(file);
		changeForm("image", file);
	};

	const getCompanies = async (input) => {
		return await new Promise((resolve) => {
			getCompanyData(input).then((r) => {
				resolve(r);
			});
		});
	};

	const getCompanyData = async (query) => {
		const response = await search.getData(query);

		if (response.data && response.data.results !== undefined) {
			return response.data.results.map((item) => {
				return {
					label: item.name,
					value: item.company_id,
				};
			});
		} else {
			return [];
		}
	};

	const save = async () => {
		// let image = Avatar === avatarUrl ? null : avatarUrl

		const data = {
			...userData,
			// image,
			company_id: company.value,
		};

		if (pageType !== "create") {
			delete data.company;
			delete data.is_active;
			delete data.email;
		}

		if (userAccount.companyId === company.value || !data.company_id) {
			delete data.company_id;
		}

		if (/https?:\/\//g.test(avatarUrl)) {
			delete data.image;
		}

		if (data.password === null || data.password === "") {
			delete data.password;
		}

		setLoading(true);

		if (pageType === "account") {
			await updateProfile(data);
		} else {
			await saveUserByAdmin(data);
		}
	};

	const saveUserByAdmin = async (data) => {
		await userManagement
			.saveUser(data)
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					dispatch(setEditUserInfo(null));
					props.history.push("/users");
				} else if (response.data.detail) {
					setErrorText(response.data.detail);
				}
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => setLoading(false));
	};

	const updateProfile = async (data) => {
		let config = {};

		let formData = new FormData();

		Object.keys(data).forEach((key) => {
			formData.append(key, data[key]);
		});

		if (formData.image) {
			config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
		} else {
			await userManagement.deletePicture();
		}

		await userManagement
			.updateProfile(formData, config)
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					dispatch(
						updateAccountInfo({
							...userAccount,
							firstName: data.first_name,
							lastName: data.last_name,
							company: company.label || null,
							companyId: company.value || null,
							image: data.image ? avatarUrl : null,
						}),
					);

					toast.success("Account information updated successfully!");
				} else if (response.data.detail) {
					setErrorText(response.data.detail);
				}
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => setLoading(false));
	};

	const setPage = useCallback(() => {
		const routeArray = location.pathname.split("/");
		const currentPage = routeArray[1];

		switch (currentPage) {
			case "edit":
				setPageType("edit");
				break;
			case "account":
				setPageType("account");
				setUserInfo({
					first_name: userAccount.firstName,
					last_name: userAccount.lastName,
					image: null,
					email: userAccount.email,
					password: null,
				});
				setCompany({
					label: userAccount.company,
					value: userAccount.companyId,
				});
				setAvatarUrl(userAccount.image);

				break;
			default:
				setPageType("create");
		}
	}, [location, userAccount]);

	useEffect(() => {
		setPage();
	}, [setPage]);

	useEffect(() => {
		const validationData = {
			first_name: {valid: userInfo ? !!userInfo.first_name : false},
			last_name: {valid: userInfo ? !!userInfo.last_name : false},
			email: {valid: userInfo ? !!userInfo.email : false},
			role: {
				valid: userInfo ? !!userInfo.role || pageType === "account" : false,
			},
			password: {valid: !!userInfo},
		};

		setFormData(userInfo);
		updateValidation({...validationData});
	}, [userInfo, setFormData, updateValidation, pageType]);

	const InlineLoaderComponent = (
		<Loader
			height={16}
			width={16}
			customStyle={{paddingLeft: "10px"}}
			type="TailSpin"
			color={"#f13a3a"}
		/>
	);

	const submitEnabled =
		validation &&
		validation?.first_name?.valid &&
		validation?.last_name?.valid &&
		validation?.role?.valid &&
		validation?.email?.valid &&
		validation?.password?.valid;

	return (
		<div className={["main-content-area", "user-form-page"].join(" ")}>
			<div className={"content-wrapper"}>
				<div className="container">
					<div className="page-title">Profile Management</div>

					<div className="grid row">
						<div className="col avatar-container">
							<div className="title">
								<span>Profile Photo</span>

								{userAccount.is_invenias_user ? null : (
									<IconCloseBold
										width={12}
										height={12}
										onClick={removeImage}
									/>
								)}
							</div>

							<div className="avatar-box">
								<div className="box">
									{avatarUrl && <img src={avatarUrl} alt="" />}
								</div>

								<UploadFile handleFileUpload={handleFileUpload} />
							</div>
						</div>
						<div className="col">
							<div className="user-form">
								<div className="title">Settings</div>
								<div className="grid row">
									<div className="col input-wrapper">
										<TextInput
											label={"First Name"}
											name="First Name"
											defaultValue={userInfo.first_name}
											customClass={[
												"custom-input",
												validation.first_name?.error
													? "has-error"
													: "",
											].join(" ")}
											errorText={validation.first_name?.error}
											disabled={userAccount.is_invenias_user}
											onChange={(e) =>
												changeForm("first_name", e.target.value)
											}
										/>
									</div>

									<div className="col input-wrapper">
										<TextInput
											label={"Last Name"}
											defaultValue={userInfo.last_name}
											name="Last Name"
											customClass={[
												"custom-input",
												validation.last_name?.error
													? "has-error"
													: "",
											].join(" ")}
											errorText={validation.last_name?.error}
											disabled={userAccount.is_invenias_user}
											onChange={(e) =>
												changeForm("last_name", e.target.value)
											}
										/>
									</div>
								</div>
								<div className="grid row">
									<div className="col input-wrapper">
										<TextInput
											label={"Email"}
											disabled={pageType !== "create-new-user"}
											name="Email"
											type="email"
											defaultValue={userInfo.email}
											customClass={[
												"custom-input",
												validation.email?.error
													? "has-error"
													: "",
											].join(" ")}
											errorText={validation.email?.error}
											onChange={(e) =>
												changeForm("email", e.target.value)
											}
										/>
									</div>

									{userAccount.is_invenias_user ? (
										<>
											<div className="col input-wrapper">
												<label htmlFor="">Company</label>
												<SelectComponent
													placeholder="Search..."
													altDropdownIndicator={true}
													customStyle={{
														border: "1px solid #DEDEDE",
														padding: "2px 16px",
													}}
													isAsync={true}
													onChange={(selectedOption) =>
														setCompany(selectedOption)
													}
													value={company}
													cacheOptions
													isDisabled={
														userAccount.is_invenias_user
													}
													loadOptions={getCompanies}
												/>
											</div>
										</>
									) : (
										<div className="col" />
									)}
								</div>

								{pageType !== "account" && (
									<div className="grid row">
										<div className="col input-wrapper">
											<label htmlFor="">Role</label>
											<SelectComponent
												customStyle={{
													border: "1px solid #DEDEDE",
													padding: "2px 16px",
												}}
												options={roles}
												altDropdownIndicator={true}
												name="Role"
												defaultValue={roles.filter(
													(role) =>
														role.label === userInfo.role,
												)}
												customClass={[
													"custom-input",
													validation.role?.error
														? "has-error"
														: "",
												].join(" ")}
												errorText={validation.role?.error}
												onChange={(e) =>
													changeForm("role", e.value)
												}
											/>
										</div>

										<div className="col input-wrapper">
											<TextInput
												label={"Password"}
												type="password"
												placeholder={"**************"}
												name="Password"
												customClass={[
													"custom-input",
													validation.password?.error
														? "has-error"
														: "",
												].join(" ")}
												errorText={validation.password?.error}
												onChange={(e) =>
													changeForm(
														"password",
														e.target.value,
													)
												}
											/>
										</div>
									</div>
								)}

								{errorText && (
									<div className="error-messages">
										<span>{errorText}</span>

										<span>
											<CloseIcon
												key={"error"}
												onClick={() => setErrorText(null)}
											/>
										</span>
									</div>
								)}
							</div>
							<div className="footer">
								<LogoutButton />

								<button
									className="btn btn-white"
									onClick={() => window.history.back()}
								>
									Back
								</button>

								<button
									className={[
										"btn",
										loading ||
										!submitEnabled ||
										userAccount.is_invenias_user
											? "btn-disabled"
											: "btn-red",
									].join(" ")}
									onClick={save}
									disabled={
										!submitEnabled ||
										loading ||
										userAccount.is_invenias_user
									}
								>
									{loading ? (
										<>Saving {InlineLoaderComponent}</>
									) : (
										"Save Changes"
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Form;
