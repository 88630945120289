/** @format */

import {parseISO} from "date-fns";

const epochs = [
	["year", 31536000],
	["month", 2592000],
	["day", 86400],
	["hour", 3600],
	["minute", 60],
	["second", 1],
];

const getDuration = (timeAgoInSeconds) => {
	for (let [name, seconds] of epochs) {
		const interval = Math.floor(timeAgoInSeconds / seconds);

		if (interval >= 1) {
			return {
				interval: interval,
				epoch: name,
			};
		}
	}

	return {
		interval: "Just",
		epoch: "now",
	};
};

export const timeAgo = (date, isUnix = true) => {
	if (!isUnix) {
		date = parseISO(date, "yyyy-MM-dd", new Date());
		date = date.getTime();
	}

	const timeAgoInSeconds = Math.floor((new Date() - new Date(date)) / 1000);
	const {interval, epoch} = getDuration(timeAgoInSeconds);
	const suffix = interval >= 1 ? "s" : "";

	return interval >= 1 ? `${interval} ${epoch}${suffix} ago` : `${interval} ${epoch}`;
};
