import {
	TOGGLE_COMPANY_LOGO_MODAL,
	TOGGLE_PEOPLE_LOGO_MODAL,
} from "store/reportActionTypes";

export function toggleCompanyLogoModal(modalProps = {}) {
	return {
		type: TOGGLE_COMPANY_LOGO_MODAL,
		companyLogoModalProps: modalProps,
	};
}

export function togglePeopleLogoModal(modalProps = {}) {
	return {
		type: TOGGLE_PEOPLE_LOGO_MODAL,
		peopleLogoModalProps: modalProps,
	};
}
