/** @format */

import React from "react";

const CheckboxWithLabel = ({label, classNames = [], ...rest}) => {
	return (
		<div className={["checkbox-with-label", ...classNames].join(" ")}>
			<input
				type="checkbox"
				id={label}
				style={{display: "none"}}
				className="checkbox-custom"
				{...rest}
			/>
			{/*<label htmlFor={label}>{label}</label>*/}

			<label className="custom-checkbox-label" htmlFor={label}>
				<span>
					<svg width="12px" height="10px" viewBox="0 0 12 10">
						<polyline points="1.5 6 4.5 9 10.5 1" />
					</svg>
				</span>
				<span>{label}</span>
			</label>
		</div>
	);
};

export default CheckboxWithLabel;
