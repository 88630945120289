import React from "react";

const OpenEye = (props) => (
	<svg
		width={24}
		height={24}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		{...props}
	>
		<mask
			id="open-eye_svg__a"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={24}
			height={24}
		>
			<path fill="#D9D9D9" d="M0 0h24v24H0z" />
		</mask>
		<g mask="url(#open-eye_svg__a)">
			<path
				d="M12 16c1.25 0 2.313-.437 3.188-1.312S16.5 12.75 16.5 11.5c0-1.25-.437-2.313-1.312-3.188S13.25 7 12 7c-1.25 0-2.313.437-3.188 1.312S7.5 10.25 7.5 11.5c0 1.25.437 2.313 1.312 3.188S10.75 16 12 16Zm0-1.8c-.75 0-1.387-.263-1.912-.788A2.601 2.601 0 0 1 9.3 11.5c0-.75.263-1.388.788-1.913A2.603 2.603 0 0 1 12 8.8c.75 0 1.388.262 1.913.787.525.525.787 1.163.787 1.913s-.262 1.387-.787 1.912A2.605 2.605 0 0 1 12 14.2Zm0 4.8c-2.433 0-4.65-.68-6.65-2.038-2-1.358-3.45-3.179-4.35-5.462.9-2.283 2.35-4.104 4.35-5.463C7.35 4.679 9.567 4 12 4c2.433 0 4.65.679 6.65 2.037 2 1.359 3.45 3.18 4.35 5.463-.9 2.283-2.35 4.104-4.35 5.462C16.65 18.321 14.433 19 12 19Zm0-2a9.545 9.545 0 0 0 5.188-1.488A9.77 9.77 0 0 0 20.8 11.5a9.777 9.777 0 0 0-3.612-4.013A9.55 9.55 0 0 0 12 6a9.55 9.55 0 0 0-5.188 1.487A9.777 9.777 0 0 0 3.2 11.5a9.77 9.77 0 0 0 3.612 4.012A9.545 9.545 0 0 0 12 17Z"
				fill="#999"
			/>
		</g>
	</svg>
);

export default OpenEye;
