import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames";

export default function TextFieldMessage({errorText}) {
	if (errorText) {
		return (
			<span className={classNames(styles.errorText, "error-text")}>
				{errorText}
			</span>
		);
	}

	return null;
}
