import React from "react";

export const PencilIcon = () => (
	<svg
		width="15"
		height="15"
		viewBox="0 0 15 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.0853 0.366024L14.6341 0.914917C14.868 1.14864 14.9996 1.46573 14.9999 1.79647V2.42853C15.0041 2.76503 14.8721 3.08894 14.6341 3.32672L13.0959 4.84033C13.0179 4.91906 12.9116 4.96334 12.8008 4.96334C12.6899 4.96334 12.5837 4.91906 12.5056 4.84033L10.1693 2.47011C10.0109 2.30842 10.0109 2.04965 10.1693 1.88796L11.6742 0.366024C11.9119 0.127893 12.2357 -0.00406942 12.5721 9.5688e-05H13.204C13.5347 0.000386854 13.8517 0.132009 14.0853 0.366024ZM11.5162 6.4038C11.6747 6.2421 11.6747 5.98333 11.5162 5.82164L9.13834 3.47637C9.06028 3.39764 8.95403 3.35336 8.84318 3.35336C8.73234 3.35336 8.62608 3.39764 8.54802 3.47637L1.8384 10.1878C1.67619 10.3486 1.54883 10.5411 1.46426 10.7534L0.0342045 14.3627C-0.0468046 14.5501 0.0201286 14.7686 0.192176 14.8784C0.31001 14.9961 0.486679 15.0321 0.641147 14.9699L4.24954 13.5061C4.46169 13.4215 4.65415 13.2942 4.81491 13.1319L11.5162 6.4038Z"
			fill="#999999"
		/>
	</svg>
);
