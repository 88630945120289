import React from "react";

const ClipboardIcon = (props) => (
	<svg
		width={16}
		height={16}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 16 16"
		{...props}
	>
		<mask
			id="ClipboardIcon_svg__a"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={16}
			height={16}
		>
			<path fill="#D9D9D9" d="M0 0h16v16H0z" />
		</mask>
		<g mask="url(#ClipboardIcon_svg__a)" fill="#1C1B1F">
			<path d="M3.333 14c-.366 0-.68-.13-.942-.391A1.285 1.285 0 0 1 2 12.667V3.333c0-.366.13-.68.391-.942.262-.26.576-.391.942-.391h2.784a1.82 1.82 0 0 1 .716-.959C7.19.791 7.578.667 8 .667c.444 0 .842.125 1.192.374.35.25.586.57.708.959h2.767c.366 0 .68.13.942.391.26.262.391.576.391.942v4h-1.333v-4h-1.334v2H4.667v-2H3.333v9.334h3.334V14H3.333ZM8 3.333a.646.646 0 0 0 .475-.192.644.644 0 0 0 .192-.474.647.647 0 0 0-.192-.476A.647.647 0 0 0 8 2a.645.645 0 0 0-.475.191.646.646 0 0 0-.192.476c0 .189.064.347.192.474A.643.643 0 0 0 8 3.333Z" />
			<path d="m14 10.667-.95.933L12 10.55v4.117h-1.334V10.55L9.6 11.6l-.934-.933L11.333 8 14 10.667Z" />
		</g>
	</svg>
);

export default ClipboardIcon;
