/** @format */

import React from "react";

const UploadIcon = (props) => {
	return (
		<svg
			{...props}
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.99996 2.5L13.3333 5.83333M9.99996 2.5V12.5V2.5ZM9.99996 2.5L6.66663 5.83333L9.99996 2.5Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.66663 14.167L2.18413 16.2378C2.27425 16.5984 2.48231 16.9185 2.77523 17.1472C3.06816 17.376 3.42913 17.5003 3.80079 17.5003H16.1991C16.5708 17.5003 16.9318 17.376 17.2247 17.1472C17.5176 16.9185 17.7257 16.5984 17.8158 16.2378L18.3333 14.167"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default UploadIcon;
