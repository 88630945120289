import {
	ACCOUNT_UPDATE,
	GET_USER_NAME_FAILED,
	GET_USER_NAME_FETCH,
	GET_USER_NAME_SUCCESS,
	LAST_UPDATED_TIME,
	LOGIN_FAILED,
	LOGIN_FETCH,
	LOGIN_SUCCESS,
	LOGOUT,
	RESET_PASSWORD_FAILED,
	RESET_PASSWORD_FETCH,
	RESET_PASSWORD_SUCCESS,
	SET_EDIT_ADMIN_INFO,
	SET_EDIT_USER_INFO,
	TOGGLE_RESEARCH_MODE,
} from "./userActionTypes";

const initialState = {
	loginInProgress: null,
	loggedOutManually: false,
	authUserError: false,
	user: JSON.parse(window.localStorage.getItem("user")) || {
		firstName: "",
		lastName: "",
		email: "",
	},
	editUserInfo: null,
	getUserNameInProgress: null,
	getUserNameError: null,
	resetPasswordInProgress: null,
	resetPasswordError: null,
	researchModeEnabled: false,
	lastUpdatedTime: false,
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_FETCH:
			return {...state, authAdminInProgress: true};
		case LOGIN_SUCCESS:
			return {
				...state,
				authAdminInProgress: false,
				user: action.payload,
				loggedOutManually: false,
			};
		case ACCOUNT_UPDATE:
			return {...state, user: action.payload};
		case LOGIN_FAILED:
			return {
				...state,
				authAdminError: action.payload,
				authAdminInProgress: false,
			};
		case GET_USER_NAME_FETCH:
			return {...state, getUserNameInProgress: true};
		case GET_USER_NAME_SUCCESS:
			return {
				...state,
				getUserNameInProgress: false,
				user: {...state.user, email: action.payload},
			};
		case GET_USER_NAME_FAILED:
			return {
				...state,
				getUserNameError: action.payload,
				getUserNameInProgress: false,
			};
		case RESET_PASSWORD_FETCH:
			return {...state, resetPasswordInProgress: true};
		case RESET_PASSWORD_SUCCESS:
			return {...state, resetPasswordInProgress: false};
		case RESET_PASSWORD_FAILED:
			return {
				...state,
				resetPasswordError: action.payload,
				resetPasswordInProgress: false,
			};
		case LOGOUT:
			return {...state, user: {}, loggedOutManually: true};
		case TOGGLE_RESEARCH_MODE:
			return {...state, researchModeEnabled: action.payload};
		case LAST_UPDATED_TIME:
			return {...state, lastUpdatedTime: action.payload};
		case SET_EDIT_USER_INFO:
			return {...state, editUserInfo: action.payload};
		case SET_EDIT_ADMIN_INFO:
			return {...state, editAdminInfo: action.payload};
		default:
			return state;
	}
};

export default userReducer;
