import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";
import routes from "./routes";
import "styles/Report/index.scss";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useClearStorage} from "hooks/useClearStorage";
import "./styles/font.scss";
import withAuth from "withAuth";
import CompanyLogoModal from "rs-components/Modals/CompanyLogoModal";
import PeopleLogoModal from "rs-components/Modals/PeopleLogoModal";

const PrivateRoute = ({component, isAuthenticated, ...rest}) => {
	const Component = useMemo(() => withAuth(component), [component]);
	const userInfo = useSelector((state) => state.user.user);
	const loggedOutManually = useSelector((state) => state.user.loggedOutManually);

	const getRouteComponent = (props) => {
		let routeComponent;
		const role = userInfo.role;

		const allowedToVisitRS = ["Admin", "Client", "RT User", "RT+MapX User"];

		if (isAuthenticated) {
			if (allowedToVisitRS.includes(role)) {
				routeComponent = <Component {...props} />;
			} else {
				routeComponent = <Redirect to={"/home"} />;
			}
		} else {
			const history = window.location.pathname;
			let redirectTo =
				!loggedOutManually && history ? `?redirectTo=${history}` : "";

			const isAccountPage = history === "/account";

			if (isAccountPage) {
				redirectTo = "";
			}

			routeComponent = <Redirect to={`/login${redirectTo}`} />;
		}

		return routeComponent;
	};

	return <Route {...rest} render={(props) => getRouteComponent(props)} />;
};

const getRoutes = (routesArr, isAuthenticated) => {
	return routesArr.map((route, i) => {
		let {component: Component, element, ...rest} = route;

		if (route.private) {
			return (
				<PrivateRoute key={i} {...route} isAuthenticated={isAuthenticated} />
			);
		}

		if (element && !route.private) {
			return (
				<Route key={i} {...rest}>
					{element}
				</Route>
			);
		}

		return (
			<Route
				key={i}
				{...rest}
				render={(props) => (
					<main>
						<Component {...props} />
					</main>
				)}
			/>
		);
	});
};

const AppContainer = () => {
	const user = useSelector((state) => state.user);

	const isAuthenticated = user.user.email;
	useClearStorage();

	return (
		<div className={"calibriFont"}>
			<>
				<Switch>{getRoutes(routes, isAuthenticated)}</Switch>
				<ToastContainer closeOnClick hideProgressBar={false} />
				<CompanyLogoModal />
				<PeopleLogoModal />
			</>
		</div>
	);
};

export default AppContainer;
