/** @format */

import HttpClient from "../api";

class ProjectRequestsApi extends HttpClient {
	async saveProject(formData, config = {}) {
		return await this.doPost("/report/projects", formData, config);
	}

	async sendProjectInvite(formData, projectId, config = {}) {
		return await this.doPost(
			`/report/projects/${projectId}/invite`,
			formData,
			config,
		);
	}

	async getProjectInformation(projectId, config = {}) {
		return this.doGet(`/report/researcher/projects/${projectId}`, config);
	}

	async updateProject(formData, projectId, config = {}) {
		return await this.doPatch(
			`/report/researcher/projects/${projectId}`,
			formData,
			config,
		);
	}
}

const projectRequestsApi = new ProjectRequestsApi();

export default projectRequestsApi;
