/** @format */

import {isDate, isValidDatePeriod} from "../helpers/string";

const errorTexts = {
	dateErrorText: "Invalid date format!",
	compareErrorText: "To date should be greater than From date!",
};

const useDatePeriodValidation = () => {
	const datePeriodValidateByKey = (key, fromDate, toDate) => {
		const validationData = {
			[key]: {valid: undefined, valid_date_range: undefined},
		};

		// checking if from date is before to date
		const dateComparison = isValidDatePeriod(fromDate, toDate);

		// setting validation passed or failed on form validation
		if (key === "start_date" && dateComparison === "invalid_from_date") {
			validationData[key] = {...validationData[key], valid: false};
		} else if (key === "end_date" && dateComparison === "invalid_to_date") {
			validationData[key] = {...validationData[key], valid: false};
		} else if (dateComparison === "valid") {
			validationData[key] = {valid: true, valid_date_range: true};
		} else if (key === "start_date" && dateComparison === "invalid_to_date") {
			validationData[key] = {valid: true, valid_date_range: undefined};
		} else if (key === "end_date" && dateComparison === "invalid_from_date") {
			validationData[key] = {
				valid: isDate(toDate),
				valid_date_range: undefined,
			};
		} else if (dateComparison === "invalid") {
			validationData[key] = {valid: true, valid_date_range: false};
		}

		return validationData[key];
	};

	const dateErrorTextByKey = (dateValidationObject) => {
		if (
			!dateValidationObject ||
			(dateValidationObject.valid === undefined &&
				dateValidationObject.valid_date_range === undefined) ||
			(dateValidationObject.valid &&
				dateValidationObject.valid_date_range === undefined) ||
			(dateValidationObject.valid && dateValidationObject.valid_date_range)
		)
			return null;

		if (!dateValidationObject.valid) return errorTexts.dateErrorText;

		if (!dateValidationObject.valid_date_range) return errorTexts.compareErrorText;

		return null;
	};

	const didDateRangeValidationFailed = (dateRange, isBothDateRequired = false) => {
		return (
			dateRange.start_date === null ||
			(isBothDateRequired && dateRange.end_date === null) ||
			(dateRange.end_date !== null &&
				isValidDatePeriod(dateRange.start_date, dateRange.end_date) !== "valid")
		);
	};

	return {
		dateErrorTextByKey,
		datePeriodValidateByKey,
		didDateRangeValidationFailed,
	};
};

export default useDatePeriodValidation;
