import React, {useState} from "react";
import ReactMarkdown from "react-markdown";
import IconPencil from "../../assets/icons/IconPencil";
import SummaryModal from "./Modals/SummaryModal";

const SummarySection = ({
	summary,
	setSummary,
	researchMode = false,
	projectId,
	candidateId,
}) => {
	const [modalIsOpen, setIfModalIsOpen] = useState(false);
	const [displayedSummary, setDisplayedSummary] = useState(summary);

	const updateSummary = (data) => {
		setDisplayedSummary(data);
		setSummary(data);
	};

	return (
		<div>
			<div className="summary-title section-title">
				<span>Summary</span>

				{researchMode && (
					<IconPencil
						className="edit-icon cursor"
						color="#702C2E"
						onClick={() => setIfModalIsOpen(true)}
					/>
				)}
			</div>

			{summary?.length ? (
				<div className="section-summary">
					<div className="list-info">
						<ReactMarkdown>{summary}</ReactMarkdown>
					</div>
				</div>
			) : (
				"-"
			)}

			{modalIsOpen && (
				<SummaryModal
					displayModal={true}
					displayedSummary={displayedSummary}
					updateSummary={updateSummary}
					candidateId={candidateId}
					projectId={projectId}
					setModalDisplay={setIfModalIsOpen}
				/>
			)}
		</div>
	);
};

export default SummarySection;
