import React, {useCallback} from "react";
import classNames from "classnames";
import placeholder from "../../assets/images/empty_logo.jpg";
import css from "./styles.module.scss";
import {useDispatch} from "react-redux";
import {toggleCompanyLogoModal} from "store/modal/modalActions";

export const CompanyLogo = ({
	className = "",
	src,
	name,
	id,
	showUploadOnHover,
	...props
}) => {
	const dispatch = useDispatch();

	const handleLogoClick = useCallback(() => {
		if (showUploadOnHover) {
			dispatch(toggleCompanyLogoModal({name, id, logo: src}));
		}
	}, [dispatch, id, src, name, showUploadOnHover]);

	return (
		<div
			className={classNames(css.companyLogo, className, {
				[css.uploadable]: showUploadOnHover,
			})}
			onClick={handleLogoClick}
		>
			<img
				{...props}
				src={src || placeholder}
				onError={({currentTarget}) => {
					currentTarget.onerror = null;
					currentTarget.src = placeholder;
				}}
				alt={""}
			/>
		</div>
	);
};
