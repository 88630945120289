/** @format */

import React, {useEffect, useRef, useState} from "react";
import CloseIconBold from "../../../assets/icons/IconCloseBold";
import SelectComponent from "../../../components/Inputs/Select";
import TextInput from "../../../components/Inputs/TextInput";
import IconCloseRound from "../../../assets/icons/IconCloseRound";
import IconAdd from "../../../assets/icons/IconAdd";
import IconCaretRight from "../../../assets/icons/IconCaretRight";
import useModalHook from "../../../hooks/useModalHook";
import projectApi from "../../../api/report/project";
import {toast} from "react-toastify";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

const initialExtraPackageList = [
	"Local Salary",
	"Restricted Stock Awards",
	"Bonus",
	"Benefits",
	"Pension",
	"Local Package",
	"Notes",
];
const packageBaseInfo = {
	currency: {display_text: null, id: null},
	notes: null,
	total: null,
};
const initialPackageNames = ["Base", "LTIP", "Car"];
const initialPackageList = initialPackageNames.map((label, index) => {
	return {
		...packageBaseInfo,
		label,
		rank: index + 1,
	};
});

const initialPackageValidation = {
	currency: {valid: undefined},
};

const RemunerationModal = ({
	currencies,
	projectId,
	candidateId,
	packages,
	updatePackages,
	modalDisplay = true,
	setModalDisplay,
}) => {
	const {
		modalIsOpen,
		setIfModalIsOpen,
		customStyles,
		Modal,
		loading,
		setLoading,
		InlineLoaderComponent,
	} = useModalHook(
		{content: {width: "528px", maxHeight: "700px", overflow: "auto"}},
		modalDisplay,
	);
	const [formData, setFormData] = useState([...initialPackageList]);
	const [extraPackageListToAdd, setExtraPackageListToAdd] = useState([]);
	const [defaultCurrency, setDefaultCurrency] = useState(null);
	const [showPackageList, setShowPackageList] = useState(false);
	const [packagesValidation, setPackagesValidation] = useState([]);
	const [isSaveDisabled, setIfSaveDisabled] = useState(true);

	const ref = useRef();

	useOnClickOutside(ref, () => setShowPackageList(false));

	useEffect(() => {
		let validations = [];
		let data = [];
		let extraPackages = initialExtraPackageList;

		for (let i = 0; i < packages.length; i++) {
			const item = packages[i];
			const currency = {
				label: item.currency?.display_text,
				value: item.currency?.id,
			};

			data = [...data, {...item, currency}];
			validations = [...validations, {...initialPackageValidation}];
			extraPackages = extraPackages.filter((label) => item.label !== label);
		}

		setExtraPackageListToAdd(extraPackages);
		setFormData(data);
		setPackagesValidation(validations);
		setIfModalIsOpen(true);
	}, [packages, setIfModalIsOpen]);

	const handleClose = () => {
		setFormData(packages);
		setIfModalIsOpen(false);
		setPackagesValidation([]);

		setTimeout(() => {
			setModalDisplay(false);
		}, 1000);
	};

	const handleInputChange = (value, key, dataIndex) => {
		let updatedData = formData;

		if (value === null || value === "") {
			value = null;
		}
		updatedData[dataIndex][key] = value;

		setFormData(updatedData);
		validationChecker(updatedData);
	};

	const validationChecker = (updatedData) => {
		let isValid;

		for (let item of updatedData) {
			if (item.currency === null || item.currency?.id === null) {
				isValid = false;
				break;
			}

			isValid = true;
		}

		setIfSaveDisabled(!isValid);
	};

	const addPackageToPackageList = (label) => {
		let currency = {label: null, value: null};
		let validationData = initialPackageValidation;

		if (defaultCurrency) {
			currency = defaultCurrency;
			validationData = {
				currency: {valid: true},
			};
		}
		setPackagesValidation([...packagesValidation, {...validationData}]);
		setFormData([
			...formData,
			{
				...packageBaseInfo,
				label,
				currency,
				rank: formData.length + 1,
			},
		]);
		setShowPackageList(false);
	};

	const save = async () => {
		setLoading(true);
		const packageData = [...formData].map((item) => {
			const currency = {
				display_text: item.currency.label,
				id: item.currency.value,
			};
			const total = parseInt(item.total);

			return {...item, currency, total};
		});

		await projectApi
			.saveCandidateInfo(projectId, candidateId, {
				remuneration_packages: packageData,
			})
			.then((response) => {
				if (response.status >= 200 && response.status < 205) {
					toast.success("Saved Successfully");
					updatePackages(response.data.remuneration_packages);

					setTimeout(() => {
						setModalDisplay(false);
					}, 1000);
				} else {
					toast.error("Something went wrong!");
					console.log(response.data);
				}
			})
			.catch((error) => console.log(error));

		setIfModalIsOpen(false);
		setPackagesValidation([]);
		setFormData(packageData);
		setLoading(false);
	};

	return (
		<div>
			<Modal
				isOpen={modalIsOpen}
				style={customStyles}
				contentLabel="Remuneration Modal"
			>
				<div
					className="reports-modal remuneration-modal"
					style={{width: "auto"}}
				>
					<div className="modal-title">
						<span className="title">Remuneration</span>
						<CloseIconBold
							className="close-icon cursor"
							onClick={handleClose}
						/>
					</div>

					<div className="content input-wrapper">
						<div className="section-row">
							<label htmlFor="">Default Currency</label>

							<div className="row">
								<div className="col input-wrapper currency">
									<SelectComponent
										customClass="dropdown"
										customStyle={{width: "100%"}}
										borderColor="#dedede"
										options={currencies}
										onChange={(currency, triggerAction) => {
											if (triggerAction.action === "clear") {
												setDefaultCurrency(null);
											} else {
												setDefaultCurrency(currency);
											}

											for (let i = 0; i < formData.length; i++) {
												handleInputChange(
													currency,
													"currency",
													i,
												);
											}
										}}
										altDropdownIndicator={true}
									/>
								</div>
							</div>
						</div>

						{formData.map((packageItem, packageIndex) => (
							<div className="section-row" key={`${packageIndex}`}>
								<label htmlFor="">{packageItem.label} *</label>

								<div className="row">
									<div className="col input-wrapper">
										<TextInput
											customClass="custom-input"
											defaultValue={packageItem.total || null}
											onChange={(e) =>
												handleInputChange(
													e.target.value,
													"total",
													packageIndex,
												)
											}
											type="number"
										/>
									</div>

									<div className="col input-wrapper currency">
										<SelectComponent
											key={
												packageIndex +
												(packageItem?.currency?.value !== null
													? packageItem?.currency?.value
													: defaultCurrency?.value)
											}
											customClass="dropdown"
											customStyle={{width: "100%"}}
											borderColor="#dedede"
											options={currencies}
											defaultValue={
												packageItem.currency?.value !== null
													? packageItem.currency
													: defaultCurrency
											}
											onChange={(currency, triggerAction) => {
												if (triggerAction.action === "clear") {
													handleInputChange(
														null,
														"currency",
														packageIndex,
													);
												} else {
													handleInputChange(
														currency,
														"currency",
														packageIndex,
													);
												}
											}}
											errorText={
												packagesValidation.length &&
												!packagesValidation[packageIndex]
													.currency.valid &&
												packagesValidation[packageIndex]
													.currency.valid !== undefined
													? "Required"
													: null
											}
											altDropdownIndicator={true}
										/>
									</div>
								</div>

								<div className="row">
									<div className="col input-wrapper">
										<TextInput
											customClass="custom-input"
											defaultValue={packageItem.notes}
											onChange={(e) =>
												handleInputChange(
													e.target.value,
													"notes",
													packageIndex,
												)
											}
										/>
									</div>
								</div>
							</div>
						))}

						<div className="buttons">
							<div
								className="add-package-icon-area"
								style={{position: "relative"}}
								ref={ref}
							>
								{!!extraPackageListToAdd.length &&
									(showPackageList ? (
										<IconCloseRound
											className="cursor"
											onClick={() =>
												setShowPackageList(
													(showOptions) => !showOptions,
												)
											}
										/>
									) : (
										<IconAdd
											width={28}
											height={28}
											isLarge={true}
											className="cursor"
											onClick={() =>
												setShowPackageList(
													(showOptions) => !showOptions,
												)
											}
										/>
									))}

								{!!extraPackageListToAdd.length && (
									<ul
										className={[
											"options-as-list",
											showPackageList
												? "show remuneration-list"
												: "hide",
										].join(" ")}
									>
										<li className="font-bold">Add Package Name</li>
										{extraPackageListToAdd.map((label, i) => (
											<li
												key={i}
												onClick={() =>
													addPackageToPackageList(label)
												}
											>
												{label}
												<IconCaretRight />
											</li>
										))}
									</ul>
								)}
							</div>

							<div className="action-buttons">
								<button
									className="btn btn-white cursor"
									onClick={handleClose}
								>
									Cancel
								</button>

								<button
									className={[
										"btn",
										loading || isSaveDisabled
											? "btn-white btn-disabled"
											: "btn-red",
									].join(" ")}
									disabled={loading || isSaveDisabled}
									onClick={save}
								>
									{loading ? (
										<>Saving {InlineLoaderComponent}</>
									) : (
										"Save Changes"
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default RemunerationModal;
