/** @format */

import React from "react";

const IconNotification = ({color = "white"}) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.75 6.5C2.75 3.04822 5.54822 0.25 9 0.25C12.4518 0.25 15.25 3.04822 15.25 6.5V10.25H15.875C16.9105 10.25 17.75 11.0895 17.75 12.125C17.75 13.1605 16.9105 14 15.875 14H2.125C1.08947 14 0.25 13.1605 0.25 12.125C0.25 11.0895 1.08947 10.25 2.125 10.25H2.75V6.5ZM9 17.75C10.3807 17.75 11.5 16.6307 11.5 15.25H6.5C6.5 16.6307 7.61929 17.75 9 17.75Z"
				fill={color}
			/>
		</svg>
	);
};

export default IconNotification;
