import React, {useState} from "react";
import CheckboxWithLabel from "../../components/Inputs/CheckboxWithLabel";
import {useSelector} from "react-redux";

const ProjectSettings = ({handleInputChange}) => {
	const initialFormData = useSelector((state) => state.projectForm);
	const [formData, setFormData] = useState({
		...initialFormData?.form.settings,
	});

	const handleChangedData = (key, value) => {
		const data = {...formData, [key]: value};

		setFormData(data);
		handleInputChange({settings: data});
	};

	return (
		<div className="project-settings-form">
			<div className="title">Settings</div>

			<CheckboxWithLabel
				label="Show only highlighted candidates"
				key="show_only_for_discussion"
				checked={formData.show_only_for_discussion}
				onChange={() =>
					handleChangedData(
						"show_only_for_discussion",
						!formData.show_only_for_discussion,
					)
				}
			/>

			<CheckboxWithLabel
				label="Hide Remuneration"
				checked={!formData.show_remuneration}
				onChange={() =>
					handleChangedData("show_remuneration", !formData.show_remuneration)
				}
			/>

			<CheckboxWithLabel
				label="Hide Gender Stats"
				checked={!formData.show_gender_stats}
				onChange={() =>
					handleChangedData("show_gender_stats", !formData.show_gender_stats)
				}
			/>

			<CheckboxWithLabel
				label="Hide LinkedIn Links"
				checked={!formData.show_linkedin_urls}
				onChange={() =>
					handleChangedData(
						"show_linkedin_urls",
						!formData.show_linkedin_urls,
					)
				}
			/>

			<CheckboxWithLabel
				label="Hide Profile Photos"
				checked={!formData.show_candidate_avatars}
				onChange={() =>
					handleChangedData(
						"show_candidate_avatars",
						!formData.show_candidate_avatars,
					)
				}
			/>

			<CheckboxWithLabel
				label="Hide Researcher Fit to Brief scores"
				checked={!formData.show_candidate_fit_to_briefs}
				onChange={() =>
					handleChangedData(
						"show_candidate_fit_to_briefs",
						!formData.show_candidate_fit_to_briefs,
					)
				}
			/>
		</div>
	);
};

export default ProjectSettings;
