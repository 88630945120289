import * as React from "react";

const DragHandle = (props) => {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<mask
				id="prefix__a"
				style={{
					maskType: "alpha",
				}}
				maskUnits="userSpaceOnUse"
				x={0}
				y={0}
				width={20}
				height={20}
			>
				<path fill="#D9D9D9" d="M0 0h20v20H0z" />
			</mask>
			<g mask="url(#prefix__a)">
				<path
					d="M7.5 16.667c-.458 0-.85-.163-1.177-.49A1.605 1.605 0 015.833 15c0-.458.164-.85.49-1.178.326-.326.719-.489 1.177-.489.458 0 .85.163 1.177.49.327.326.49.719.49 1.177 0 .458-.163.85-.49 1.177-.326.327-.719.49-1.177.49zm5 0c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 01-.49-1.177c0-.458.164-.85.49-1.178.326-.326.719-.489 1.177-.489.458 0 .85.163 1.177.49.327.326.49.719.49 1.177 0 .458-.163.85-.49 1.177-.326.327-.719.49-1.177.49zm-5-5c-.458 0-.85-.164-1.177-.49A1.603 1.603 0 015.833 10c0-.458.164-.85.49-1.177.326-.327.719-.49 1.177-.49.458 0 .85.163 1.177.49.327.326.49.719.49 1.177 0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49zm5 0c-.458 0-.85-.164-1.177-.49a1.603 1.603 0 01-.49-1.177c0-.458.164-.85.49-1.177.326-.327.719-.49 1.177-.49.458 0 .85.163 1.177.49.327.326.49.719.49 1.177 0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49zm-5-5c-.458 0-.85-.164-1.177-.49A1.603 1.603 0 015.833 5c0-.458.164-.85.49-1.177.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.326.49.719.49 1.177 0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49zm5 0c-.458 0-.85-.164-1.177-.49A1.603 1.603 0 0110.833 5c0-.458.164-.85.49-1.177.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.327.326.49.719.49 1.177 0 .458-.163.85-.49 1.177-.326.326-.719.49-1.177.49z"
					fill="#999"
				/>
			</g>
		</svg>
	);
};

export default DragHandle;
