import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import IconCaretLeft from "../assets/icons/IconCaretLeft";
import {useSelector} from "react-redux";
import {CompanyLogo} from "rs-components/CompanyLogo/CompanyLogo";

const AssignmentInfoAsHeader = ({headerInfo, backCallback, isPrintPage = false}) => {
	const summaryData = useSelector((state) => state.project.summaryData);

	const researchMode = useSelector((state) => state.user.researchModeEnabled);

	return (
		<>
			{summaryData?.assignmentInfo && (
				<div
					className={classNames("project-header", {
						"text-only": !!headerInfo,
						"print-project-header": isPrintPage,
					})}
				>
					{!isPrintPage && (
						<div className="icon">
							{backCallback ? (
								<div onClick={backCallback}>
									<IconCaretLeft />
								</div>
							) : (
								<Link to="/home">
									<IconCaretLeft />
								</Link>
							)}
						</div>
					)}

					<div className="header-info">
						{headerInfo || (
							<>
								<span className="company">
									<CompanyLogo
										className="logo"
										src={summaryData.assignmentInfo.company.logo}
										name={summaryData.assignmentInfo.company.name}
										id={summaryData.assignmentInfo.company.id}
										alt=""
										loading="lazy"
										showUploadOnHover={researchMode}
									/>
									<span>
										{summaryData.assignmentInfo.company.name}
									</span>
								</span>

								<span className="project-title">
									{summaryData.assignmentInfo.assignment_name}
								</span>
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default AssignmentInfoAsHeader;
