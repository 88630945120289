import React, {useRef, useState} from "react";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";

import {Loader} from "components";
import projectApi from "api/report/project";
import styles from "./styles.module.scss";

export const ProgressNote = ({projectId, candidateId, comment, researchMode}) => {
	const initialComment = useRef(comment);
	const [editable, setEditable] = useState(false);
	const [inputValue, setInputValue] = useState(comment);
	const [loading, setLoading] = useState(false);

	const onCancel = () => {
		setEditable(false);
		setInputValue(initialComment.current);
	};

	const onSave = async () => {
		try {
			setLoading(true);
			await projectApi.saveCandidateInfo(projectId, candidateId, {
				status_info: {
					progress_note: inputValue,
				},
			});
			initialComment.current = inputValue;
			setLoading(false);
			setEditable(false);
		} catch (e) {
			setInputValue(comment);
			setLoading(false);
		}
	};

	return (
		<div>
			<div>
				<span className={styles.label}>Comment</span>
				<div
					className={classNames(styles.progressWrapper, {
						[styles.disabledWrapper]: !editable,
					})}
				>
					{editable ? (
						<textarea
							autoFocus
							className={classNames(styles.progressInput, {
								[styles.disabledInput]: !editable,
								[styles.default]: !researchMode,
							})}
							onChange={(ev) => setInputValue(ev.target.value)}
							onClick={() => (researchMode ? setEditable(true) : null)}
							placeholder="Write your comment."
							readOnly={!editable}
							rows={
								inputValue?.length ? inputValue.split("\n").length : 1
							}
							value={inputValue}
						/>
					) : (
						<div
							onClick={() => (researchMode ? setEditable(true) : null)}
							className={classNames(styles.markdownContainer, {
								[styles.researchMode]: researchMode,
							})}
						>
							{inputValue ? (
								<ReactMarkdown>{inputValue}</ReactMarkdown>
							) : (
								"Click here to add comment."
							)}
						</div>
					)}

					{editable ? (
						<div className={styles.actionGroup}>
							<span onClick={onCancel}>Cancel</span>
							{!loading ? (
								<span className={styles.save} onClick={onSave}>
									Save
								</span>
							) : (
								<Loader width={20} color="#2ba44f" height={20} />
							)}
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};
