/** @format */

import {useEffect} from "react";

const useAuthRedirect = (user, history, redirectUrl) => {
	useEffect(() => {
		if (user.email) history.push(redirectUrl);
	}, [history, user, redirectUrl]);

	return true;
};

export default useAuthRedirect;
