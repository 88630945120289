/** @format */

import React from "react";

const CloseIconBold = ({
	height = 16,
	width = 16,
	onClick,
	color = "#141414",
	...rest
}) => {
	return (
		<div onClick={onClick}>
			<svg
				width={width}
				height={height}
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...rest}
			>
				<path
					d="M15.8001 13.9201C15.9263 14.0453 15.9973 14.2157 15.9973 14.3935C15.9973 14.5712 15.9263 14.7416 15.8001 14.8668L14.8668 15.8001C14.7416 15.9263 14.5712 15.9973 14.3935 15.9973C14.2157 15.9973 14.0453 15.9263 13.9201 15.8001L8.00013 9.88013L2.08013 15.8001C1.95495 15.9263 1.78455 15.9973 1.6068 15.9973C1.42904 15.9973 1.25864 15.9263 1.13346 15.8001L0.200129 14.8668C0.0739201 14.7416 0.00292969 14.5712 0.00292969 14.3935C0.00292969 14.2157 0.0739201 14.0453 0.200129 13.9201L6.12013 8.00013L0.200129 2.08013C0.0739201 1.95495 0.00292969 1.78455 0.00292969 1.6068C0.00292969 1.42904 0.0739201 1.25864 0.200129 1.13346L1.13346 0.200129C1.25864 0.0739201 1.42904 0.00292969 1.6068 0.00292969C1.78455 0.00292969 1.95495 0.0739201 2.08013 0.200129L8.00013 6.12013L13.9201 0.200129C14.0453 0.0739201 14.2157 0.00292969 14.3935 0.00292969C14.5712 0.00292969 14.7416 0.0739201 14.8668 0.200129L15.8001 1.13346C15.9263 1.25864 15.9973 1.42904 15.9973 1.6068C15.9973 1.78455 15.9263 1.95495 15.8001 2.08013L9.88013 8.00013L15.8001 13.9201Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};

export default CloseIconBold;
