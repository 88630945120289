// Interviews Schedule
export const SET_INTERVIEWS = "SET_INTERVIEWS";
export const GET_INTERVIEWS = "GET_INTERVIEWS";
export const SET_ASSESSMENT_DATA = "SET_ASSESSMENT_DATA";
export const SET_CANDIDATES_DATA = "SET_CANDIDATES_DATA";
export const CLEAR_ASSESSMENT_DATA = "CLEAR_ASSESSMENT_DATA";
export const SET_SUMMARY_DATA = "SET_SUMMARY_DATA";
export const SET_GENDER_DATA = "SET_GENDER_DATA";
export const CLEAR_SUMMARY_DATA = "CLEAR_SUMMARY_DATA";
export const SET_ASSIGNMENTS = "SET_ASSIGNMENTS";
export const SET_FORM_DATA = "SET_FORM_DATA";
export const CLEAR_FORM = "CLEAR_FORM";
export const SET_CURRENCIES = "SET_CURRENCIES";
export const SET_PROGRESS_STATUS = "SET_PROGRESS_STATUS";
export const SET_NOTICE_PERIODS = "SET_NOTICE_PERIODS";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_COMPANY_LOGO = "SET_COMPANY_LOGO";
export const SET_PEOPLE_LOGO = "SET_PEOPLE_LOGO";
export const TOGGLE_COMPANY_LOGO_MODAL = "TOGGLE_COMPANY_LOGO_MODAL";
export const TOGGLE_PEOPLE_LOGO_MODAL = "TOGGLE_PEOPLE_LOGO_MODAL";
export const UPDATE_CANDIDATE_ORDERING_INIT = "UPDATE_CANDIDATE_ORDERING_INIT";
export const UPDATE_CANDIDATE_ORDERING_DONE = "UPDATE_CANDIDATE_ORDERING_DONE";
export const UPDATE_CANDIDATE_ORDERING_FAIL = "UPDATE_CANDIDATE_ORDERING_FAIL";
