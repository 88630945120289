import React from "react";

const NotFoundIcon = (props) => (
	<svg
		width={338}
		height={239}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 338 239"
		{...props}
	>
		<path
			d="M131.568 119.4h9.1v15h-9.1V150h-18.2v-15.6h-36.6v-12.5l29.1-47.3h25.7v44.8Zm-18.2 0v-29h-.2l-17.6 29h17.8Zm64.655-47.8c10.466.067 18.233 3.933 23.3 11.6 5.133 7.667 8 16.1 8.6 25.3v2c.066.6.1 1.233.1 1.9 0 .667-.034 1.333-.1 2v1.9c-.6 9.2-3.434 17.633-8.5 25.3-5.067 7.6-12.867 11.4-23.4 11.4-10.534 0-18.334-3.8-23.4-11.4-5.067-7.667-7.9-16.1-8.5-25.3 0-.667-.034-1.3-.1-1.9v-3.9c.066-.667.1-1.333.1-2 .6-9.2 3.433-17.633 8.5-25.3 5.133-7.667 12.933-11.5 23.4-11.5v-.1Zm0 17.3c-3.267.067-5.8 1.5-7.6 4.3-1.734 2.8-3 5.867-3.8 9.2-.4 1.867-.7 3.667-.9 5.4a59.412 59.412 0 0 0-.2 4.5c0 1.267.066 2.8.2 4.6.2 1.733.5 3.533.9 5.4.8 3.4 2.066 6.5 3.8 9.3 1.8 2.8 4.333 4.2 7.6 4.2 3.266 0 5.766-1.4 7.5-4.2 1.8-2.8 3.1-5.9 3.9-9.3.4-1.867.666-3.667.8-5.4.2-1.8.3-3.333.3-4.6 0-1.267-.1-2.767-.3-4.5a40.819 40.819 0 0 0-.8-5.4c-.8-3.333-2.1-6.4-3.9-9.2-1.734-2.8-4.234-4.2-7.5-4.2v-.1Zm90.654 30.5h9.1v15h-9.1V150h-18.2v-15.6h-36.6v-12.5l29.1-47.3h25.7v44.8Zm-18.2 0v-29h-.2l-17.6 29h17.8Z"
			fill="#EEE"
		/>
		<rect
			x={19.5}
			y={1.5}
			width={317}
			height={207}
			rx={14.5}
			stroke="#F3DFDF"
			strokeWidth={3}
		/>
		<path stroke="#F3DFDF" strokeWidth={3} d="M20 27.5h317" />
		<path
			d="M39.012 15.926a3.577 3.577 0 0 0-7.061-1.146 3.577 3.577 0 0 0 7.061 1.146ZM53.345 16.15a3.577 3.577 0 0 0-6.962-1.643 3.577 3.577 0 0 0 6.962 1.643ZM67.766 15.917a3.577 3.577 0 0 0-7.061-1.146 3.577 3.577 0 0 0 7.061 1.146Z"
			fill="#F3DFDF"
		/>
		<path
			opacity={0.1}
			d="M33.283 159.459c-2.248-11.511 5.264-22.66 16.779-24.904 11.515-2.243 22.671 5.27 24.919 16.781 2.248 11.511-5.265 22.661-16.78 24.904-11.514 2.243-22.67-5.27-24.918-16.781Z"
			fill="#fff"
		/>
		<path
			d="M43.825 144.68c.713.263 1.555.049 2.027-.587a9.385 9.385 0 0 1 6.28-3.594c2.501-.317 4.97.354 6.99 1.86.773.619 1.923.471 2.5-.317.618-.773.47-1.923-.319-2.5a13.124 13.124 0 0 0-9.668-2.608 12.866 12.866 0 0 0-8.659 5.004c-.618.772-.47 1.923.319 2.499.21.077.362.181.53.243Z"
			fill="#C6C6C6"
		/>
		<path
			d="M69.6 135.262c11.138 8.582 13.232 24.503 4.652 35.633-8.566 11.089-24.492 13.178-35.645 4.639-11.096-8.566-13.19-24.487-4.652-35.633 8.58-11.131 24.549-13.204 35.645-4.639Zm-28.427 36.929c9.286 7.137 22.64 5.435 29.776-3.846 7.137-9.281 5.414-22.588-3.873-29.725-9.287-7.137-22.598-5.419-29.735 3.862-7.178 9.266-5.455 22.572 3.831 29.709Z"
			fill="#C6C6C6"
		/>
		<path
			d="m37.359 173.517 3.51 2.7-12.27 15.942-3.51-2.699 12.27-15.943Z"
			fill="#C6C6C6"
		/>
		<path
			d="M4.84 219.452c2.224 1.724 5.436 1.288 7.16-.935l18.86-24.542a2.716 2.716 0 0 0-.504-3.807l-3.787-2.919a2.717 2.717 0 0 0-3.807.503l-18.86 24.542a5.075 5.075 0 0 0 .938 7.158Z"
			fill="url(#NotFound_svg__a)"
		/>
		<defs>
			<linearGradient
				id="NotFound_svg__a"
				x1={29.996}
				y1={186.741}
				x2={10.787}
				y2={211.716}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#CF5858" />
				<stop offset={0.973} stopColor="#A14545" />
			</linearGradient>
		</defs>
	</svg>
);

export default NotFoundIcon;
