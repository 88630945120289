/** @format */

import React, {useEffect, useState} from "react";
import IconPencil from "../../assets/icons/IconPencil";
import {moneyFormat} from "helpers/string";
import RemunerationModal from "./Modals/RemunerationModal";

const RemunerationSection = ({
	currencies = [],
	remuneration,
	projectId,
	candidateId,
	isResearcher = false,
	isPrintPage = false,
}) => {
	const [modalDisplay, setModalDisplay] = useState(false);
	const {packages, organizePackageDataForResearcher} = getPackages(
		remuneration,
		isResearcher,
	);

	return (
		<div className="remuneration-section">
			<div className="section-title">
				Remuneration
				{isResearcher && !isPrintPage && (
					<IconPencil
						className="edit-icon cursor"
						color="#702C2E"
						onClick={() => setModalDisplay(true)}
					/>
				)}
			</div>

			{packages.length
				? packages.map((item, index) => (
						<div className="info" key={index}>
							<span className="label">{item.label}</span>
							<span className="values">
								{item.values.length
									? item.values.map((value, vI) => (
											<span key={vI}>{value}</span>
									  ))
									: "-"}
							</span>
						</div>
				  ))
				: "-"}

			{!isPrintPage && modalDisplay && (
				<RemunerationModal
					setModalDisplay={setModalDisplay}
					projectId={projectId}
					candidateId={candidateId}
					currencies={currencies}
					packages={remuneration}
					updatePackages={organizePackageDataForResearcher}
				/>
			)}
		</div>
	);
};

export default RemunerationSection;

export const getPackages = (remuneration, isResearcher) => {
	const [packages, setPackages] = useState([]);

	const organizePackageDataForResearcher = (remunerationData) => {
		const data = remunerationData.filter(
			(item) => item.notes !== null || item.total !== null,
		);

		if (data.length) {
			const packagesData = data.map((packageData) => {
				const valueFormat =
					packageData.total !== undefined &&
					packageData.total !== null &&
					packageData.currency?.display_text !== undefined
						? moneyFormat(
								packageData.total,
								packageData.currency.display_text,
						  )
						: "-";

				return {
					label: packageData.label,
					values: [valueFormat, packageData.notes],
				};
			});

			setPackages(packagesData);
		}
	};

	useEffect(() => {
		if (isResearcher) {
			organizePackageDataForResearcher(remuneration);
		} else {
			setPackages(remuneration);
		}
	}, [remuneration, isResearcher]);

	return {packages, organizePackageDataForResearcher};
};
