import React from "react";

const IconPrint = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_4472_30302"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_4472_30302)">
				<path
					d="M15.9998 8.29999V5.29999H7.9998V8.29999H6.4998V3.79999H17.4998V8.29999H15.9998ZM17.7998 12.3C18.0831 12.3 18.3208 12.204 18.5128 12.012C18.7041 11.8207 18.7998 11.5833 18.7998 11.3C18.7998 11.0167 18.7041 10.779 18.5128 10.587C18.3208 10.3957 18.0831 10.3 17.7998 10.3C17.5165 10.3 17.2788 10.3957 17.0868 10.587C16.8955 10.779 16.7998 11.0167 16.7998 11.3C16.7998 11.5833 16.8955 11.8207 17.0868 12.012C17.2788 12.204 17.5165 12.3 17.7998 12.3ZM15.9998 19V14.725H7.9998V19H15.9998ZM17.4998 20.5H6.4998V16.5H2.7998V10.8C2.7998 10.1 3.04147 9.50832 3.5248 9.02499C4.00814 8.54165 4.5998 8.29999 5.2998 8.29999H18.6998C19.4165 8.29999 20.0125 8.54165 20.4878 9.02499C20.9625 9.50832 21.1998 10.1 21.1998 10.8V16.5H17.4998V20.5ZM19.6998 15V10.8C19.6998 10.5167 19.6041 10.279 19.4128 10.087C19.2208 9.89565 18.9831 9.79999 18.6998 9.79999H5.2998C5.01647 9.79999 4.77914 9.89565 4.5878 10.087C4.3958 10.279 4.2998 10.5167 4.2998 10.8V15H6.4998V13.225H17.4998V15H19.6998Z"
					fill="#1C1B1F"
				/>
			</g>
		</svg>
	);
};

export default IconPrint;
