import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import useModalHook from "hooks/useModalHook";
import * as colors from "styles/themes.module.scss";
import css from "./index.module.scss";
import CloseIconBold from "assets/icons/IconCloseBold";
import {toggleCompanyLogoModal} from "store/modal/modalActions";
import {TextInput} from "components";
import UploadIcon from "assets/icons/UploadIcon";
import ClipboardIcon from "assets/icons/ClipboardIcon";
import LogoPreview from "assets/images/logo-preview.svg";
import companyApi from "api/report/company";
import IconPlay from "assets/icons/PlayIcon";
import useWindowSize from "hooks/useWindowSize";
import {setCompanyLogo} from "store/project/projectActions";

const BREAK_POINT = 470;

const CompanyLogoModal = () => {
	const dispatch = useDispatch();

	const hiddenFileInput = useRef(null);

	const previewRef = useRef(null);

	const hiddenPreviewRef = useRef(null);

	const [loading, setLoading] = useState(false);

	const [loadingUrl, setLoadingUrl] = useState(false);

	const [loadingRestore, setLoadingRestore] = useState(false);

	const [preview, setPreview] = useState(null);

	const [avatarFile, setAvatarFile] = useState(null);

	const [avatarUrl, setAvatarUrl] = useState(null);

	const [filename, setFilename] = useState("");

	const [fileValidation, setFileValidation] = useState("");

	const [urlValidation, setUrlValidation] = useState("");

	const [url, setUrl] = useState("");

	const isOpen = useSelector((state) => state.modal.companyLogoModal);

	const company = useSelector((state) => state.modal.companyLogoModalProps);

	const {width} = useWindowSize();

	const {customStyles, Modal, InlineLoaderComponent} = useModalHook(
		{content: {width: "450px"}},
		isOpen,
		colors.mainThemeColor,
	);

	const handleClose = useCallback(() => {
		dispatch(toggleCompanyLogoModal());

		// Reset modal states
		// TODO: Reset logic could be simplified with useReducer hook.
		setLoading(false);
		setLoadingUrl(false);
		setLoadingRestore(false);
		setPreview(null);
		setAvatarFile(null);
		setAvatarUrl(null);
		setFilename("");
		setFileValidation("");
		setUrlValidation("");
		setUrl("");
	}, [dispatch]);

	const handleUploadFromUrlChange = useCallback((e) => {
		setUrl(e.target.value);
	}, []);

	const handleUploadFromFileChange = useCallback(() => {}, []);

	const handleUploadFromFileClick = useCallback(() => {
		hiddenFileInput.current.click();
	}, []);

	const handleImageSelect = useCallback((e) => {
		const files = e.target.files;

		if (files && files.length > 0) {
			const [file] = files;

			setFileValidation("");
			setPreview(URL.createObjectURL(file));
			setFilename(file.name);
			setAvatarFile(file);
		}
	}, []);

	const handleClipboardAccess = useCallback(async () => {
		try {
			setFileValidation("");

			if (!navigator.clipboard) {
				throw new Error("Clipboard not supported on this browser.");
			}

			if (navigator.permissions) {
				const permission = await navigator.permissions.query({
					name: "clipboard-read",
				});
				if (permission.state === "denied") {
					throw new Error("Not allowed to read clipboard.");
				}
			}

			const clipboardContents = await navigator.clipboard.read();
			for (const item of clipboardContents) {
				if (!item.types.includes("image/png")) {
					throw new Error("Clipboard contains non-image data.");
				}

				const blob = await item.getType("image/png");
				previewRef.current.src = URL.createObjectURL(blob);

				setPreview(URL.createObjectURL(blob));
				setFilename("Clipboard image");
				setAvatarFile(blob);
			}
		} catch (error) {
			setFileValidation(error.message);
		}
	}, []);

	const handleUploadFromUrlClick = useCallback(() => {
		setLoadingUrl(true);

		hiddenPreviewRef.current.src = url;
	}, [url]);

	const handleRestoreClick = useCallback(() => {
		if (company) {
			setLoadingRestore(true);

			companyApi
				.restoreLogo(company.id)
				.then(async (resp) => {
					await dispatch(setCompanyLogo({id: resp.id, logo: resp.logo}));

					window.location.reload();
				})
				.finally(() => {
					setLoadingRestore(false);
				});
		}
	}, [company, dispatch]);

	const handleSubmit = useCallback(() => {
		if (company && (avatarFile || avatarUrl)) {
			setLoading(true);

			const data = new FormData();
			avatarFile
				? data.append("logo", avatarFile)
				: data.append("logo_url", avatarUrl);

			// const logo = avatarFile ? URL.createObjectURL(avatarFile) : avatarUrl;

			companyApi
				.uploadLogo(company.id, data)
				.then(async (resp) => {
					if (resp.status === 200) {
						await dispatch(
							setCompanyLogo({id: resp.data.id, logo: resp.data.logo}),
						);

						window.location.reload();
					} else {
						const message = resp?.data?.detail || "Something went wrong.";
						avatarFile
							? setFileValidation(message)
							: setUrlValidation(message);
					}
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [avatarFile, avatarUrl, company, dispatch]);

	useEffect(() => {
		if (urlValidation) {
			setUrlValidation("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url]);

	useEffect(() => {
		if (avatarUrl) {
			setAvatarFile(null);
			setFilename("");
		}
	}, [avatarUrl]);

	useEffect(() => {
		if (avatarFile) {
			setAvatarUrl(null);
			setUrl("");
		}
	}, [avatarFile]);

	if (!company) return null;

	return (
		<Modal
			isOpen={isOpen}
			style={{
				...customStyles,
				content: {
					...customStyles.content,
					width: width <= BREAK_POINT ? 320 : 450,
				},
			}}
			contentLabel="Modal"
		>
			<div
				className={classNames("reports-modal", css.modal)}
				style={{width: "auto"}}
			>
				<div className={css.modalTitle}>
					<span className={css.title}>
						Change<span>{company.name}</span>Logo
					</span>
					<CloseIconBold
						className="close-icon cursor"
						onClick={handleClose}
						color="#999999"
						width={12}
						height={12}
					/>
				</div>
				<div className={css.content}>
					<div className={css.preview}>
						<img
							onClick={handleUploadFromFileClick}
							ref={previewRef}
							src={preview || company.logo}
							onError={() => {
								previewRef.current.src = LogoPreview;
							}}
							alt="Image preview"
						/>
						<img
							ref={hiddenPreviewRef}
							style={{
								position: "absolute",
								visibility: "hidden",
								zIndex: "-1",
							}}
							onLoad={() => {
								setPreview(hiddenPreviewRef.current.src);
								setLoadingUrl(false);
								setAvatarUrl(hiddenPreviewRef.current.src);
							}}
							onError={() => {
								setUrlValidation("Invalid image url.");
								setLoadingUrl(false);
							}}
						/>
					</div>
					<div className={css.row}>
						<input
							type="file"
							ref={hiddenFileInput}
							style={{display: "none"}}
							onChange={handleImageSelect}
							accept=".png,.jpeg,.gif"
						/>
						<TextInput
							label="Upload from a file"
							name="image-file"
							type="text"
							disabled
							placeholder="Select a file to upload"
							customClass={css.input}
							errorText={fileValidation}
							onChange={handleUploadFromFileChange}
							value={filename}
						/>
						<button
							onClick={handleUploadFromFileClick}
							className={classNames("btn btn-white", css.uploadButton, {
								[css.validation]: fileValidation,
								[css.noIconMargin]: width <= BREAK_POINT,
							})}
						>
							<UploadIcon />
							{width > BREAK_POINT && "Upload"}
						</button>
					</div>
					<div className={css.clipboard} onClick={handleClipboardAccess}>
						<ClipboardIcon />
						Upload from clipboard
					</div>
					<div className={css.row}>
						<TextInput
							label="Or Upload from a URL"
							name="image-file"
							type="text"
							placeholder="Add the file URL"
							customClass={css.input}
							errorText={urlValidation}
							onChange={handleUploadFromUrlChange}
							value={url}
						/>
						<button
							onClick={handleUploadFromUrlClick}
							className={classNames("btn btn-white", css.uploadButton, {
								[css.validation]: urlValidation,
								[css.noIconMargin]: width <= BREAK_POINT,
							})}
						>
							<UploadIcon />
							{loadingUrl && (
								<span className={css.absolute}>
									{InlineLoaderComponent}
								</span>
							)}
							{width > BREAK_POINT && (
								<span className={classNames({[css.hide]: loadingUrl})}>
									Upload
								</span>
							)}
						</button>
					</div>
					<button
						onClick={handleSubmit}
						className={classNames("btn btn-red", css.submit)}
						disabled={loading || (!avatarFile && !avatarUrl)}
					>
						{loading ? InlineLoaderComponent : "Save Changes"}
					</button>
					<div className={css.restoreContainer}>
						<div className={css.restore} onClick={handleRestoreClick}>
							{loadingRestore ? (
								InlineLoaderComponent
							) : (
								<>
									<IconPlay />
									Restore original from Invenias
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default CompanyLogoModal;
