import React from "react";

export const LeftArrow = () => (
	<svg
		width="12"
		height="12"
		viewBox="0 0 7 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.66602 10L0.666016 5L5.66602 0L6.83268 1.16667L2.99935 5L6.83268 8.83333L5.66602 10Z"
			fill="#999999"
		/>
	</svg>
);
