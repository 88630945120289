/** @format */

import React, {useMemo} from "react";
import "react-day-picker/lib/style.css";
import {format} from "date-fns";
import {getShortName} from "../../helpers/string";

const InterviewScheduleWithTimelineMobile = ({interviews = [], toggle}) => {
	const upcomingInterviews = useMemo(
		() =>
			interviews.filter(
				(item) => new Date(item.date).getTime() > new Date().getTime(),
			),
		[interviews],
	);

	const previousInterviews = useMemo(
		() =>
			interviews.filter(
				(item) => new Date(item.date).getTime() < new Date().getTime(),
			),
		[interviews],
	);

	if (!toggle) return null;

	return (
		<div>
			<>
				{upcomingInterviews.length > 0 ? (
					<div className="interview-schedule-wrapper-mobile upcoming">
						{upcomingInterviews.map((interview, index) => (
							<div className="row" key={index}>
								<div className="date-column">
									<span className="date">
										{format(
											new Date(interview.date),
											"d ccc / HH:mm",
										)}
									</span>
									<div className="interview-title">
										{interview.title}
									</div>
								</div>
								<div className="info-columns">
									<div className="candidate-column">
										<div className="candidate-avatar">
											{/*<img src={interview.candidate_avatar} alt=""/>*/}
											<div className="name-as-avatar">
												{getShortName(interview.candidate_name)}
											</div>
											<div className="candidate-name">
												{interview.candidate_name}
											</div>
										</div>
									</div>
									<div className="interviewer-column">
										<div className="info">
											<div className="label">Role</div>
											<div
												className="value"
												title={interview.candidate_role}
											>
												{interview.candidate_role
													? interview.candidate_role.length >
													  50
														? interview.candidate_role.substr(
																0,
																50,
														  ) + "..."
														: interview.candidate_role
													: "-"}
											</div>
										</div>
										<div className="info">
											<div className="label">Company</div>
											<div className="value">
												{interview.candidate_company}
											</div>
										</div>
										<div className="info">
											<div className="label">Interviewer</div>
											<div className="value">
												{interview.interviewers.join(", ")}
											</div>
										</div>
										<div className="info">
											<div className="label">Location</div>
											<div className="value">
												{interview.location || "-"}
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<span className="candidates-card-wrapper" style={{padding: "40px"}}>
						There currently aren&apos;t any upcoming interviews.
					</span>
				)}
			</>
			<>
				<div className="project-header text-only">
					<div className="header-info">Previous Interviews</div>
				</div>
				{previousInterviews.length > 0 ? (
					<div className="interview-schedule-wrapper-mobile previous">
						{previousInterviews.map((interview, index) => (
							<div className="row" key={index}>
								<div className="fade" />
								<div className="date-column">
									<span className="date">
										{format(
											new Date(interview.date),
											"d ccc / HH:mm",
										)}
									</span>
									<div className="interview-title">
										{interview.title}
									</div>
								</div>
								<div className="info-columns">
									<div className="candidate-column">
										<div className="candidate-avatar">
											{/*<img src={interview.candidate_avatar} alt=""/>*/}
											<div className="name-as-avatar">
												{getShortName(interview.candidate_name)}
											</div>
											<div className="candidate-name">
												{interview.candidate_name}
											</div>
										</div>
									</div>
									<div className="interviewer-column">
										<div className="info">
											<div className="label">Role</div>
											<div
												className="value"
												title={interview.candidate_role}
											>
												{interview.candidate_role
													? interview.candidate_role.length >
													  50
														? interview.candidate_role.substr(
																0,
																50,
														  ) + "..."
														: interview.candidate_role
													: "-"}
											</div>
										</div>
										<div className="info">
											<div className="label">Company</div>
											<div className="value">
												{interview.candidate_company}
											</div>
										</div>
										<div className="info">
											<div className="label">Interviewer</div>
											<div className="value">
												{interview.interviewers.join(", ")}
											</div>
										</div>
										<div className="info">
											<div className="label">Location</div>
											<div className="value">
												{interview.location || "-"}
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<span className="candidates-card-wrapper" style={{padding: "40px"}}>
						There aren&apos;t any previous interviews.
					</span>
				)}
			</>
		</div>
	);
};

export default InterviewScheduleWithTimelineMobile;
