/** @format */

import React from "react";
import {Doughnut} from "react-chartjs-2";

const CustomDoughnut = ({
	labels,
	backgroundColors,
	borderColors,
	values,
	labelColor,
}) => {
	const data = {
		labels: labels,
		datasets: [
			{
				label: "",
				data: values,
				backgroundColor: backgroundColors,
				borderColor: borderColors,
				borderWidth: 1,
			},
		],
	};

	const options = {
		rotation: -0.5 * Math.PI - (50 / 180) * Math.PI,
		cutoutPercentage: 65,
		responsive: true,
		legend: {
			display: false,
			position: "right",
			align: "middle",
			labels: {
				usePointStyle: true,
			},
		},
		tooltips: {
			mode: "index",
			intersect: false,
			callbacks: {
				label: function (tooltipItem, data) {
					const label = data.labels[tooltipItem.index];
					const datasetLabel =
						data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

					return label + ": " + datasetLabel.toLocaleString() + "%";
				},
			},
		},
		plugins: {
			labels: false,
		},
	};

	return (
		<div className="chart-wrapper" style={{padding: 0}}>
			<div className="chart">
				<Doughnut data={data} height={176} options={options} />
			</div>
			<div className="legend">
				{labels.map((label, index) => (
					<div className="item" key={index}>
						<div className="label" style={{color: labelColor}}>
							<span
								className="dot"
								style={{background: backgroundColors[index]}}
							/>{" "}
							{label}
						</div>
						<span className="info" style={{color: labelColor}}>
							{values[index]}%
						</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default CustomDoughnut;
