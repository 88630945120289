/** @format */

import React, {useEffect, useState} from "react";
import IconPencil from "../../assets/icons/IconPencil";
import MarketInsightModal from "./Modals/MarketInsightModal";

const MarketInsightSection = ({researcherMode, marketInsights, projectId}) => {
	const [displayedMarketInsights, setDisplayedMarketInsights] = useState([
		...marketInsights,
	]);
	const [modalDisplay, setModalDisplay] = useState(false);

	useEffect(() => {
		setDisplayedMarketInsights(marketInsights);
	}, [marketInsights]);

	return (
		<div className="market-insight-card-wrapper">
			<div className="section-title">
				<span>Market Insight</span>

				{researcherMode && (
					<span>
						<IconPencil
							className="cursor edit-icon"
							onClick={() => setModalDisplay(true)}
						/>
					</span>
				)}
			</div>

			{displayedMarketInsights?.length ? (
				displayedMarketInsights.map((insight, mI) => (
					<div className="market-insight-card" key={mI}>
						<div className="block-title">{insight.heading}</div>
						<div className="description">{insight.description}</div>
					</div>
				))
			) : (
				<div className="market-insight-card">-</div>
			)}

			{modalDisplay && (
				<MarketInsightModal
					projectId={projectId}
					setModalDisplay={setModalDisplay}
					marketInsights={displayedMarketInsights}
					updateMarketInsights={setDisplayedMarketInsights}
				/>
			)}
		</div>
	);
};

export default MarketInsightSection;
