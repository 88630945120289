import React from "react";
import SimpleBreadcrumb from "rs-components/SimpleBreadcrumb";
import IconPrint from "assets/icons/IconPrint";

const FilterSection = ({
	breadcrumbOptions,
	shouldPrint = true,
	handlePrintClick,
	printPageLoading = false,
	printLabelText,
}) => {
	return (
		<div className="filter-options">
			<div className="left-block-filters">
				{!!breadcrumbOptions.length && (
					<SimpleBreadcrumb options={breadcrumbOptions} />
				)}
			</div>

			{shouldPrint && (
				<div className="right-block-filters">
					<button
						className="btn btn-white"
						onClick={handlePrintClick}
						disabled={printPageLoading}
					>
						<IconPrint /> {printLabelText}
					</button>
					{/*<button className="btn btn-white">*/}
					{/*    <IconProfile/> Download Profile*/}
					{/*</button>*/}
				</div>
			)}
		</div>
	);
};

export default FilterSection;
