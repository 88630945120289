import HttpClient from "./api";

class SearchApi extends HttpClient {
	async searchUsers(query, config = {}) {
		let mode;

		if (/(@)/g.test(query)) {
			mode = "email";
		} else {
			mode = "name";
		}

		return this.doGet(
			`mapx/users?q=${encodeURIComponent(query)}&search_mode=${mode}`,
			config,
		);
	}
}

const search = new SearchApi();

export default search;
