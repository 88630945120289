import React, {useCallback, useEffect, useMemo, useState} from "react";
import CloseIconBold from "assets/icons/IconCloseBold";
import {getShortName} from "helpers/string";
import TextInput from "components/Inputs/TextInput";
import SelectComponent from "components/Inputs/Select";
import useModalHook from "hooks/useModalHook";
import projectApi from "api/report/project";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";

const initialValidation = {
	first_name: {valid: undefined},
	last_name: {valid: undefined},
};

const errorTexts = {
	firstNameErrorText: "Please enter first name",
	lastNameErrorText: "Please enter last name",
};

const PersonalInformationModal = ({
	modalDisplay = false,
	setModalDisplay,
	displayedCandidateData,
	updateDisplayedData,
	updateCandidateName,
	projectId,
	candidateId,
}) => {
	const candidateFormData = useMemo(() => {
		return {
			first_name: displayedCandidateData.first_name,
			last_name: displayedCandidateData.last_name,
			linked_in_url: displayedCandidateData.linked_in_url || null,
			invenias_url: displayedCandidateData.invenias_url || null,
			location: {
				city: displayedCandidateData.location?.city || null,
				country: displayedCandidateData.location?.country || null,
			},
		};
	}, [displayedCandidateData]);

	const {
		modalIsOpen,
		setIfModalIsOpen,
		customStyles,
		Modal,
		loading,
		setLoading,
		InlineLoaderComponent,
	} = useModalHook({content: {width: "520px"}}, modalDisplay);
	const [formValidation, setFormValidation] = useState({
		...initialValidation,
	});
	const [profileForm, setProfileForm] = useState({...candidateFormData});
	const [isSaveDisabled, setIfSaveDisabled] = useState(true);
	const countries = useSelector((state) => state.project?.countries);

	useEffect(() => {
		setProfileForm({...candidateFormData});
		validationChecker({...candidateFormData});
		setIfModalIsOpen(true);
	}, [candidateFormData, setIfModalIsOpen]);

	const countriesData = useCallback(() => {
		return countries?.length
			? countries.map((country) => {
					return {
						label: country.name,
						value: country.name,
					};
			  })
			: [];
	}, [countries]);

	const handleClose = () => {
		setProfileForm({...displayedCandidateData});
		setIfModalIsOpen(false);
		setModalDisplay(false);
		setFormValidation({...initialValidation});
	};

	const handleInputChange = (value, key) => {
		let updatedData = profileForm;
		let validationData = {...initialValidation};

		if (value === null || value === "") {
			value = null;
		}

		if (key === "city" || key === "country") {
			updatedData["location"][key] = value;
		} else {
			updatedData[key] = value;
		}

		for (const [objKey, objValue] of Object.entries({
			first_name: updatedData.first_name,
			last_name: updatedData.last_name,
		})) {
			if (objValue === "" || objValue === null) {
				validationData[objKey] = {valid: false};
			} else {
				validationData[objKey] = {valid: true};
			}
		}

		setFormValidation({...validationData});
		setProfileForm(updatedData);
		validationChecker(updatedData);
	};

	const validationChecker = (updatedData) => {
		let isValid = true;

		if (updatedData.first_name === null || updatedData.last_name === null) {
			isValid = false;
		}

		setIfSaveDisabled(!isValid);
	};

	const save = async () => {
		setLoading(true);

		await projectApi
			.saveCandidateInfo(projectId, candidateId, {
				personal_details: profileForm,
			})
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					setIfModalIsOpen(false);
					setModalDisplay(false);
					toast.success("Saved Successfully");
					updateDisplayedData({
						...displayedCandidateData,
						...profileForm,
					});

					if (updateCandidateName) {
						updateCandidateName(
							`${profileForm.first_name} ${profileForm.last_name}`,
						);
					}
				} else {
					toast.error("Something went wrong.");
				}
			})
			.catch((error) => console.log(error))
			.finally(() => setLoading(false));
	};

	return (
		<div>
			<Modal
				isOpen={modalIsOpen}
				style={customStyles}
				contentLabel="Personal Information Modal"
			>
				<div
					className="reports-modal candidate-profile-modal"
					style={{width: "auto"}}
				>
					<div className="modal-title">
						<span className="title">Edit Profile</span>
						<CloseIconBold
							className="close-icon cursor"
							onClick={handleClose}
						/>
					</div>

					<div className="content input-wrapper">
						<div className="col company-logo-column">
							<label htmlFor="">Photo</label>

							{displayedCandidateData.avatar !== null ? (
								<img
									src={displayedCandidateData.avatar}
									alt={displayedCandidateData.first_name}
									height={56}
								/>
							) : (
								<div
									className="name-as-avatar"
									style={{height: "56px"}}
								>
									{getShortName(
										displayedCandidateData.first_name,
										displayedCandidateData.last_name,
									)}
								</div>
							)}
						</div>

						<div className="col input-wrapper">
							<label htmlFor="">First Name</label>

							<TextInput
								customClass="input"
								errorText={
									formValidation?.first_name?.valid !== undefined &&
									!formValidation?.first_name?.valid
										? errorTexts.firstNameErrorText
										: null
								}
								onChange={(e) =>
									handleInputChange(e.target.value, "first_name")
								}
								placeholder="First Name"
								defaultValue={profileForm.first_name}
							/>
						</div>

						<div className="col input-wrapper">
							<label htmlFor="">Last Name</label>

							<TextInput
								customClass="input"
								errorText={
									formValidation?.last_name?.valid !== undefined &&
									!formValidation?.last_name?.valid
										? errorTexts.firstNameErrorText
										: null
								}
								onChange={(e) =>
									handleInputChange(e.target.value, "last_name")
								}
								placeholder="Last Name"
								defaultValue={profileForm.last_name}
							/>
						</div>

						<div className="col input-wrapper">
							<label htmlFor="">Linkedin Link</label>

							<TextInput
								customClass="input"
								onChange={(e) =>
									handleInputChange(e.target.value, "linked_in_url")
								}
								placeholder="https://linkedin.com/your-profile-link"
								defaultValue={profileForm.linked_in_url}
							/>
						</div>

						<div className="col input-wrapper">
							<label htmlFor="">Town/City</label>

							<TextInput
								customClass="input"
								onChange={(e) =>
									handleInputChange(e.target.value, "city")
								}
								placeholder="e.g. London"
								defaultValue={profileForm.location.city}
							/>
						</div>

						<div className="col input-wrapper">
							<label htmlFor="">Country</label>

							<SelectComponent
								key={"country"}
								customClass="dropdown"
								customStyle={{width: "100%"}}
								borderColor="#dedede"
								options={countriesData()}
								onChange={(country, triggerAction) => {
									if (triggerAction.action === "clear") {
										handleInputChange(null, "country");
									} else {
										handleInputChange(country.value, "country");
									}
								}}
								value={countriesData().filter(
									(country) =>
										country.value ===
										profileForm?.location?.country,
								)}
								// menuPortalTarget={document.body}
								// menuPosition={"fixed"}
								menuPlacement={"auto"}
								hideIndicator={true}
							/>
						</div>

						<div className="buttons">
							<button className="btn btn-white" onClick={handleClose}>
								Cancel
							</button>

							<button
								className={[
									"btn",
									loading || isSaveDisabled
										? "btn-white btn-disabled"
										: "btn-red",
								].join(" ")}
								disabled={loading || isSaveDisabled}
								onClick={save}
							>
								{loading ? (
									<>Saving {InlineLoaderComponent}</>
								) : (
									"Save Changes"
								)}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default PersonalInformationModal;
