/** @format */

import React from "react";
import ErrorPage from "components/Plugins/ErrorPage";
import NotFoundIcon from "assets/icons/NotFoundIcon";

const NotFound = () => {
	return (
		<ErrorPage
			icon={<NotFoundIcon />}
			title="Page Not Found"
			description="Sorry, we couldn't find the page."
			link="/home"
			linkText="Back to homepage"
		/>
	);
};

export default NotFound;
