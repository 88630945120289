/** @format */

import HttpClient from "./api";

class AuthApi extends HttpClient {
	async login(userData) {
		userData = {...userData, grant_type: "password"};

		return await this.doPost("/auth/login", userData);
	}

	async logout(body) {
		return await this.doPost("/auth/logout", body);
	}

	async logoutBulk(body) {
		return await this.doPost("/auth/logout/bulk", body);
	}

	async getUser() {
		return await this.doGet("/user/profile");
	}

	async getUserName({email}) {
		return await this.doGet(`/auth/get_username?email_address=${email}`);
	}

	async verifyEmail(formData, config = {}) {
		return await this.doPost("/auth/reset-password/new", formData, config);
	}

	async resetPassword(code, formData, config = {}) {
		return await this.doPost(`/auth/reset-password?code=${code}`, formData, config);
	}

	async register(code, formData, config = {}) {
		return await this.doPost(
			`/auth/register?invitation_code=${code}`,
			formData,
			config,
			true,
		);
	}

	async getAdmins(config = null) {
		return await this.doGet("/admin/user", config);
	}

	async saveAdmin(formData, config = {}) {
		const userId = formData.user_id || null;

		return userId
			? delete formData.user_id &&
					(await this.doPatch(`/admin/user/${userId}`, formData, config))
			: await this.doPost("/admin/user", formData, config);
	}

	async updateAdmin(body, adminId) {
		return await this.doPatch(`/admin/user/${adminId}`, body);
	}

	async deleteAdmin(adminId) {
		return await this.doDelete(`/admin/user/${adminId}`);
	}

	async activateAdmin(adminId) {
		return await this.doPost(`/admin/user/${adminId}/activate`);
	}

	async deactivateAdmin(adminId) {
		return await this.doPost(`/admin/user/${adminId}/deactivate`);
	}
}

const auth = new AuthApi();

export default auth;
