import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import useAuthRedirect from "./useAuthRedirect";
import useFormValidation from "./useFormValidation";
import {
	loginUser,
	registerUser,
	resetPassword,
	verifyEmailAccount,
} from "store/user/userSelectors";
import Loader from "components/Plugins/Loader";
import {emailPattern} from "helpers/validator";

const initialUserData = {
	email: "",
	password: "",
};
const initialValidation = {
	email: {valid: false},
	password: {valid: false},
};

export const useOnAuth = ({
	history,
	type = "login",
	userInitialData = null,
	userInitialValidation = null,
	redirectUrl = "/home",
	themeColor = "#702c2e",
}) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const {user} = useSelector((state) => state.user); // fetchUserInProgress removed from const
	const [error, setError] = useState(null);
	const [message, setMessage] = useState(null);
	const urlSearchParams = new URLSearchParams(window.location.search);
	const redirectTo =
		Object.fromEntries(urlSearchParams.entries())?.redirectTo || null;

	const [userData, validation, changeForm] = useFormValidation(
		userInitialData || initialUserData,
		userInitialValidation || initialValidation,
	);

	// for handling memory leak
	const unmounted = useRef(false);

	useEffect(() => {
		return () => {
			unmounted.current = true;
		};
	}, []);

	useAuthRedirect(user, history, redirectTo || redirectUrl);

	const onSubmit = async () => {
		if (!submitEnabled) return;
		setError(null);
		setMessage(null);

		let res;

		setLoading(true);

		if (type === "forgot-password") {
			res = await dispatch(verifyEmailAccount(userData));

			if (res && !res?.error) {
				setMessage(
					"An email has been sent your inbox with further instruction. Please check your inbox!",
				);
			}
		} else if (type === "reset-password") {
			const code = userData.code;

			res = await dispatch(resetPassword(code, userData));

			if (res && !res?.error) {
				setMessage(
					"Password updated successfully. You will be redirected to login page...",
				);

				setTimeout(() => {
					history.push(redirectTo || redirectUrl);
				}, 2000);
			}
		} else if (type === "register") {
			const code = userData.code;

			delete userData.code;
			userData.password = userData.new_password;
			delete userData.new_password;
			delete userData.conf_password;
			delete userData.project_id;

			res = await dispatch(registerUser(code, userData));

			if (res && !res?.error) {
				setMessage(
					"Registered successfully. Redirecting you to the project page...",
				);

				setTimeout(() => {
					history.push(redirectTo || redirectUrl);
				}, 2000);
			}
		} else {
			res = await dispatch(loginUser(userData));
		}

		if (!unmounted.current) {
			setLoading(false);
		}

		if (res.error) {
			setError(res.error);
		}
	};

	const LoaderComponent = (
		<Loader type={"Rings"} height={40} width={40} color={themeColor} />
	);

	const [autoFilled, setAutofilled] = useState(false);

	useLayoutEffect(() => {
		/**
		 * The field can be prefilled on the very first page loading by the browser
		 * By the security reason browser limits access to the field value from JS level and the value becomes available
		 * only after first user interaction with the page
		 * it actually can have some value, so we should process this edge case in the form logic
		 */

		const checkAutofilled = () => {
			const hasValue =
				!!document.getElementById("email")?.matches("*:-webkit-autofill") &&
				!!document.getElementById("password")?.matches("*:-webkit-autofill");
			setAutofilled(hasValue);

			const el = document.querySelector('[name="email"]');

			if (el) validation.email.valid = emailPattern.test(el.value);
		};

		// The time when it's ready is not very stable, so check few times
		setTimeout(checkAutofilled, 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isDisabled = () => {
		const isEmailValid = validation?.email?.valid || autoFilled;
		const isPasswordValid = validation.password
			? validation.password.valid || autoFilled
			: false;

		if (type === "forgot-password") {
			return isEmailValid;
		} else if (type === "reset-password") {
			return validation.new_password.valid && validation.conf_password.valid;
		} else if (type === "register") {
			return (
				validation.email.valid &&
				validation.first_name.valid &&
				validation.last_name.valid &&
				validation.new_password.valid
			);
		} else {
			return isEmailValid && isPasswordValid;
		}
	};

	const submitEnabled = isDisabled();

	return {
		loading,
		error,
		changeForm,
		onSubmit,
		LoaderComponent,
		submitEnabled,
		validation,
		message,
	};
};
