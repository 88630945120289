import React, {useCallback, useEffect, useState} from "react";
import IconRight from "assets/icons/IconRight";
import IconLeft from "assets/icons/IconLeft";
import CustomDoughnut from "rs-components/Charts/CustomDoughnut";
import classNames from "classnames";
import {getGenderChartDataByProjectId} from "store/project/projectSelectors";
import {useDispatch, useSelector} from "react-redux";

const GenderSection = ({
	projectId,
	researchMode,
	InlineLoaderComponent,
	applyGrayEffect,
}) => {
	const {genderData} = useSelector((state) => state.project);
	const dispatch = useDispatch();
	const [genderLoading, setGenderLoading] = useState(false);
	const [genderChartData, setGenderChartData] = useState(null);

	const Chart = genderChartData?.labels.length ? (
		<CustomDoughnut
			labels={genderChartData.labels}
			values={genderChartData.values}
			backgroundColors={genderChartData.colors}
			borderColors={genderChartData.colors}
		/>
	) : (
		"-"
	);

	const getGenderData = useCallback(async () => {
		await dispatch(getGenderChartDataByProjectId(projectId, researchMode));
	}, [dispatch, projectId, researchMode]);

	useEffect(() => {
		if (genderData?.fetched) {
			setGenderChartData(genderData);
		} else {
			setGenderLoading(true);
			getGenderData().finally(() => setGenderLoading(false));
		}
	}, [genderData, getGenderData]);

	return (
		<>
			<div
				className={classNames("chart-row")}
				style={{
					paddingTop: researchMode ? "21px" : 0,
					opacity: applyGrayEffect ? "50%" : "100%",
				}}
			>
				<div className={"section-title"} style={{marginBottom: 0}}>
					Gender
					{/*{researchMode && (<span><IconAdd className="cursor"/></span>)}*/}
				</div>
				<div className="navigate-arrows">
					<IconRight className="icon-right" />{" "}
					<IconLeft color="#CDCECF" className="icon-left" />
				</div>
			</div>

			<div
				style={{
					opacity: applyGrayEffect ? "50%" : "100%",
				}}
			>
				{genderLoading ? InlineLoaderComponent : Chart}
			</div>
		</>
	);
};

export default GenderSection;
