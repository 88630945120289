import React, {useCallback, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";

import IconPencil from "assets/icons/IconPencil";
import DragHandle from "assets/icons/DragHandle";
import ProgressStatus from "rs-components/ProgressStatus";
import PersonalInformationModal from "rs-pages/ClientCandidate/Modals/PersonalInformationModal";
import {CompanyLogo} from "rs-components/CompanyLogo/CompanyLogo";
import {ProgressNote} from "rs-components/ProgressNote";
import {convertLocationConcatenatedString, getShortName} from "helpers/string";
import {togglePeopleLogoModal} from "store/modal/modalActions";

import ViewProfileButton from "../../Buttons/ViewProfileButton";

const SummaryCard = ({
	candidate,
	isDetailsProfile = false,
	candidateProfileStringParameter = "",
	projectId,
	updateCandidateName = null,
	displayLinkedInUrl = true,
	displayProfilePic = true,
	sortable = false,
	disableDragHandle = false,
}) => {
	const dispatch = useDispatch();

	const researchMode = useSelector((state) => state.user.researchModeEnabled);
	const [displayedCandidateData, setDisplayedCandidateData] = useState({
		...candidate,
	});
	const [modalIsOpen, setIfModalIsOpen] = useState(false);
	// const [showOptions, setShowOptions] = useState(false);
	// const ref = useRef();

	// useOnClickOutside(ref, () => setShowOptions(false));

	const companyInfo = useMemo(() => {
		let company = candidate.company;

		if (company === null) return null;

		if (company.custom_name || company.invenias_name) {
			company.name = company?.custom_name || company?.invenias_name;
		} else if (company.name === undefined) {
			company.name = "-";
		}

		return company;
	}, [candidate.company]);

	const role = useMemo(
		() =>
			candidate?.role?.length > 70
				? candidate?.role?.slice(0, 67) + "..."
				: candidate?.role || "-",
		[candidate.role],
	);

	const handleEditProfileClick = () => {
		setIfModalIsOpen(true);
	};

	const handleLogoClick = useCallback(() => {
		if (researchMode) {
			dispatch(
				togglePeopleLogoModal({
					name: displayedCandidateData.first_name,
					id: displayedCandidateData.id,
					logo: displayedCandidateData.avatar,
				}),
			);
		}
	}, [dispatch, displayedCandidateData, researchMode]);

	const candidateName = (
		<span className="value">
			{`${displayedCandidateData.first_name} ${displayedCandidateData.last_name}`}
		</span>
	);

	const candidateAvatar = (
		<>
			{displayedCandidateData.avatar !== null && displayProfilePic && (
				<img
					className={classNames({uploadable: researchMode})}
					src={displayedCandidateData.avatar}
					alt={displayedCandidateData.first_name}
					onClick={handleLogoClick}
				/>
			)}

			{(displayedCandidateData.avatar === null || !displayProfilePic) && (
				<div
					className={classNames("name-as-avatar", {uploadable: researchMode})}
					onClick={handleLogoClick}
				>
					{getShortName(
						displayedCandidateData.first_name,
						displayedCandidateData.last_name,
					)}
				</div>
			)}
		</>
	);

	const candidateAvatarAsLink = (
		<CandidateProfileLink
			candidateId={displayedCandidateData.id}
			projectId={projectId}
			candidateProfileStringParameter={candidateProfileStringParameter}
			childrenComponent={candidateAvatar}
			researchMode={researchMode}
			clickable={candidate?.is_clickable || candidate?.for_discussion}
		/>
	);

	const candidateNameAsLink = (
		<CandidateProfileLink
			candidateId={displayedCandidateData.id}
			projectId={projectId}
			candidateProfileStringParameter={candidateProfileStringParameter}
			childrenComponent={candidateName}
			isLink={
				researchMode ||
				(!researchMode &&
					(candidate?.is_clickable || candidate?.for_discussion))
			}
		/>
	);

	const statusInfo = useMemo(() => {
		let info;
		if (displayedCandidateData?.status_info) {
			info = displayedCandidateData.status_info;
		} else if (displayedCandidateData?.progress_info) {
			info = displayedCandidateData?.progress_info;
		} else return null;

		if (
			info !== undefined &&
			typeof info.progress_note === "string" &&
			info.progress_note?.length === 0
		) {
			info.progress_note = null;
		}

		return info;
	}, [displayedCandidateData]);

	const {attributes, listeners, setNodeRef, transform, transition, isDragging} =
		useSortable({
			id: candidate.id,
			disabled: !sortable || disableDragHandle,
		});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<>
			<div
				className={classNames("candidate-card", {
					inactive:
						!researchMode &&
						(!candidate?.is_clickable || candidate.for_discussion) &&
						!isDetailsProfile,
					dragging: isDragging,
				})}
				ref={setNodeRef}
				style={style}
			>
				<div className="avatar">
					{displayedCandidateData?.for_discussion && (
						<span className="discussion">Highlighted</span>
					)}
					<div className="avatar-details">
						{sortable && (
							<>
								{disableDragHandle && (
									<ReactTooltip
										id={candidate.id}
										place="top"
										type="light"
										effect="solid"
										className="custom-tooltip"
									>
										<span>
											To order Candidates manually select `
											<b>Preferred</b>` sorting
										</span>
									</ReactTooltip>
								)}
								<a data-tip data-for={candidate.id}>
									<DragHandle
										className={classNames("drag-handle", {
											disabled: disableDragHandle,
										})}
										{...attributes}
										{...listeners}
									/>
								</a>
							</>
						)}
						{candidateAvatarAsLink}
						<div className="name-wrapper">{candidateNameAsLink}</div>

						{researchMode && isDetailsProfile && (
							<IconPencil
								className="edit-icon cursor"
								color="#702C2E"
								onClick={handleEditProfileClick}
							/>
						)}
					</div>
				</div>
				<div className="info-block">
					<div className="basic-info">
						<div className="row">
							<div className="info name">
								<span className="label">Name</span>

								{candidateNameAsLink}
							</div>
							<div className="info role">
								<span className="label">Role</span>
								<span className="value-wrapper" data-tooltip={role}>
									<span className="value">{role}</span>
								</span>
							</div>

							<div className="info company">
								<div className="logo">
									{companyInfo !== null && companyInfo?.logo ? (
										<CompanyLogo
											src={companyInfo?.logo}
											name={companyInfo?.name}
											id={companyInfo?.id}
											alt=""
											showUploadOnHover={researchMode}
										/>
									) : (
										<span style={{paddingRight: "32px"}} />
									)}
								</div>
								<div className="details">
									<span className="label">Company</span>
									<span className="value">{companyInfo?.name}</span>
								</div>
							</div>
							<div className="info location">
								<span className="label">Location</span>
								<span className="value">
									{convertLocationConcatenatedString(
										displayedCandidateData.location,
									)}
								</span>
							</div>

							<div className="progress-block">
								{/*<ReactTooltip place="top" type="light" effect="solid" className="custom-tooltip"/>*/}
								<ProgressStatus
									key={displayedCandidateData.id}
									status={
										displayedCandidateData.status_info
											? displayedCandidateData.status_info
													.display_text
											: displayedCandidateData.progress_info
													.status
									}
									projectId={projectId}
									candidateId={displayedCandidateData.id}
									date={
										displayedCandidateData.status_info
											? displayedCandidateData.status_info.date
											: displayedCandidateData.progress_info.date
									}
									progressLevel={
										displayedCandidateData.status_info
											? displayedCandidateData.status_info.level
											: displayedCandidateData.progress_info.level
									}
								/>
							</div>
						</div>

						{(researchMode ||
							(!researchMode && statusInfo?.progress_note !== null)) && (
							<ProgressNote
								comment={statusInfo?.progress_note}
								projectId={projectId}
								candidateId={displayedCandidateData.id}
								researchMode={researchMode}
							/>
						)}

						<span className="view-button-wrapper">
							<ViewProfileButton
								isDetailsProfile={isDetailsProfile}
								projectId={projectId}
								candidateProfileStringParameter={
									candidateProfileStringParameter
								}
								candidateId={displayedCandidateData.id}
								isClickable={
									researchMode ||
									candidate?.is_clickable ||
									candidate?.for_discussion
								}
								linkedInUrl={
									displayLinkedInUrl
										? displayedCandidateData.linked_in
										: null
								}
							/>
						</span>
					</div>
				</div>
			</div>

			{!isDragging && <div className="candidate-card-line" />}

			{modalIsOpen && (
				<PersonalInformationModal
					modalDisplay={modalIsOpen}
					candidateId={candidate.id}
					setModalDisplay={setIfModalIsOpen}
					projectId={projectId}
					updateDisplayedData={setDisplayedCandidateData}
					displayedCandidateData={displayedCandidateData}
					updateCandidateName={updateCandidateName}
				/>
			)}
		</>
	);
};

export default SummaryCard;

export const CandidateProfileLink = ({
	projectId,
	candidateId,
	candidateProfileStringParameter,
	childrenComponent,
	clickable,
	researchMode,
}) =>
	!researchMode && clickable ? (
		<Link
			to={{
				pathname: `/projects/${projectId}/candidate-profile/${candidateId}${candidateProfileStringParameter}`,
			}}
		>
			{childrenComponent}
		</Link>
	) : (
		childrenComponent
	);
