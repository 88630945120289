/** @format */

import React from "react";

const SaveIcon = (props) => {
	return (
		<svg
			{...props}
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.5556 0H2.22224C0.994427 0 0 0.994427 0 2.22224V17.7778C0 19.0056 0.994427 20 2.22224 20H17.7778C19.0056 20 20 19.0056 20 17.7778V4.44443L15.5556 0ZM10 17.7778C8.16109 17.7778 6.66667 16.2833 6.66667 14.4444C6.66667 12.6055 8.16109 11.1111 10 11.1111C11.8389 11.1111 13.3333 12.6055 13.3333 14.4444C13.3333 16.2833 11.8389 17.7778 10 17.7778ZM13.3333 6.66667H2.22224V2.22224H13.3333V6.66667Z"
				fill="white"
			/>
		</svg>
	);
};

export default SaveIcon;
