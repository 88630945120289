/** @format */

import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import useModalHook from "../../../hooks/useModalHook";
import projectApi from "../../../api/report/project";
import {toast} from "react-toastify";
import {setSummaryData} from "../../../store/project/projectActions";
import CloseIconBold from "../../../assets/icons/IconCloseBold";
import TextInput from "../../../components/Inputs/TextInput";
import IconTrash from "../../../assets/icons/IconTrash";
import TextAreaInput from "../../../components/Inputs/TextAreaInput";
import {autoGrowTextArea} from "../../../helpers/general";
import IconAdd from "../../../assets/icons/IconAdd";

const initialInsight = {
	id: null,
	heading: null,
	description: null,
};

const errorTexts = {
	headingErrorText: "Title can not be empty!",
	descriptionErrorText: "Description can not be empty!",
};

const initialValidation = {
	heading: {valid: undefined},
	description: {valid: undefined},
};

const MarketInsightModal = ({
	marketInsights,
	projectId,
	modalDisplay = true,
	setModalDisplay,
	updateMarketInsights,
}) => {
	const summaryData = useSelector((state) => state.project.summaryData);
	const {
		modalIsOpen,
		setIfModalIsOpen,
		customStyles,
		Modal,
		loading,
		setLoading,
		InlineLoaderComponent,
	} = useModalHook({content: {width: "600px"}}, modalDisplay);
	const [isSaveDisabled, setIfSaveDisabled] = useState(true);
	const [marketInsightsForm, setMarketInsightsForm] = useState([{...initialInsight}]);
	const [displayAddNewIcon, setDisplayAddNewIcon] = useState(false);
	const [deletedInsights, setDeletedInsights] = useState([]);
	const [validation, setValidation] = useState([{...initialValidation}]);
	const [isEditMode, setIsEditMode] = useState(false);
	const dispatch = useDispatch();

	const handleClose = () => {
		setMarketInsightsForm([{...initialInsight}]);
		setIfModalIsOpen(false);
		setDeletedInsights([]);

		setTimeout(() => {
			setModalDisplay(false);
		}, 1000);
	};

	const handleAddNew = async () => {
		const insights = [...marketInsightsForm, {...initialInsight}];

		await setMarketInsightsForm(insights);
		await setDisplayAddNewIcon(false);
		setIfSaveDisabled(true); // because we're adding new role with empty data;
		setValidation([...validation, {...initialValidation}]);
	};

	const removeInsight = async (dataIndex) => {
		let insights = marketInsightsForm;

		if (insights[dataIndex] === undefined) return;

		if (isEditMode && insights[dataIndex]?.id) {
			const deletedData = {
				id: insights[dataIndex].id,
				is_deleted: true,
			};

			setDeletedInsights([...deletedInsights, deletedData]);
		}

		insights = insights.filter((_, index) => index !== dataIndex);

		if (!insights.length) {
			insights = [{...initialInsight}];
		}

		setMarketInsightsForm([...insights]);
		validationChecker([...insights]);
		setAddIconStatus([...insights]);
	};

	const setAddIconStatus = (data = []) => {
		let latestData = data[data.length - 1];

		if (latestData.heading !== null && latestData.description !== null) {
			setDisplayAddNewIcon(true);
		} else {
			setDisplayAddNewIcon(false);
		}
	};

	const handleInputChange = (value, key, dataIndex) => {
		let updatedData = marketInsightsForm;

		updatedData[dataIndex][key] = value;
		setAddIconStatus(updatedData);

		const validationData = [...validation];

		// HERE VALIDATION RULE IS: HEADING & DESCRIPTION CAN NOT BE EMPTY

		if (value) {
			validationData[dataIndex][key] = {valid: true};
		} else {
			validationData[dataIndex][key] = {valid: false};
			setIfSaveDisabled(true);
		}

		setValidation([...validationData]);
		setMarketInsightsForm([...updatedData]);
		validationChecker(updatedData);
	};

	const validationChecker = (updatedData) => {
		let isValid;

		for (let item of updatedData) {
			if (
				item.heading === null ||
				item.heading === "" ||
				item.description === null ||
				item.description === ""
			) {
				isValid = false;
				break;
			}
			isValid = true;
		}

		setIfSaveDisabled(!isValid);
	};

	const save = async () => {
		const data = [...marketInsightsForm, ...deletedInsights];

		setLoading(true);

		await projectApi
			.saveProjectInfo(projectId, {market_insights: data})
			.then((response) => {
				if (response.status >= 200 && response.status <= 204) {
					toast.success("Saved Successfully");
					updateMarketInsights(response.data.market_insights);
					dispatch(
						setSummaryData({
							...summaryData,
							marketInsights: response.data.market_insights,
						}),
					);
					setIfModalIsOpen(false);

					setTimeout(() => {
						setModalDisplay(false);
					}, 1000);
				} else {
					toast.error("Something went wrong!");
				}
			})
			.catch((error) => console.log(error));

		setLoading(false);
		setDeletedInsights([]);
	};

	const handleEdit = useCallback(() => {
		setIfModalIsOpen(true);

		if (marketInsights?.length) {
			setIsEditMode(true);
			const data = [...marketInsights];

			setMarketInsightsForm(data);
			let validationData = [];

			data.forEach(() => {
				validationData = [
					...validationData,
					{
						heading: {valid: true},
						description: {valid: true},
					},
				];
			});

			setValidation(validationData);
			setAddIconStatus(data);
			validationChecker(data);
		} else {
			setIsEditMode(false);
		}
	}, [marketInsights, setIfModalIsOpen]);

	useEffect(() => {
		handleEdit();
	}, [handleEdit]);

	return (
		<div>
			<Modal
				isOpen={modalIsOpen}
				style={customStyles}
				contentLabel="Market Insight Modal"
			>
				<div className="reports-modal" style={{width: "auto"}}>
					<div className="modal-title">
						<span className="title">Market Insights</span>
						<CloseIconBold
							className="close-icon cursor"
							onClick={handleClose}
						/>
					</div>

					<div className="content">
						{marketInsightsForm.map((data, dataIndex) => (
							<div key={`${dataIndex} + ${data.id}`}>
								<div className="col input-wrapper company-name">
									<label htmlFor="">Title</label>

									<div className="text-input-with-icon">
										<TextInput
											customClass="input"
											errorText={
												validation[dataIndex]?.heading
													?.valid !== undefined &&
												!validation[dataIndex]?.heading?.valid
													? errorTexts.headingErrorText
													: null
											}
											placeholder="Title"
											onChange={(e) =>
												handleInputChange(
													e.target.value,
													"heading",
													dataIndex,
												)
											}
											defaultValue={data.heading}
										/>

										{(dataIndex > -1 || isEditMode) && (
											<IconTrash
												onClick={() => removeInsight(dataIndex)}
												className="trash-icon cursor"
											/>
										)}
									</div>
								</div>

								<div className="col input-wrapper company-name">
									<label htmlFor="">Description</label>

									<TextAreaInput
										rows={
											data.description?.length
												? data.description?.split("\n").length
												: 1
										}
										customStyle={{
											maxHeight: "350px",
											height: "auto",
										}}
										errorText={
											validation[dataIndex]?.description
												?.valid !== undefined &&
											!validation[dataIndex]?.description?.valid
												? errorTexts.descriptionErrorText
												: null
										}
										onChange={(e) =>
											handleInputChange(
												e.target.value,
												"description",
												dataIndex,
											)
										}
										onKeyDown={autoGrowTextArea}
										onKeyUp={autoGrowTextArea}
										defaultValue={data.description}
									/>
								</div>
							</div>
						))}

						{displayAddNewIcon && (
							<div className="col add-icon-with-label">
								<span className="cursor" onClick={handleAddNew}>
									Add Insight
									<IconAdd />
								</span>
							</div>
						)}

						<div className="buttons">
							<button className="btn btn-white" onClick={handleClose}>
								Cancel
							</button>

							<button
								className={[
									"btn",
									loading || isSaveDisabled
										? "btn-white btn-disabled"
										: "btn-red",
								].join(" ")}
								disabled={loading || isSaveDisabled}
								onClick={save}
							>
								{loading ? (
									<>Saving {InlineLoaderComponent}</>
								) : (
									"Save Changes"
								)}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default MarketInsightModal;
