/** @format */

import React, {useState} from "react";
import CloseIconBold from "../../../assets/icons/IconCloseBold";
import TextAreaInput from "../../../components/Inputs/TextAreaInput";
import {autoGrowTextArea} from "../../../helpers/general";
import useModalHook from "../../../hooks/useModalHook";
import projectApi from "../../../api/report/project";
import {toast} from "react-toastify";

const SummaryModal = ({
	displayModal,
	displayedSummary,
	candidateId,
	projectId,
	updateSummary,
	setModalDisplay,
}) => {
	const {
		modalIsOpen,
		setIfModalIsOpen,
		customStyles,
		Modal,
		loading,
		setLoading,
		InlineLoaderComponent,
	} = useModalHook({content: {width: "995px"}}, displayModal);
	const [summary, setSummary] = useState(displayedSummary || "");

	const handleClose = () => {
		setSummary(displayedSummary);
		setIfModalIsOpen(false);

		setTimeout(() => {
			setModalDisplay(false);
		}, 1000);
	};

	const saveSummary = async () => {
		setLoading(true);

		await projectApi
			.saveCandidateInfo(projectId, candidateId, {summary})
			.then((response) => {
				setIfModalIsOpen(false);
				toast.success("Saved Successfully");
				updateSummary(response.data.summary);

				setTimeout(() => {
					setModalDisplay(false);
				}, 1000);
			})
			.catch((error) => console.log(error));

		setLoading(false);
	};

	return (
		<div>
			<Modal
				isOpen={modalIsOpen}
				style={customStyles}
				contentLabel="Summary Modal"
			>
				<div className="reports-modal" style={{width: "auto"}}>
					<div className="modal-title">
						<span className="title">Summary</span>
						<CloseIconBold
							className="close-icon cursor"
							onClick={handleClose}
						/>
					</div>

					<div className="content input-wrapper">
						<TextAreaInput
							rows={10}
							customStyle={{
								maxHeight: "350px",
								height: "auto",
								paddingBottom: 0,
							}}
							errorText={summary ? "" : "Please Enter Summary"}
							onChange={(e) => setSummary(e.target.value)}
							onKeyDown={autoGrowTextArea}
							onKeyUp={autoGrowTextArea}
							defaultValue={summary}
						/>

						<div className="buttons">
							<button className="btn btn-white" onClick={handleClose}>
								Cancel
							</button>

							<button
								className={[
									"btn",
									loading || summary?.length < 1
										? "btn-white btn-disabled"
										: "btn-red",
								].join(" ")}
								disabled={loading || summary?.length < 1}
								onClick={saveSummary}
							>
								{loading ? (
									<>Saving {InlineLoaderComponent}</>
								) : (
									"Save Changes"
								)}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default SummaryModal;
