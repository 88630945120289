/** @format */

import React, {useState} from "react";
import Modal from "react-modal";
import Loader from "../components/Plugins/Loader";

const styles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		background: "#fff",
		borderRadius: 0,
		border: "none",
		padding: 0,
	},
	overlay: {
		background: "rgba(20, 20, 20, 0.7)",
		zIndex: 100,
	},
};

Modal.setAppElement("#root");

const useModalHook = (
	customStyles = {},
	modalDisplay = false,
	loaderColor = "#0c5850",
) => {
	const [modalIsOpen, setIfModalIsOpen] = useState(modalDisplay);
	const [loading, setLoading] = useState(false);
	const [deleting, setDeleting] = useState(false);

	const InlineLoaderComponent = (
		<Loader
			height={16}
			width={16}
			customStyle={{paddingLeft: "10px"}}
			type="TailSpin"
			color={loaderColor}
		/>
	);

	const LoaderComponent = (
		<Loader
			displayAtCenterOfPage={true}
			type={"Rings"}
			height={80}
			width={80}
			color={loaderColor}
		/>
	);

	return {
		modalIsOpen,
		setIfModalIsOpen,
		Modal,
		customStyles: {
			content: {...styles.content, ...customStyles.content},
			overlay: {...styles.overlay, ...customStyles.overlay},
		},
		loading,
		setLoading,
		deleting,
		setDeleting,
		LoaderComponent,
		InlineLoaderComponent,
	};
};

export default useModalHook;
