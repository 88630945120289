/** @format */

import React from "react";
import ErrorPage from "components/Plugins/ErrorPage";
import ServiceUnavailableIcon from "assets/icons/ServiceUnavailableIcon";

const ServiceUnavailable = () => {
	return (
		<ErrorPage
			icon={<ServiceUnavailableIcon />}
			title="Service Unavailable"
			description="Sorry, we are under maintenance. We'll be ready soon! Please come back later."
		/>
	);
};

export default ServiceUnavailable;
