/** @format */

import React from "react";
import {Provider} from "react-redux";
import store from "store";
import {BrowserRouter as Router} from "react-router-dom";
import AppContainer from "./AppContainer";
import "./styles/global.module.scss";

class App extends React.Component {
	constructor() {
		super();
	}

	componentDidCatch(error, errorInfo) {
		if (process.env.NODE_ENV === "production") {
			window.location = "/503";
			// TODO: send notification with error/errorInfo to slack.
			console.error(error);
			console.error(errorInfo);
		}
	}

	render() {
		return (
			<Provider store={store}>
				<Router>
					<AppContainer />
				</Router>
			</Provider>
		);
	}
}

export default App;
