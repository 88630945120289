/** @format */

import React from "react";
import {Link, useHistory} from "react-router-dom";

const Breadcrumb = ({options, activeIndex = 0, clickHandle}) => {
	const handleClick = (index) => {
		if (clickHandle) {
			clickHandle(index);
		}
	};

	const {push} = useHistory();

	return (
		<div className="breadcrumb-container">
			<div className="breadcrumb">
				{options.map((option, index) => (
					<React.Fragment key={index}>
						{activeIndex === index ? (
							<a className="active" onClick={() => push(option.to)}>
								<span className="breadcrumb__inner">
									<span className="breadcrumb__title">
										{option.name}
									</span>
								</span>
							</a>
						) : (
							<Link to={option.to} onClick={() => handleClick(index)}>
								<span className="breadcrumb__inner">
									<span className="breadcrumb__title">
										{option.name}
									</span>
								</span>
							</Link>
						)}
					</React.Fragment>
				))}
			</div>
		</div>
	);
};

export default Breadcrumb;
