import React, {useCallback} from "react";
import axios from "axios";
import config from "config";
import HttpClient from "api/api";
import {Loader} from "components";
import * as colors from "styles/themes.module.scss";
import {useDispatch} from "react-redux";
import ReportNavbar from "./rs-components/LayoutPartials/Navbar/reportNavbar";

import {logoutUser} from "store/user/userSelectors";

function forwardToLogin() {
	let redirect = "/login";

	const pathname = window.location.pathname;
	if (pathname) {
		redirect += `?redirectTo=${pathname}`;
	}

	window.localStorage.clear();
	window.location = redirect;
}

function Layout({children}) {
	const dispatch = useDispatch();
	const onLogoutUser = useCallback(async () => {
		dispatch(logoutUser());
	}, [dispatch]);

	const isRSPrintPage = () => {
		const url = (window.location.origin + window.location.pathname).split("/");

		return url.includes("print");
	};

	return (
		<>
			{!isRSPrintPage() && (
				<header>
					<ReportNavbar logoutAdmin={onLogoutUser} />
				</header>
			)}
			<main>{children}</main>
		</>
	);
}

function withAuth(Component) {
	return class AuthConponent extends React.Component {
		constructor(props) {
			super(props);
			this.controller = new AbortController();
			this.state = {render: false};
		}

		componentDidMount() {
			const token = window.localStorage.getItem("token");
			const refreshToken = window.localStorage.getItem("refresh_token");

			if (token) {
				const expiredTime = localStorage.getItem("expires_in")
					? parseInt(localStorage.getItem("expires_in"), 10)
					: null;

				if (expiredTime && expiredTime <= new Date().getTime()) {
					const client = axios.create({
						baseURL: config.apiUrl,
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
					});

					client
						.post(
							"/auth/refresh",
							{refresh_token: refreshToken},
							{signal: this.controller.signal},
						)
						.then(async (response) => {
							if (response.status === 200) {
								window.localStorage.setItem(
									"token",
									"Bearer " + response.data.access_token,
								);

								window.localStorage.setItem(
									"expires_in",
									new Date().getTime() +
										1000 * response.data.expires_in,
								);

								await HttpClient.resetConstructor();

								this.setState({render: true});
							} else {
								forwardToLogin();
							}
						})
						.catch(function (thrown) {
							if (!axios.isCancel(thrown)) {
								// Not a cancel but error
								forwardToLogin();
							}
						});
				} else {
					this.setState({render: true});
				}
			} else {
				forwardToLogin();
			}
		}

		componentWillUnmount() {
			this.controller.abort();
		}

		render() {
			const {render} = this.state;

			if (!render) {
				return (
					<Loader
						displayAtCenterOfPage={true}
						type={"Rings"}
						height={80}
						width={80}
						color={colors.loaderDotColor}
					/>
				);
			}

			return (
				<Layout>
					<Component {...this.props} />
				</Layout>
			);
		}
	};
}

export default withAuth;
