import React from "react";
import {Link} from "react-router-dom";

const ForDiscussionButton = ({projectId, totalCandidate}) => {
	return (
		<Link
			to={`/projects/${projectId}/assessments?filter-type=for_discussion&for-discussion=true`}
		>
			<div className="highlighted-profiles-button">
				<span className="label">
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<mask
							id="mask0_3973_30279"
							style={{maskType: "alpha"}}
							maskUnits="userSpaceOnUse"
							x="0"
							y="0"
							width="20"
							height="20"
						>
							<rect width="20" height="20" fill="#D9D9D9" />
						</mask>
						<g mask="url(#mask0_3973_30279)">
							<path
								d="M6.66699 11.6667H13.3337V11.2083C13.3337 10.5972 13.0281 10.1042 12.417 9.72916C11.8059 9.35416 11.0003 9.16666 10.0003 9.16666C9.00033 9.16666 8.19477 9.35416 7.58366 9.72916C6.97255 10.1042 6.66699 10.5972 6.66699 11.2083V11.6667ZM10.0003 8.33332C10.4587 8.33332 10.8512 8.16999 11.1778 7.84332C11.5039 7.51721 11.667 7.12499 11.667 6.66666C11.667 6.20832 11.5039 5.8161 11.1778 5.48999C10.8512 5.16332 10.4587 4.99999 10.0003 4.99999C9.54199 4.99999 9.14977 5.16332 8.82366 5.48999C8.49699 5.8161 8.33366 6.20832 8.33366 6.66666C8.33366 7.12499 8.49699 7.51721 8.82366 7.84332C9.14977 8.16999 9.54199 8.33332 10.0003 8.33332ZM1.66699 18.3333V3.33332C1.66699 2.87499 1.83033 2.48249 2.15699 2.15582C2.4831 1.82971 2.87533 1.66666 3.33366 1.66666H16.667C17.1253 1.66666 17.5178 1.82971 17.8445 2.15582C18.1706 2.48249 18.3337 2.87499 18.3337 3.33332V13.3333C18.3337 13.7917 18.1706 14.1842 17.8445 14.5108C17.5178 14.8369 17.1253 15 16.667 15H5.00033L1.66699 18.3333ZM3.33366 14.3125L4.31283 13.3333H16.667V3.33332H3.33366V14.3125Z"
								fill="#702C2E"
							/>
						</g>
					</svg>
					Highlighted Profiles ({totalCandidate})
				</span>

				<span>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<mask
							id="mask0_3973_30283"
							style={{maskType: "alpha"}}
							maskUnits="userSpaceOnUse"
							x="0"
							y="0"
							width="20"
							height="20"
						>
							<rect width="20" height="20" fill="#D9D9D9" />
						</mask>
						<g mask="url(#mask0_3973_30283)">
							<path
								d="M10.0002 16.6667L8.81266 15.5L13.4793 10.8333H3.3335V9.16666H13.4793L8.81266 4.49999L10.0002 3.33333L16.6668 9.99999L10.0002 16.6667Z"
								fill="#702C2E"
							/>
						</g>
					</svg>
				</span>
			</div>
		</Link>
	);
};

export default ForDiscussionButton;
