/** @format */

import React from "react";

const AddIcon = ({
	width = 18,
	height = 18,
	color = "#702C2E",
	isLarge = false,
	...props
}) => {
	return (
		<svg
			width={width}
			height={height}
			{...props}
			viewBox={`0 0 ${width} ${height}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			{!isLarge ? (
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0.666748 9.00033C0.666748 4.39795 4.39771 0.666992 9.00008 0.666992C11.2102 0.666992 13.3298 1.54497 14.8926 3.10777C16.4554 4.67057 17.3334 6.79019 17.3334 9.00033C17.3334 13.6027 13.6025 17.3337 9.00008 17.3337C4.39771 17.3337 0.666748 13.6027 0.666748 9.00033ZM11.9167 9.83366C12.1469 9.83366 12.3334 9.64711 12.3334 9.41699V8.58366C12.3334 8.35354 12.1469 8.16699 11.9167 8.16699H9.83342V6.08366C9.83342 5.85354 9.64687 5.66699 9.41675 5.66699H8.58342C8.3533 5.66699 8.16675 5.85354 8.16675 6.08366V8.16699H6.08341C5.8533 8.16699 5.66675 8.35354 5.66675 8.58366V9.41699C5.66675 9.64711 5.8533 9.83366 6.08341 9.83366H8.16675V11.917C8.16675 12.1471 8.3533 12.3337 8.58342 12.3337H9.41675C9.64687 12.3337 9.83342 12.1471 9.83342 11.917V9.83366H11.9167Z"
					fill={color}
				/>
			) : (
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0.666992 13.9993C0.666992 6.63555 6.63653 0.666016 14.0003 0.666016C17.5365 0.666016 20.9279 2.07077 23.4284 4.57126C25.9289 7.07174 27.3337 10.4631 27.3337 13.9993C27.3337 21.3631 21.3641 27.3327 14.0003 27.3327C6.63653 27.3327 0.666992 21.3631 0.666992 13.9993ZM18.667 15.3327C19.0352 15.3327 19.3337 15.0342 19.3337 14.666V13.3327C19.3337 12.9645 19.0352 12.666 18.667 12.666H15.3337V9.33268C15.3337 8.96449 15.0352 8.66601 14.667 8.66601H13.3337C12.9655 8.66601 12.667 8.96449 12.667 9.33268V12.666H9.33366C8.96547 12.666 8.667 12.9645 8.667 13.3327V14.666C8.667 15.0342 8.96547 15.3327 9.33366 15.3327H12.667V18.666C12.667 19.0342 12.9655 19.3327 13.3337 19.3327H14.667C15.0352 19.3327 15.3337 19.0342 15.3337 18.666V15.3327H18.667Z"
					fill={color}
				/>
			)}
		</svg>
	);
};

export default AddIcon;
