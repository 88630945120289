import React, {useCallback, useEffect, useRef, useState} from "react";
import LatestUpdatesSection from "./LatestUpdatesSection";
import Breadcrumb from "rs-components/Breadcrumb";
import {useDispatch, useSelector} from "react-redux";
import Loader from "components/Plugins/Loader";
import {
	getInterviewsByProjectId,
	getProjectSummaryByProjectId,
} from "store/project/projectSelectors";
import AssignmentInfoAsHeader from "rs-components/AssignmentInfoAsHeader";
import MarketInsightSection from "./MarketInsightSection";
import CandidatesByStage from "./CandidatesByStage";
import GenderSection from "./GenderSection";
import FitToBriefSection from "./FitToBriefSection";
import ForDiscussionButton from "rs-components/Buttons/ForDiscussionButton";
import useWindowSize from "hooks/useWindowSize";
import ErrorPage from "components/Plugins/ErrorPage";
import NotFoundIcon from "assets/icons/NotFoundIcon";

const Summary = (props) => {
	const summaryData = useSelector((state) => state.project.summaryData);
	const dispatch = useDispatch();
	const researchMode = useSelector((state) => state.user.researchModeEnabled);

	const [stages, setStages] = useState([]);
	const [totalCandidates, setTotalCandidates] = useState(0);
	const [totalForDiscussedCandidates, setTotalForDiscussedCandidates] = useState(0);
	const [latestUpdates, setLatestUpdates] = useState([]);
	const [marketInsights, setMarketInsights] = useState([]);
	// const [insightReport, setInsightReport] = useState({});
	const [fitToBriefs, setFitToBriefs] = useState([]);
	const [loading, setLoading] = useState(false);
	const [invalidProject, setInvalidProject] = useState(false);
	const [displayGenderSection, setDisplayGenderSection] = useState(false);
	const {projectId} = props.match.params;
	const currentRef = useRef(true);
	const {width} = useWindowSize();

	const setSummary = useCallback(async () => {
		await dispatch(getProjectSummaryByProjectId(projectId, researchMode));

		dispatch(getInterviewsByProjectId(projectId));
	}, [dispatch, projectId, researchMode]);

	useEffect(() => {
		const data = summaryData;

		if (data?.hasData && data.status === true) {
			setStages(data.stages);
			setTotalCandidates(data.totalCandidates);
			setLatestUpdates(data.latestUpdates);
			setFitToBriefs(data.fitToBriefs);
			setMarketInsights(data.marketInsights);
			// setInsightReport(data.insightReport);
			setTotalForDiscussedCandidates(data.totalForDiscussedCandidates);
			setInvalidProject(false);
		} else if (data.status === false) {
			setInvalidProject(true);
		}

		if (data.settings) {
			setDisplayGenderSection(data.settings.show_gender_stats);
		}
	}, [summaryData]);

	useEffect(() => {
		// dispatch(clearAssessmentData());
		if (
			!summaryData?.hasData ||
			parseInt(projectId) !== parseInt(summaryData.projectId)
		) {
			try {
				setLoading(true);
				setSummary().finally(() => setLoading(false));
			} catch (e) {
				console.log(e);
			}
		}

		return () => {
			currentRef.current = false;
		};
	}, [setSummary, summaryData.hasData, projectId, summaryData.projectId]);

	const LoaderComponent = (
		<Loader
			displayAtCenterOfPage={true}
			type={"Rings"}
			height={80}
			width={80}
			color={"#f13a3a"}
		/>
	);
	const InlineLoaderComponent = <Loader height={40} width={40} color={"#f13a3a"} />;

	return (
		<div className="main-content-area">
			<div className={"content-wrapper summary-page"}>
				{!invalidProject && (
					<div className="container custom-scrollbar">
						<Breadcrumb
							options={[
								{
									name: "Summary",
									to: `/projects/${projectId}/summary`,
								},
								{
									name: "Savannah Assessing",
									to: `/projects/${projectId}/assessments`,
								},
								{
									name: "Interviewing",
									to: `/projects/${projectId}/candidate-interviews`,
								},
							]}
							activeIndex={0}
						/>

						{loading && LoaderComponent}

						{!loading && summaryData?.hasData && (
							<>
								<AssignmentInfoAsHeader />

								<div className="content">
									<div className="left-column">
										{/*LATEST UPDATES SECTION*/}
										<LatestUpdatesSection
											latestUpdates={latestUpdates}
											projectId={projectId}
										/>

										{/*MARKET INSIGHT SECTION*/}
										<MarketInsightSection
											researcherMode={researchMode}
											marketInsights={marketInsights}
											projectId={projectId}
										/>

										{width <= 767 && (
											<FitToBriefSection
												fitToBriefs={fitToBriefs}
											/>
										)}

										{/*<InsightReportSection researchMode={researchMode}*/}
										{/*                      insightReport={insightReport}/>*/}
									</div>

									<div className="right-column">
										{/*HIGHLIGHTED PROFILES*/}
										<ForDiscussionButton
											projectId={projectId}
											totalCandidate={totalForDiscussedCandidates}
										/>

										<div className="card">
											{/*CANDIDATES BY STAGES*/}
											<CandidatesByStage
												stages={stages}
												researcherMode={researchMode}
												projectId={projectId}
												setStages={setStages}
												totalCandidates={totalCandidates}
											/>

											{/*GENDER CHART*/}
											{(displayGenderSection || researchMode) && (
												<GenderSection
													applyGrayEffect={
														!displayGenderSection
													}
													projectId={projectId}
													researchMode={researchMode}
													InlineLoaderComponent={
														InlineLoaderComponent
													}
												/>
											)}
										</div>

										{width > 767 && (
											<FitToBriefSection
												fitToBriefs={fitToBriefs}
											/>
										)}

										{/*<DocumentDownloadSection researchMode={researchMode} />*/}
									</div>
								</div>
							</>
						)}
					</div>
				)}

				{!loading && invalidProject && (
					<ErrorPage
						icon={<NotFoundIcon />}
						title="Project Unavailable"
						description="Either project doesn't exist or you don't have access to this project yet, Contact Administrator!"
						link="/home"
						linkText="Back to projects"
					/>
				)}
			</div>
		</div>
	);
};

export default Summary;
