import {
	ACCOUNT_UPDATE,
	GET_USER_NAME_FAILED,
	GET_USER_NAME_FETCH,
	GET_USER_NAME_SUCCESS,
	LAST_UPDATED_TIME,
	LOGIN_FAILED,
	LOGIN_FETCH,
	LOGIN_SUCCESS,
	LOGOUT,
	RESET_PASSWORD_FAILED,
	RESET_PASSWORD_FETCH,
	RESET_PASSWORD_SUCCESS,
	SET_EDIT_ADMIN_INFO,
	SET_EDIT_USER_INFO,
	TOGGLE_RESEARCH_MODE,
} from "./userActionTypes";

export function loginFetch() {
	return {type: LOGIN_FETCH};
}

export function loginSuccess(user) {
	return {type: LOGIN_SUCCESS, payload: user};
}

export function loginFailed(error) {
	return {type: LOGIN_FAILED, payload: error};
}

export function logout() {
	return {type: LOGOUT};
}

export function getUserNameFetch() {
	return {type: GET_USER_NAME_FETCH};
}

export function getUserNameSuccess(email) {
	return {type: GET_USER_NAME_SUCCESS, payload: email};
}

export function getUserNameFailed(error) {
	return {type: GET_USER_NAME_FAILED, payload: error};
}

export function resetPasswordFetch() {
	return {type: RESET_PASSWORD_FETCH};
}

export function resetPasswordSuccess() {
	return {type: RESET_PASSWORD_SUCCESS};
}

export function resetPasswordFailed(error) {
	return {type: RESET_PASSWORD_FAILED, payload: error};
}

export function toggleResearchMode(payload) {
	return {type: TOGGLE_RESEARCH_MODE, payload: payload};
}

export function updateSaveTime(payload) {
	return {type: LAST_UPDATED_TIME, payload: payload};
}

export function setEditUserInfo(payload) {
	return {type: SET_EDIT_USER_INFO, payload: payload};
}

export function setEditAdminInfo(payload) {
	return {type: SET_EDIT_ADMIN_INFO, payload: payload};
}

export function updateAccountInfo(payload) {
	return {type: ACCOUNT_UPDATE, payload: payload};
}
