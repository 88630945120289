import React from "react";
import {Link} from "react-router-dom";

const ViewProfileButton = ({
	isDetailsProfile = false,
	isClickable = false,
	linkedInUrl = null,
	projectId,
	candidateId,
	candidateProfileStringParameter,
}) => {
	const internalProfileLink = `/projects/${projectId}/candidate-profile/${candidateId}${candidateProfileStringParameter}`;

	const ViewRsProfileButton = (
		<Link to={internalProfileLink} className="view-profile-button">
			<button>
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M7.99967 14.6667C4.31945 14.6626 1.33705 11.6802 1.33301 7.99999V7.86666C1.4063 4.20301 4.42273 1.2853 8.08679 1.33391C11.7508 1.38253 14.6888 4.37925 14.6649 8.04355C14.6409 11.7079 11.6641 14.6659 7.99967 14.6667ZM7.99967 2.66666C5.05416 2.66666 2.66634 5.05447 2.66634 7.99999C2.66634 10.9455 5.05416 13.3333 7.99967 13.3333C10.9452 13.3333 13.333 10.9455 13.333 7.99999C13.3297 5.05584 10.9438 2.66996 7.99967 2.66666ZM7.03301 11.3333L6.09034 10.39L8.44701 8.03332L6.09034 5.67666L7.03301 4.73332L10.333 8.03332L7.03367 11.3333H7.03301Z"
						fill="#F3A533"
					/>
				</svg>

				<span>View Profile</span>
			</button>
		</Link>
	);

	const ViewLinkedInUrl = linkedInUrl && (
		<Link to={{pathname: linkedInUrl}} target="_blank">
			<button className="linkedin-url">
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<mask
						id="mask0_3974_29717"
						style={{maskType: "alpha"}}
						maskUnits="userSpaceOnUse"
						x="0"
						y="0"
						width="16"
						height="16"
					>
						<rect width="16" height="16" fill="#D9D9D9" />
					</mask>
					<g mask="url(#mask0_3974_29717)">
						<path
							d="M14 8V12.6667C14 13.0333 13.8696 13.3473 13.6087 13.6087C13.3473 13.8696 13.0333 14 12.6667 14H3.33333C2.96667 14 2.65267 13.8696 2.39133 13.6087C2.13044 13.3473 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13044 2.65267 2.39133 2.39133C2.65267 2.13044 2.96667 2 3.33333 2H8V3.33333H3.33333V12.6667H12.6667V8H14ZM6.46667 10.4667L5.53333 9.53333L11.7333 3.33333H9.33333V2H14V6.66667H12.6667V4.26667L6.46667 10.4667Z"
							fill="#999999"
						/>
					</g>
				</svg>

				<span>View on LinkedIn</span>
			</button>
		</Link>
	);

	const displayButton = () => {
		if (isClickable && !isDetailsProfile) {
			return ViewRsProfileButton;
		} else if ((!isClickable || isDetailsProfile) && linkedInUrl !== null) {
			return ViewLinkedInUrl;
		} else {
			return null;
		}
	};

	return displayButton() !== null && displayButton();
};

export default ViewProfileButton;
