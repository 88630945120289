import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import rootReducer from "./Reducers";
import {loadState, saveState} from "../helpers/localStorage";

const middleware = [thunk];
const composeEnhancers =
	(typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
	compose;

const persistedState = loadState();
const store = createStore(
	rootReducer,
	persistedState,
	composeEnhancers(applyMiddleware(...middleware)),
);

store.subscribe(() => {
	saveState({
		user: store.getState().user,
		project: store.getState().project,
		projectForm: store.getState().projectForm,
	});
});

export default store;
