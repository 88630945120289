import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import TextAreaInput from "components/Inputs/TextAreaInput";
import TextInput from "components/Inputs/TextInput";

let fitToBriefsChecked = false;

const FitToBriefSettings = forwardRef(({briefs, handleInputChange, getBriefs}, ref) => {
	const [briefForm, setBriefForm] = useState([]);

	const briefValidationFailed = useCallback(() => {
		return !briefForm.filter(
			(item) => item.updated_name === "" || item.value === "",
		).length;
	}, [briefForm]);

	const handleChange = async (value, index, attribute) => {
		const updatedBriefs = briefForm.map((item, itemIndex) => {
			if (index === itemIndex) {
				item[attribute] = value;
				item.validName = !(attribute === "updated_name" && value === "");
				item.validDesc = !(attribute === "value" && value === "");
			}

			return item;
		});

		await setBriefForm([...updatedBriefs]);
		await handleInputChange({
			fit_to_briefs: updatedBriefs,
			briefUpdated: true,
		});
	};

	useImperativeHandle(ref, () => ({
		validated() {
			return briefValidationFailed();
		},
	}));

	useEffect(() => {
		if (!briefs.length && getBriefs !== undefined && !fitToBriefsChecked) {
			getBriefs();
			fitToBriefsChecked = true;
		} else {
			setBriefForm([...briefs]);
		}
	}, [briefs, getBriefs]);

	return (
		<div className="fit-to-brief-project-form">
			<div className="title">Brief Settings</div>

			{briefForm?.map((item, index) => (
				<div className="col input-wrapper" key={index}>
					<label htmlFor="realBusiness">
						{index + 1}.
						<TextInput
							customClass="label-input"
							key={index + "title"}
							defaultValue={item.updated_name}
							onChange={(e) =>
								handleChange(e.target.value, index, "updated_name")
							}
							errorText={
								item.validName === undefined || item.validName
									? ""
									: "Please Enter Invenias Name"
							}
						/>
					</label>
					<TextAreaInput
						rows={8}
						key={index + "desc"}
						placeholder={"Enter Description *"}
						errorText={
							item.validDesc === undefined || item.validDesc
								? ""
								: "Please Enter Description"
						}
						onChange={(e) => handleChange(e.target.value, index, "value")}
						defaultValue={item.value}
						customStyle={{resize: "vertical"}}
					/>
				</div>
			))}

			{!briefForm.length && (
				<p
					style={{
						paddingTop: "20px",
						display: "flex",
						alignContent: "center",
						justifyContent: "center",
						height: "100%",
					}}
				>
					No Briefs Found!
				</p>
			)}
		</div>
	);
});

FitToBriefSettings.displayName = "FitToBriefSettings";

export default FitToBriefSettings;
