import React from "react";

const ArrowLeft = (props) => (
	<svg
		width={7}
		height={12}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 7 12"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.874.185 7 1.3 2.253 6 7 10.7l-1.126 1.116L.718 6.71a1 1 0 0 1 0-1.422L5.874.185Z"
			fill="#999"
		/>
	</svg>
);

export default ArrowLeft;
