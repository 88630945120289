import React from "react";

export const NotPinned = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.290823 18.4119L6.91744 11.7691L3.37588 8.21892C3.01248 7.85463 3.01248 7.26599 3.37588 6.90155L5.17452 5.09853C5.73375 4.53794 6.57257 4.3978 7.26215 4.68748L11.1205 0.819773C12.2108 -0.273258 14.112 -0.273258 15.2026 0.819773L19.1542 4.78103C20.2819 5.91152 20.2819 7.7426 19.1542 8.87309L15.2959 12.7408C15.389 12.9744 15.4451 13.2266 15.4451 13.4788C15.4451 13.9926 15.2494 14.469 14.8859 14.8335L13.0872 16.6365C12.9008 16.8234 12.6679 16.9074 12.4256 16.9074C12.1833 16.9074 11.9502 16.8141 11.764 16.6365L8.22242 13.0863L1.5958 19.7291C1.40938 19.9159 1.17649 20 0.934175 20C0.691853 20 0.458784 19.9066 0.272554 19.7291C-0.0908508 19.3648 -0.0908508 18.7761 0.272554 18.4117L0.290823 18.4119ZM12.4161 14.6468L13.5624 13.451L13.4132 13.3014C13.0498 12.9371 13.0498 12.3485 13.4132 11.984L17.831 7.55553C18.2317 7.15377 18.2317 6.49983 17.831 6.10748L13.8793 2.14622C13.6836 1.95007 13.4319 1.84726 13.1523 1.84726C12.8728 1.84726 12.621 1.95006 12.4254 2.14622L8.00761 6.57473C7.83046 6.75231 7.59756 6.84567 7.34599 6.84567C7.0944 6.84567 6.86136 6.74287 6.68437 6.57473L6.53517 6.42517C6.53517 6.42517 6.48853 6.40644 6.47 6.42517L5.33299 7.56496L12.3973 14.6465L12.4161 14.6468Z"
			fill="#999999"
		/>
	</svg>
);
