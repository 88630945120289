import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import SelectComponent from "components/Inputs/Select";
import TextInput from "components/Inputs/TextInput";
import useFormValidation from "hooks/useFormValidation";
import {useDispatch, useSelector} from "react-redux";
import {setAssignments, setFormData} from "store/projectForm/projectFormActions";
import projectApi from "api/report/project";
import {emailPattern} from "helpers/validator";
import projectRequestsApi from "api/report/projectRequests";
import Loader from "components/Plugins/Loader";
import {toast} from "react-toastify";

const errorMessages = {
	invalidEmail: "You have entered invalid e-mail account",
	uniqueEmail: "This E-mail already invited once, enter unique user e-mail!",
};

const ProjectDetailsSettings = forwardRef(
	({handleInputChange, getBriefs, isEdit, projectId, currentProgressLevel}, ref) => {
		const [externalInviteLoading, setExternalInviteLoading] = useState(false);
		const [externalShare, setExternalShare] = useState("");
		const [, setExternalShareValidation] = useState({
			valid: undefined,
			unique: undefined,
		});
		const [shareExternalErrorMessage, setShareExternalErrorMessage] =
			useState(null);

		const initialFormData = useSelector((state) => state.projectForm);
		const {assignments} = initialFormData;
		const initialValidation = {
			assignment_id: {
				valid: initialFormData.form.assignment?.value ? true : undefined,
			},
		};
		const [formData, validation, changeForm] = useFormValidation(
			initialFormData.form,
			initialValidation,
		);
		const [query, setQuery] = useState("a");
		const dispatch = useDispatch();
		const [loading, setLoading] = useState(false);

		const validateExternalEmail = async (email, sendInvite = false) => {
			if (emailPattern.test(email)) {
				if (formData.external_shares.includes(email)) {
					setExternalShareValidation({
						valid: true,
						unique: false,
					});
					setShareExternalErrorMessage(errorMessages.uniqueEmail);
				} else {
					setExternalShare("");
					setExternalShareValidation({
						valid: true,
						unique: true,
					});
					setShareExternalErrorMessage(null);

					if (sendInvite) {
						await sendingInvite(email);
						handleChangedData("external_shares", [
							...formData.external_shares,
							email,
						]);
					}
				}
			} else {
				setExternalShareValidation({
					valid: false,
					unique: undefined,
				});
				setShareExternalErrorMessage(errorMessages.invalidEmail);
			}
		};

		const handleChangedData = (key, targetValue) => {
			if (key === "internal_shares") {
				targetValue = [...formData.internal_shares, {email: targetValue}];
			}

			const data = changeForm(key, targetValue);

			handleInputChange(data);

			if (key === "assignment" && targetValue) {
				getBriefs(targetValue.value);
			}
		};

		const isValidated = () => {
			return validation.assignment_id.valid;
		};

		// This hook used to provide information is validated to parent component
		// This boolean value enable/disable Save Button

		useImperativeHandle(ref, () => ({
			validated() {
				return isValidated();
			},
		}));

		const getAssignmentsByQuery = useCallback(
			async (query) => {
				setLoading(true);

				await projectApi
					.getAssignments(query)
					.then((response) => {
						const data = response.data.assignments.map((item) => {
							return {
								label: `${item.company_name}, ${item.name}`,
								value: item.id,
								internalRef: item.internal_ref,
							};
						});

						dispatch(setAssignments(data));
					})
					.catch((e) => {
						console.log(e);
					})
					.finally(() => setLoading(false));
			},
			[dispatch],
		);

		const sendingInvite = async (email) => {
			setExternalInviteLoading(true);

			await projectRequestsApi
				.sendProjectInvite({email}, projectId)
				.then((response) => {
					if (response.status === 200 || response.status === 201) {
						toast.success(
							"The invitation should arrive in the user's inbox within a few minutes.",
						);
					} else {
						toast.error("Something went wrong!");
					}
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => setExternalInviteLoading(false));
		};

		useEffect(() => {
			if (query && !isEdit && currentProgressLevel === 0) {
				getAssignmentsByQuery(query).then((r) => r);
			}
		}, [getAssignmentsByQuery, isEdit, query, currentProgressLevel]);

		const InlineLoaderComponent = (
			<Loader
				height={16}
				width={16}
				customStyle={{paddingLeft: "10px"}}
				type="TailSpin"
				color={"#f13a3a"}
			/>
		);

		return (
			<div className="project-details-form">
				<div className="title">Details</div>

				<div className="grid row">
					<div className="col full input-wrapper">
						<label htmlFor="">Assignment *</label>
						<SelectComponent
							altDropdownIndicator={true}
							errorText={
								validation.assignment_id.valid === undefined ||
								validation.assignment_id.valid
									? ""
									: "Please Select Assignment"
							}
							onChange={(assignment, triggerAction) => {
								if (triggerAction.action === "clear") {
									setTimeout(() => {
										dispatch(setFormData({fit_to_briefs: []}));
										handleChangedData("assignment", null);
									}, 300);
								} else {
									handleChangedData("assignment", assignment);
								}
							}}
							onInputChange={setQuery}
							defaultValue={initialFormData.form.assignment}
							options={assignments}
							isSearchable={true}
							isClearable={true}
							placeholder="Type to select"
							isDisabled={isEdit}
							isLoading={loading}
							classNamePrefix="select-assignment"
							formatOptionLabel={({label, internalRef}) => (
								<div className="select-assignment-item">
									<div className="label">{label}</div>
									<div className="internal-ref">{internalRef}</div>
								</div>
							)}
						/>
					</div>
					{/*<div className="col input-wrapper">*/}
					{/*    <label htmlFor="">Status</label>*/}
					{/*    <SelectComponent options={[{'label': 'Active', value: 1}, {'label': 'Inactive', value: 1}]}*/}
					{/*                     altDropdownIndicator={true}/>*/}
					{/*</div>*/}
				</div>

				<div className="grid row" style={{marginTop: "32px"}}>
					{isEdit && (
						<div className="col dropdown-with-tags">
							<div className="shared-selection input-wrapper">
								<label htmlFor="">Shared Externally</label>
								<span className="input-with-button">
									<TextInput
										customClass="custom-input"
										placeholder="Email Address"
										type="email"
										errorText={shareExternalErrorMessage}
										value={externalShare}
										onInput={(e) =>
											validateExternalEmail(e.target.value)
										}
										onChange={(e) =>
											setExternalShare(e.target.value)
										}
									/>
									<button
										className={[
											"btn",
											externalShare.length
												? "btn-white"
												: "btn-disabled",
										].join(" ")}
										disabled={!externalShare.length}
										onClick={() =>
											validateExternalEmail(externalShare, true)
										}
									>
										{externalInviteLoading ? (
											<>Sending {InlineLoaderComponent}</>
										) : (
											"Send Invite"
										)}
									</button>
								</span>
							</div>
							<div className="tags">
								{formData.external_shares.map((email, ii) => (
									<button className="btn btn-tag" key={ii}>
										{email}
									</button>
								))}
							</div>
						</div>
					)}

					{/*<div className="col dropdown-with-tags">*/}
					{/*	<div className="mapx-components-selection input-wrapper">*/}
					{/*		<label htmlFor="">Shared Internally</label>*/}
					{/*		<SelectComponent customClass="dropdown" altDropdownIndicator={true} />*/}
					{/*	</div>*/}
					{/*	<div className="tags">*/}
					{/*		{formData.internal_shares.map((item, ie) => (*/}
					{/*			<button className="btn btn-tag" key={ie}>*/}
					{/*				{item.name} {item.email}*/}
					{/*			</button>*/}
					{/*		))}*/}
					{/*	</div>*/}
					{/*</div>*/}
				</div>
			</div>
		);
	},
);

ProjectDetailsSettings.displayName = "ProjectDetailsSettings";

export default ProjectDetailsSettings;
