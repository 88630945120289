/** @format */

import React from "react";

const PencilIcon = ({height = 15, width = 15, color = "#141414", ...rest}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.5853 0.866024L15.1341 1.41492C15.368 1.64864 15.4996 1.96573 15.4999 2.29647V2.92853C15.5041 3.26503 15.3721 3.58894 15.1341 3.82672L13.5959 5.34033C13.5179 5.41906 13.4116 5.46334 13.3008 5.46334C13.1899 5.46334 13.0837 5.41906 13.0056 5.34033L10.6693 2.97011C10.5109 2.80842 10.5109 2.54965 10.6693 2.38796L12.1742 0.866024C12.4119 0.627893 12.7357 0.495931 13.0721 0.500096H13.704C14.0347 0.500387 14.3517 0.632009 14.5853 0.866024ZM12.0162 6.9038C12.1747 6.7421 12.1747 6.48333 12.0162 6.32164L9.63834 3.97637C9.56028 3.89764 9.45403 3.85336 9.34318 3.85336C9.23234 3.85336 9.12608 3.89764 9.04802 3.97637L2.3384 10.6878C2.17619 10.8486 2.04883 11.0411 1.96426 11.2534L0.534205 14.8627C0.453195 15.0501 0.520129 15.2686 0.692176 15.3784C0.81001 15.4961 0.986679 15.5321 1.14115 15.4699L4.74954 14.0061C4.96169 13.9215 5.15415 13.7942 5.31491 13.6319L12.0162 6.9038Z"
				fill={color}
			/>
		</svg>
	);
};

export default PencilIcon;
