import {
	CLEAR_ASSESSMENT_DATA,
	CLEAR_SUMMARY_DATA,
	SET_ASSESSMENT_DATA,
	SET_CANDIDATES_DATA,
	SET_COMPANY_LOGO,
	SET_COUNTRIES,
	SET_CURRENCIES,
	SET_GENDER_DATA,
	SET_INTERVIEWS,
	SET_NOTICE_PERIODS,
	SET_PROGRESS_STATUS,
	SET_SUMMARY_DATA,
} from "../reportActionTypes";
import ReportDemoSVG from "assets/images/demos/report.svg";

const initialAssessment = {
	totalCounts: {},
	query: "",
	filterType: "all_candidates",
};

const initialSummaryData = {
	projectId: null,
	stages: [],
	totalCandidates: 0,
	totalForDiscussedCandidates: 0,
	candidatesSummary: [],
	marketInsights: [],
	insightReport: {
		cover: ReportDemoSVG,
		summary: [],
	},
	fitToBriefs: [],
	hasData: false,
	assignmentInfo: null,
	currencies: [],
	progress_statuses: [],
	notice_periods: [],
	countries: [],
	last_published_date: null,
};

const initialGenderData = {
	labels: [],
	values: [],
	colors: [],
	fetched: false,
};

const initialState = {
	summaryData: initialSummaryData,
	genderData: initialGenderData,
	interviews: [],
	candidates: [],
	assessment: initialAssessment,
};

const projectReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_COMPANY_LOGO: {
			const {summaryData} = state;

			if (
				summaryData &&
				summaryData.assignmentInfo &&
				summaryData.assignmentInfo.company
			) {
				return {
					...state,
					summaryData: {
						...state.summaryData,
						assignmentInfo: {
							...state.summaryData.assignmentInfo,
							company: {
								...state.summaryData.assignmentInfo.company,
								logo: action.payload.logo,
							},
						},
					},
				};
			}

			return state;
		}
		case SET_SUMMARY_DATA:
			return {
				...state,
				summaryData: {
					...action.payload,
					hasData: action.payload.hasData || false,
				},
			};
		case SET_GENDER_DATA:
			return {...state, genderData: action.payload};
		case CLEAR_SUMMARY_DATA:
			return {
				...state,
				summaryData: initialSummaryData,
				genderData: initialGenderData,
			};

		case SET_INTERVIEWS:
			return {...state, interviews: action.payload};
		case SET_ASSESSMENT_DATA:
			return {...state, assessment: action.payload};
		case SET_CANDIDATES_DATA:
			let data = [];

			if (action.payload?.shouldAddMore) {
				data = [...state.candidates, ...action.payload.candidates];
			} else {
				data = [...action.payload.candidates];
			}

			return {
				...state,
				candidates: data,
			};
		case CLEAR_ASSESSMENT_DATA:
			return {...state, candidates: [], assessment: initialAssessment};
		case SET_CURRENCIES:
			return {...state, currencies: action.payload};
		case SET_PROGRESS_STATUS:
			return {...state, progress_statuses: action.payload};
		case SET_NOTICE_PERIODS:
			return {...state, notice_periods: action.payload};
		case SET_COUNTRIES:
			return {...state, countries: action.payload};
		default:
			return state;
	}
};

export default projectReducer;
