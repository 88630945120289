/** @format */

import React from "react";

const RightIcon = ({color = "#CDCECF", ...rest}) => {
	return (
		<div {...rest}>
			<svg
				width="7"
				height="12"
				viewBox="0 0 7 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M5.09677 10.83C5.2063 10.9404 5.3554 11.0026 5.51094 11.0026C5.66647 11.0026 5.81557 10.9404 5.9251 10.83L6.15844 10.5967C6.27237 10.4869 6.33573 10.3349 6.33344 10.1767L6.33344 1.82335C6.33573 1.66517 6.27237 1.5131 6.15844 1.40335L5.9251 1.17002C5.81557 1.05958 5.66647 0.997467 5.51094 0.997467C5.3554 0.997467 5.2063 1.05958 5.09677 1.17002L0.6751 5.59168C0.56231 5.69754 0.498328 5.84533 0.498328 6.00002C0.498328 6.1547 0.56231 6.30249 0.6751 6.40835L5.09677 10.83Z"
					fill={color}
				/>
			</svg>
		</div>
	);
};

export default RightIcon;
