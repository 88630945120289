/** @format */

import React from "react";
import css from "./index.module.scss";

const Footer = () => {
	return (
		<p className={css.copyright}>
			©{new Date().getFullYear()} All Rights Reserved. Savannah®
		</p>
	);
};

export default Footer;
