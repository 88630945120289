import React from "react";
import {Link} from "react-router-dom";
import IconCaretLeft from "assets/icons/IconCaretLeft";
import IconCaretRight from "assets/icons/IconCaretRight";
import Loader from "components/Plugins/Loader";

const BrowseCandidateSection = ({browseCandidate, loading}) => {
	const InlineLoaderComponent = <Loader height={30} width={30} color={"#f13a3a"} />;

	return (
		<div className="browse-candidate">
			<div className="left">
				{!loading && (
					<Link
						to={browseCandidate.prev}
						className="cursor"
						style={{
							visibility: browseCandidate.prev ? "visible" : "hidden",
						}}
					>
						<button className="btn btn-white">
							<IconCaretLeft height={10} />
							<label htmlFor="candidate">Previous candidate</label>
						</button>
					</Link>
				)}
			</div>
			<div className="center">
				{loading ? (
					InlineLoaderComponent
				) : (
					<>
						{browseCandidate.title}: {browseCandidate.position} of{" "}
						{browseCandidate.totalCandidate}
					</>
				)}
			</div>

			<div className="right">
				{!loading && (
					<Link
						to={browseCandidate.next}
						className="cursor"
						style={{
							visibility: browseCandidate.next ? "visible" : "hidden",
						}}
					>
						<button className="btn btn-white">
							<label htmlFor="candidate">Next Candidate</label>
							<IconCaretRight height={10} />
						</button>
					</Link>
				)}
			</div>
		</div>
	);
};

export default BrowseCandidateSection;
