// Login Action Types
export const LOGIN_FETCH = "LOGIN_FETCH";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const TOGGLE_RESEARCH_MODE = "TOGGLE_RESEARCH_MODE";
export const LAST_UPDATED_TIME = "LAST_UPDATED_TIME";
export const SET_EDIT_USER_INFO = "SET_EDIT_USER_INFO";
export const SET_EDIT_ADMIN_INFO = "SET_EDIT_ADMIN_INFO";
export const ACCOUNT_UPDATE = "ACCOUNT_UPDATE";

// Username Action Types
export const GET_USER_NAME_FETCH = "GET_USER_NAME_FETCH";
export const GET_USER_NAME_SUCCESS = "GET_USER_NAME_SUCCESS";
export const GET_USER_NAME_FAILED = "GET_USER_NAME_FAILED";

// Reset Password Action types
export const RESET_PASSWORD_FETCH = "RESET_PASSWORD_FETCH";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
