import React from "react";
import {Link} from "react-router-dom";
import TextInput from "components/Inputs/TextInput";
import css from "./index.module.scss";
import Footer from "rs-components/LayoutPartials/Footer";
import {useOnAuth} from "hooks/useOnAuth";
import {onEnter} from "hooks/common";
import {SavannahLogo} from "assets/icons/SavannahLogo";

const Login = (props) => {
	const {
		error,
		loading,
		changeForm,
		LoaderComponent,
		onSubmit,
		submitEnabled,
		validation,
	} = useOnAuth({
		history: props.history,
		type: "login",
		redirectUrl: "/home",
	});

	return (
		<div className={css.container}>
			<div className={css.content}>
				{/*<div className={css.logo}/>*/}

				<div className={css.logoContainer}>
					<SavannahLogo />
				</div>

				<div
					style={{
						display: "flex",
						fontSize: "18px",
						justifyContent: "center",
					}}
				>
					Sign in for secure project access
				</div>

				<form className={css.form} onKeyPress={(e) => onEnter(e, onSubmit)}>
					<TextInput
						label="Email"
						name="email"
						type="text"
						placeholder="Enter E-Mail"
						errorText={validation.email.error}
						onChange={(e) => changeForm("email", e.target.value)}
						id={"email"}
						customClass={css.loginInput}
					/>
					<TextInput
						label="Password"
						name="password"
						type="password"
						placeholder="Enter the password"
						errorText={validation.password.error}
						onChange={(e) => changeForm("password", e.target.value)}
						id={"password"}
						customClass={css.loginInput}
					/>

					{error ? <p className={css.error}>{error}</p> : null}

					<button
						type="button"
						disabled={!submitEnabled || loading}
						onClick={onSubmit}
						className={css.submitButton}
					>
						{loading ? LoaderComponent : "Login"}
					</button>
				</form>
				<div className={css.links}>
					{/*<Link to="/forgot-username" className={css.linkForgot}>*/}
					{/*	Forgot Username?*/}
					{/*</Link>*/}
					<Link to="/forgot-password" className={css.linkForgot}>
						Forgot Password?
					</Link>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Login;
