import React from "react";
import {Link} from "react-router-dom";
import IconCaretRight from "assets/icons/IconCaretRight";

const SimpleBreadcrumb = ({options}) => {
	return (
		<ol className="simple-breadcrumb">
			{options.map((option, index) => (
				<div key={index} style={{display: "flex"}}>
					<span className="link">
						{option.to !== "#" ? (
							<Link to={option.to}>{option.name}</Link>
						) : (
							option.name
						)}
					</span>

					{index < options.length - 1 && (
						<span className="arrow">
							<IconCaretRight />
						</span>
					)}
				</div>
			))}
		</ol>
	);
};

export default SimpleBreadcrumb;
