import React from "react";
import Select, {components} from "react-select";
import AsyncSelect from "react-select/async";
import css from "./index.module.scss";
import IconCaretDown from "../../../assets/icons/IconCaretDown";
import {TextFieldMessage} from "components";

const SelectComponent = ({
	customClass,
	customStyle = [],
	altDropdownIndicator = false,
	isAsync = false,
	borderColor = "#D7DFE9",
	placeHolderFontColor = "#000",
	hideIndicator = false,
	hidePlaceholder = false,
	themeColor = "#BD281E",
	...props
}) => {
	const customStyles = {
		control: (base) => ({
			...base,
			border:
				props.errorText !== undefined && props.errorText
					? "1px solid red"
					: "1px solid " + borderColor,
			height: "40px",
			borderRadius: hidePlaceholder ? "4px" : 0,
			...customStyle,
		}),
		menu: (base) => ({
			...base,
			zIndex: 10,
		}),
		indicatorSeparator: (base) => ({
			...base,
			width: 0,
		}),
		dropdownIndicator: (base) => ({
			...base,
			color: "#000",
		}),
		placeholder: (base) => ({
			...base,
			display: hidePlaceholder ? "none" : "auto",
			fontSize: "14px",
			lineHeight: "24px",
			color: placeHolderFontColor,
		}),
		option: (provided, state) => ({
			...provided,
			fontSize: "14px",
			lineHeight: "24px",
			color: state.isDisabled
				? null
				: state.isSelected
				? "#fff"
				: state.isFocused
				? "initial"
				: null,

			backgroundColor: state.isDisabled
				? null
				: state.isSelected
				? themeColor
				: state.isFocused
				? "#EEEEEE"
				: null,
			":hover": {
				backgroundColor: state.isDisabled
					? null
					: state.isSelected
					? themeColor
					: state.isFocused
					? "#EEEEEE"
					: null,
			},
		}),
	};

	const indicatorComponent = hideIndicator
		? {
				DropdownIndicator: () => null,
				IndicatorSeparator: () => null,
		  }
		: altDropdownIndicator
		? {
				DropdownIndicator: (props) => {
					return (
						<components.DropdownIndicator {...props}>
							<IconCaretDown />
						</components.DropdownIndicator>
					);
				},
		  }
		: {};

	const hasError =
		props.errorText !== "" &&
		props.errorText !== null &&
		props.errorText !== undefined;

	return isAsync ? (
		<AsyncSelect
			{...props}
			components={indicatorComponent}
			className={[
				css.select,
				customClass ? customClass : "",
				hasError ? "invalid-input" : "valid-input",
			].join(" ")}
			styles={customStyles}
		/>
	) : (
		<div
			style={{display: "flex", width: "100%"}}
			className={[hasError ? "invalid-input" : "valid-input"].join(" ")}
		>
			<Select
				{...props}
				components={indicatorComponent}
				className={[css.select, customClass ? customClass : ""].join(" ")}
				styles={customStyles}
			/>

			<TextFieldMessage errorText={props.errorText} />
		</div>
	);
};

export default SelectComponent;
