export const autoGrowTextArea = (e) => {
	e.target.style.height = "inherit";
	// e.target.style.height = `${e.target.scrollHeight}px`;
	// Get the computed styles for the element
	const computed = window.getComputedStyle(e.target);

	// Calculate the height
	const height =
		parseInt(computed.getPropertyValue("border-top-width"), 1) +
		parseInt(computed.getPropertyValue("padding-top"), 16) +
		e.target.scrollHeight +
		parseInt(computed.getPropertyValue("padding-bottom"), 16) +
		parseInt(computed.getPropertyValue("border-bottom-width"), 1);

	e.target.style.height = `${Math.min(height, 350)}px`;

	return e;
};
