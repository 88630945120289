/** @format */

import {getShortName} from "helpers/string";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, NavLink, withRouter} from "react-router-dom";
import {clearSummaryData} from "store/project/projectActions";
import {toggleResearchMode} from "store/user/userActions";
import PencilIcon from "../../../assets/icons/IconPencil";
import IconSave from "../../../assets/icons/IconSave";
import IconUpload from "../../../assets/icons/IconUpload";
import IconNotification from "../../../assets/icons/NotificationIcon";
import {SavannahLogoMini, SavannahLogoWhite} from "../../../assets/icons/SavannahLogo";
import Switch from "../../../components/Inputs/Switch";
import {timeAgo} from "../../../helpers/timeDifference";
// import SiteSwitcher from "./siteSwitcher";
import PublishChangesModal from "../../PublishChangesModal";
import css from "./reportNavbar.module.scss";

const ReportNavbar = () => {
	const researchMode = useSelector((state) => state.user.researchModeEnabled);
	const {last_published_date} = useSelector((state) => state.project.summaryData);
	const {user} = useSelector((state) => state);
	const {firstName, lastName, user_name, is_invenias_user, image} = user.user;
	const dispatch = useDispatch();
	const [projectId, setProjectId] = useState(null);
	const [modalDisplay, setModalDisplay] = useState(false);
	const route = window.location.pathname;

	let nameString = "";

	if (firstName) {
		nameString = getShortName(firstName, lastName);
	} else {
		nameString = getShortName(user_name);
	}

	const handleSwitchMode = (value) => {
		// setResearchModeEnabled(value)
		dispatch(toggleResearchMode(value));
		dispatch(clearSummaryData());
	};

	useEffect(() => {
		const routeArray = route.split("/");
		const pattern = new RegExp(/\d+/);

		if (routeArray.length > 2 && pattern.test(routeArray[2])) {
			setProjectId(parseInt(routeArray[2]?.match(pattern)[0]));
		} else {
			setProjectId(false);
		}
	}, [route]);

	// const ifAllowedToSwitchSite = () => {
	//     const allowedToVisitAll = ['Admin', 'RT+MapX User'];
	//
	//     return allowedToVisitAll.includes(role);
	// }

	const lastUpdatedTime = () => {
		if (last_published_date) {
			return timeAgo(last_published_date, false);
		}

		return "n/a";
	};

	// const updatePageTitle = updateWebAppTitle();
	//
	// useEffect(() => {
	// 	updatePageTitle();
	// }, [updatePageTitle]);

	return (
		<div className={"navbar-wrapper"}>
			<div className={[css.navbar, "navbar"].join(" ")}>
				<div className={[css.container, "container"].join(" ")}>
					<div className={[css.left, "logo-area"].join(" ")}>
						{/*{ifAllowedToSwitchSite() && <SiteSwitcher isReportSite={true}/>}*/}

						<NavLink to="/home" activeClassName="selected">
							<SavannahLogoWhite className="logoWithText" />

							<SavannahLogoMini className="logoWithoutText" />
						</NavLink>
					</div>

					<div
						className={[
							"info-group",
							researchMode ? "researcher-mode" : "",
						].join(" ")}
					>
						{is_invenias_user && (
							<span className="update-info">
								<Switch
									value={researchMode}
									onChange={handleSwitchMode}
									customClass="custom-switch"
									style={{marginRight: "10px"}}
								/>
								<label
									htmlFor="switch"
									className="label"
									style={{paddingRight: "5px"}}
								>
									Researcher Mode
								</label>
							</span>
						)}

						{researchMode && projectId && (
							<>
								<span className="update-info">
									<IconSave style={{marginRight: "10px"}} /> Last
									published {lastUpdatedTime()}
								</span>

								<span
									className="update-info cursor"
									onClick={() => setModalDisplay(true)}
								>
									<IconUpload style={{marginRight: "10px"}} /> Publish
									Changes
								</span>
							</>
						)}

						{!researchMode && projectId && (
							<span className="update-info">
								Last updated: {lastUpdatedTime()}
							</span>
						)}

						{researchMode && projectId && (
							<Link
								to={`/projects/${projectId}/edit?redirectTo=${route}`}
							>
								<span
									className="update-info"
									style={{
										display: "flex",
										alignItems: "center",
										gap: 10,
									}}
								>
									<PencilIcon color="#ffffff" /> Edit Project
								</span>
							</Link>
						)}

						<span className="notification">
							<IconNotification color={"#CDCECF"} />
						</span>
						<NavLink
							to="/account"
							activeClassName="selected"
							className={[css.profileLink, "profile"].join(" ")}
						>
							{image ? (
								<img
									src={image}
									height={40}
									alt=""
									className="avatar-on-nav"
								/>
							) : (
								nameString
							)}
						</NavLink>
					</div>

					<div
						className={[
							"navigation-icon",
							researchMode ? "researcher-mode" : "",
						].join(" ")}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.5 7H3.5C3.22386 7 3 6.77614 3 6.5V5.5C3 5.22386 3.22386 5 3.5 5H20.5C20.7761 5 21 5.22386 21 5.5V6.5C21 6.77614 20.7761 7 20.5 7ZM21 12.5V11.5C21 11.2239 20.7761 11 20.5 11H3.5C3.22386 11 3 11.2239 3 11.5V12.5C3 12.7761 3.22386 13 3.5 13H20.5C20.7761 13 21 12.7761 21 12.5ZM21 17.5V18.5C21 18.7761 20.7761 19 20.5 19H3.5C3.22386 19 3 18.7761 3 18.5V17.5C3 17.2239 3.22386 17 3.5 17H20.5C20.7761 17 21 17.2239 21 17.5Z"
								fill="white"
							/>
						</svg>
					</div>
				</div>
			</div>

			{modalDisplay && (
				<PublishChangesModal
					projectId={projectId}
					setModalDisplay={setModalDisplay}
				/>
			)}
		</div>
	);
};

export default withRouter(ReportNavbar);

export const updateWebAppTitle = () => {
	return useCallback(() => {
		document.title = "Savannah Reporting Tool";
		const appleIcon = document.getElementById("apple-touch-icon");
		appleIcon.href = "favicon_io/apple-icon-180x180.png";

		const regularIcon = document.getElementById("icon");
		regularIcon.href = "favicon_io/android-icon-192x192.png";
	}, []);
};
