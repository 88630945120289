import {
	TOGGLE_COMPANY_LOGO_MODAL,
	TOGGLE_PEOPLE_LOGO_MODAL,
} from "store/reportActionTypes";

const initialState = {
	companyLogoModal: false,
	companyLogoModalProps: {},
	peopleLogoModal: false,
	peopleLogoModalProps: {},
};

const modalReducer = (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_PEOPLE_LOGO_MODAL:
			return {
				...state,
				peopleLogoModal: !state.peopleLogoModal,
				peopleLogoModalProps: action.peopleLogoModalProps,
			};
		case TOGGLE_COMPANY_LOGO_MODAL:
			return {
				...state,
				companyLogoModal: !state.companyLogoModal,
				companyLogoModalProps: action.companyLogoModalProps,
			};
		default:
			return state;
	}
};

export default modalReducer;
