import React, {useState} from "react";
import css from "containers/Profile/index.module.scss";
import {logoutUser} from "store/user/userSelectors";
import {useDispatch} from "react-redux";
import {Loader} from "components";

const LogoutButton = ({
	exitingSessions = false,
	onLoggingOut = null,
	backgroundColor = "#e2231a",
}) => {
	const [loggingOut, setLoggingOut] = useState(false);
	const dispatch = useDispatch();

	const onLogout = async () => {
		if (onLoggingOut !== null) {
			onLoggingOut(true);
		}

		setLoggingOut(true);
		await dispatch(logoutUser());
		setLoggingOut(false);

		if (onLoggingOut !== null) {
			onLoggingOut(false);
		}
	};

	const InlineLoaderComponent = <Loader height={40} width={40} color={"#0c5850"} />;

	return (
		<button
			type="button"
			onClick={onLogout}
			className={css.submitButton}
			style={{background: backgroundColor}}
			disabled={exitingSessions || loggingOut}
		>
			Logout{" "}
			{!!loggingOut && (
				<>
					<span>&nbsp;&nbsp;</span>
					{InlineLoaderComponent}
				</>
			)}
		</button>
	);
};

export default LogoutButton;
