/** @format */

import React from "react";

const SearchIcon16 = ({color = "none", height = 10, width = 10, ...rest}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				color={color}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.02778 4.88138L0.625 1.50839L1.59722 0.544678L5 3.91766L8.40278 0.544678L9.375 1.50839L5.97222 4.88138L9.375 8.25436L8.40278 9.21807L5 5.84509L1.59722 9.21807L0.625 8.25436L4.02778 4.88138Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default SearchIcon16;
