import React from "react";
import {Link} from "react-router-dom";
import TextInput from "components/Inputs/TextInput";
import css from "./index.module.scss";
import Footer from "rs-components/LayoutPartials/Footer";
import {useOnAuth} from "hooks/useOnAuth";
import {SavannahLogo} from "../../assets/icons/SavannahLogo";
import {LeftArrow} from "assets/icons/LeftArrow";

const ForgotPassword = (props) => {
	const userInitialData = {email: ""};
	const userInitialValidation = {
		email: {valid: false},
	};
	const {
		loading,
		changeForm,
		error,
		LoaderComponent,
		onSubmit,
		submitEnabled,
		validation,
		message,
	} = useOnAuth({
		history: props.history,
		type: "forgot-password",
		userInitialData,
		userInitialValidation,
		redirectUrl: "/login",
	});

	return (
		<div className={css.container}>
			<div className={css.content}>
				<div className={css.logoContainer}>
					<SavannahLogo />
				</div>

				<h1 className={css.title}>Forgot Password</h1>
				<p className={css.subTitle}>
					Please enter your email, we’ll email you instructions to reset your
					password.
				</p>
				<form className={css.form}>
					<TextInput
						label="Email"
						name="email"
						type="text"
						placeholder="Enter E-Mail"
						errorText={validation.email.error}
						onChange={(e) => changeForm("email", e.target.value)}
						customClass={css.loginInput}
					/>

					{error && <p className={css.error}>{error}</p>}

					{message && <p className={css.success}>{message}</p>}

					<button
						type="button"
						disabled={!submitEnabled || loading}
						onClick={onSubmit}
						className={css.submitButton}
					>
						{loading ? LoaderComponent : "Submit"}
					</button>

					<div className={css.links}>
						<LeftArrow />
						<Link to="/login" className={css.linkForgot}>
							Return to Login
						</Link>
					</div>
				</form>
			</div>
			<Footer />
		</div>
	);
};

export default ForgotPassword;
