import {LeftArrow} from "assets/icons/LeftArrow";
import {SavannahLogo} from "assets/icons/SavannahLogo";
import classNames from "classnames";
import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import TextInput from "../../components/Inputs/TextInput";
import {newPasswordRules} from "../../helpers/validator";
import {useOnAuth} from "../../hooks/useOnAuth";
import Footer from "../../rs-components/LayoutPartials/Footer";
import css from "./index.module.scss";

const ResetPassword = (props) => {
	const [passwordValidations, setPasswordValidations] = useState(newPasswordRules);
	const search = useLocation().search;
	const verificationCode = new URLSearchParams(search).get("code");
	const userInitialData = {
		password: "",
		conf_password: "",
		code: verificationCode,
	};
	const userInitialValidation = {
		new_password: {valid: false},
		conf_password: {valid: false},
	};
	const {
		loading,
		changeForm,
		error,
		LoaderComponent,
		onSubmit,
		submitEnabled,
		validation,
		message,
	} = useOnAuth({
		history: props.history,
		type: "reset-password",
		userInitialData,
		userInitialValidation,
		redirectUrl: "/login",
	});

	useEffect(() => {
		if (verificationCode === null) {
			props.history.push("/login");
		}
	}, [props.history, verificationCode, changeForm]);

	useEffect(() => {
		if (validation?.new_password) {
			setPasswordValidations((passwordValidations) => {
				return passwordValidations.map((item) => {
					if (validation.new_password.error !== undefined) {
						item.isValid = !validation.new_password.error.includes(
							item.rule,
						);
					}

					return item;
				});
			});
		}
	}, [validation]);

	return (
		<div className={css.container}>
			<div className={css.content}>
				<div className={css.logoContainer}>
					<SavannahLogo />
				</div>

				<h1 className={css.title}>Reset Your Password</h1>
				<p className={css.subTitle}>Please create a new password.</p>

				<form className={classNames(css.form, css.resetForm)}>
					<div className={css.inputContainer}>
						<TextInput
							label="New Password"
							name="new_password"
							type="password"
							placeholder="Enter new password"
							// errorText={validation.new_password.error}
							onChange={(e) => changeForm("new_password", e.target.value)}
							passwordValidations={passwordValidations}
							customClass={css.loginInput}
						/>
					</div>

					<TextInput
						label="Confirm Password"
						name="conf_password"
						type="password"
						placeholder="Confirm password"
						errorText={validation.conf_password.error}
						onChange={(e) => changeForm("conf_password", e.target.value)}
						customClass={css.loginInput}
					/>

					{error && <p className={css.error}>{error}</p>}

					{message && <p className={css.success}>{message}</p>}

					<button
						type="button"
						disabled={!submitEnabled || loading}
						onClick={onSubmit}
						className={css.submitButton}
					>
						{loading ? LoaderComponent : "Submit"}
					</button>

					<div className={css.links}>
						<LeftArrow />
						<Link to="/login" className={css.linkForgot}>
							Return to Login
						</Link>
					</div>
				</form>
			</div>
			<Footer />
		</div>
	);
};

export default ResetPassword;
